import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13844076"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "ion-margin-vertical" }
const _hoisted_4 = { class: "ion-margin-vertical" }
const _hoisted_5 = { class: "ion-text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_import_data_modal = _resolveComponent("import-data-modal")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dataLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_spinner),
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('LoadingData')), 1)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_import_data_modal, {
            crop: _ctx.cropId,
            step: "liming",
            onImportData: _ctx.handleImportData
          }, null, 8, ["crop", "onImportData"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, { class: "ion-justify-content-left ion-align-items-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      size: "10",
                      "size-md": "auto"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          type: "number",
                          fill: "solid",
                          class: "ion-text-right",
                          label: _ctx.translations[_ctx.currentLanguage].LimingFrequency,
                          modelValue: _ctx.cropLiming.frequency,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cropLiming.frequency) = $event)),
                          modelModifiers: { number: true },
                          onIonBlur: _ctx.updateCropLimingPrice
                        }, null, 8, ["label", "modelValue", "onIonBlur"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      size: "2",
                      "size-md": "auto"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_text, { class: "ion-text-left" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Years')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      size: "12",
                      "size-md": "auto",
                      "offset-md": "1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_select, {
                          label: _ctx.translations[_ctx.currentLanguage].Product,
                          placeholder: _ctx.translations[_ctx.currentLanguage].ProductPlaceHolder,
                          modelValue: _ctx.cropLiming.product,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.cropLiming.product) = $event)),
                          fill: "solid",
                          "cancel-text": _ctx.translations[_ctx.currentLanguage].Cancel,
                          "ok-text": _ctx.translations[_ctx.currentLanguage].Choose,
                          onIonChange: _ctx.updateCropLimingData
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.limingProducts, (product, index) => {
                              return (_openBlock(), _createBlock(_component_ion_select_option, {
                                key: index,
                                value: product.id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(product.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["label", "placeholder", "modelValue", "cancel-text", "ok-text", "onIonChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      size: "9",
                      "size-md": "auto",
                      "offset-md": "1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          type: "number",
                          min: "0",
                          fill: "solid",
                          class: "ion-text-right",
                          label: _ctx.translations[_ctx.currentLanguage].Quantity,
                          modelValue: _ctx.cropLiming.quantity,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.cropLiming.quantity) = $event)),
                          onIonBlur: _ctx.updateCropLimingPrice
                        }, null, 8, ["label", "modelValue", "onIonBlur"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      size: "3",
                      "size-md": "auto"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('UnitTproHa')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "ion-justify-content-left ion-align-items-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      size: "9",
                      "size-md": "auto"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          type: "number",
                          min: "0",
                          fill: "solid",
                          class: "ion-text-right",
                          label: _ctx.translations[_ctx.currentLanguage].Cost,
                          modelValue: _ctx.cropLiming.cost,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.cropLiming.cost) = $event)),
                          modelModifiers: { number: true },
                          onIonBlur: _ctx.updateCropLimingPrice
                        }, null, 8, ["label", "modelValue", "onIonBlur"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      size: "3",
                      "size-md": "auto"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('UnitEuroProT')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      "offset-md": "2",
                      size: "12",
                      "size-md": "auto"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_checkbox, {
                          modelValue: _ctx.cropLiming.spreading_included,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.cropLiming.spreading_included) = $event)),
                          onIonChange: _ctx.updateCropLimingPrice,
                          labelPlacement: "end"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('SpreadingIncluded')), 1)
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onIonChange"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      "offset-md": "2",
                      size: "12",
                      "size-md": "auto"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_checkbox, {
                          modelValue: _ctx.cropLiming.spreading_over_current,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.cropLiming.spreading_over_current) = $event)),
                          onIonChange: _ctx.updateCropLimingData,
                          labelPlacement: "end"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('SpreadingOverCrop')), 1)
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onIonChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, { class: "ion-justify-content-center ion-align-items-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      size: "9",
                      "size-md": "auto"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          type: "number",
                          min: "0",
                          label: _ctx.translations[_ctx.currentLanguage].YearlyCost,
                          modelValue: _ctx.cropLiming.yearly_cost,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.cropLiming.yearly_cost) = $event)),
                          readonly: "true",
                          strong: "true",
                          class: "ion-text-center readonly"
                        }, null, 8, ["label", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      size: "3",
                      "size-md": "auto"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ion_button, {
              color: "secondary",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.previousStep($event, _ctx.cropLiming)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Previous')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_button, {
              color: "primary",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.nextStep($event, _ctx.cropLiming)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Next')), 1)
              ]),
              _: 1
            })
          ])
        ]))
  ]))
}