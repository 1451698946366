import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f5db59fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_auto_fill_select = _resolveComponent("auto-fill-select")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("form", {
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
          onChange: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
        }, [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('BreedingType')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_auto_fill_select, {
                        options: _ctx.breedingTypes,
                        searchField: "name",
                        selected: _ctx.data.breeding_type_name,
                        label: _ctx.translations[_ctx.currentLanguage].BreedingType,
                        placeholder: _ctx.translations[_ctx.currentLanguage].Search,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = option => _ctx.handleSelected(option, _ctx.data, 'breeding_type', true))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" >")
                        ]),
                        _: 1
                      }, 8, ["options", "selected", "label", "placeholder"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { class: "ion-margin-vertical ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        fill: "solid",
                        modelValue: _ctx.data.chicken_coop_size,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.chicken_coop_size) = $event)),
                        label: _ctx.translations[_ctx.currentLanguage].ChickenCoopSize,
                        "label-placement": "floating",
                        class: "input",
                        onIonBlur: _ctx.submitForm
                      }, null, 8, ["modelValue", "label", "onIonBlur"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        fill: "solid",
                        modelValue: _ctx.data.coop,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.coop) = $event)),
                        label: _ctx.translations[_ctx.currentLanguage].Coop,
                        "label-placement": "floating",
                        class: "input",
                        onIonBlur: _ctx.submitForm
                      }, null, 8, ["modelValue", "label", "onIonBlur"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        fill: "solid",
                        modelValue: _ctx.data.label,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.label) = $event)),
                        label: _ctx.translations[_ctx.currentLanguage].Label,
                        "label-placement": "floating",
                        class: "input",
                        onIonBlur: _ctx.submitForm
                      }, null, 8, ["modelValue", "label", "onIonBlur"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 32)
      ]))
    : _createCommentVNode("", true)
}