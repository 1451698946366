
import { IonTitle, IonButton, IonRow, IonGrid, IonCol, IonSpinner, IonIcon } from '@ionic/vue';
import BaseLayout from '../layouts/BaseLayout.vue';
import { defineComponent, onMounted, ref, computed } from "vue";
import axiosInstance from "@/axiosInstance";
import { 
  createOutline, 
  eyeOutline,
  checkmarkOutline
} from 'ionicons/icons';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    BaseLayout,
    IonTitle,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonSpinner,
    IonIcon
  },
  setup() {
    const dataLoaded = ref(false);
    const campaigns = ref(null);
    const company = ref(null);
    const route = useRoute();
    const slug = route.params.slug as string;
    const store = useStore();
    const userType = computed(() => store.state.userType);

    const getCampaignData = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/${slug}/campaigns/`); 
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getCompany = async () => {
      try {
        const response = await axiosInstance.get(`api/core/companies/${slug}/`); 
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const updateCampaign = async (campaign: any) => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/${slug}/campaign/saisie/${campaign.year}/`, campaign); 
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const canValidate = (campaign: any) => {
      return (
        userType.value == 'employee' && 
        campaign.submitted == true &&
        campaign.validated != true
      );
    };

    const canUpdate = (campaign: any) => {
      return (
        campaign.submitted == false ||
        (
          userType.value == 'employee' &&
          campaign.validated != true
        )
      );
    };

    const validateCampaign = async (campaign: any) => {
      try {
        campaign.validated = true;
        campaign.progressStatus = "Validée";
        await updateCampaign(campaign);
      } catch (error) {
        console.error('Error updating production data:', error);
      }
    };

    onMounted(async () => {
      company.value = await getCompany();
      campaigns.value = await getCampaignData();
      dataLoaded.value = true;
    });

    return {
      createOutline, 
      eyeOutline,
      checkmarkOutline,
      company,
      campaigns,
      dataLoaded,
      slug,
      userType,
      validateCampaign,
      canValidate,
      canUpdate
    };
  },
});
