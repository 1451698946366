import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6231e9e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chart-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ion_grid, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, null, {
              default: _withCtx(() => [
                _createElementVNode("h2", null, _toDisplayString(_ctx.$t('MyRotations')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_col, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("canvas", {
                    ref: "canvas",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
                  }, null, 512)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}