
import {
  IonPage,
  IonTitle,
  IonSpinner,
  IonButton,
  IonText
} from '@ionic/vue';
import { 
  eyeOutline
} from 'ionicons/icons';
import BaseLayout from '../layouts/BaseLayout.vue';
import { defineComponent, onMounted, ref } from "vue";
import axiosInstance from "@/axiosInstance";
import AutoFillSelect from '@/components/AutoFillSelect.vue';
import frTranslations from '@/locales/fr.json';

export default defineComponent({
  components: {
    IonPage,
    BaseLayout,
    IonTitle,
    IonSpinner,
    IonButton,
    IonText,
    AutoFillSelect
  },
  setup() {
    const dataLoaded = ref(false);
    const user = ref(null);
    const selectedGroup = ref(null);
    const selectedGroupMembership = ref(null);
    const groups = ref<any[]>([]);
    const memberships = ref([]);
    const selectedMembership = ref(null);
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };

    const getUserInfo = async () => {
      try {
        const response = await axiosInstance.get(`api/core/user-info/`);
        return response.data.user;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getGroups = async (userId: number) => {
      try {
        const response = await axiosInstance.get(`api/core/${userId}/groups/`); 
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getMemberships = async (group: any) => {
      try {
        const response = await axiosInstance.get(`api/core/memberships/${group.id}/`); 
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    onMounted(async () => {
      const userInfo = await getUserInfo();
      if (userInfo) {
        user.value = userInfo;
        groups.value = await getGroups(userInfo.id);
        const noGroup = {
          'id': 0,
          'name': "Pas de groupe",
          'members': [],
          'slug': ""
        };
        groups.value.unshift(noGroup);
        dataLoaded.value = true;
      }
    });

    const handleSelectedGroupMember = async (group: any) => {
      if (group) {
        memberships.value = await getMemberships(group);
      }
      else {
        memberships.value = [];
        selectedMembership.value = null;
      }
      
    }

    return {
      translations,
      currentLanguage,
      user,
      groups,
      selectedGroup,
      selectedGroupMembership,
      dataLoaded,
      eyeOutline,
      memberships,
      selectedMembership,
      handleSelectedGroupMember
    };
  },
});
