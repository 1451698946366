import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32d3959f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "field-container" }
const _hoisted_4 = { class: "field-container" }
const _hoisted_5 = { class: "field-container" }
const _hoisted_6 = { class: "field-container" }
const _hoisted_7 = { class: "field-container" }
const _hoisted_8 = { class: "field-container" }
const _hoisted_9 = { class: "field-container" }
const _hoisted_10 = { class: "field-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dataLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_spinner),
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('LoadingData')), 1)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-justify-content-left ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "4"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_ion_text, { class: "field-label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Area')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cropArea.area), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('UnitHa')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "4"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_ion_text, { class: "field-label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Yield')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cropArea.crop_yield), 1)
                          ]),
                          _: 1
                        }),
                        (_ctx.cropArea.yield_unit == 'T')
                          ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('UnitTonProHa')), 1)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_ion_text, { key: 1 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('UnitQuintalProHa')), 1)
                              ]),
                              _: 1
                            }))
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "4"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_ion_text, { class: "field-label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('YieldTarget')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cropArea.crop_yield_target), 1)
                          ]),
                          _: 1
                        }),
                        (_ctx.cropArea.yield_unit == 'T')
                          ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('UnitTonProHa')), 1)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_ion_text, { key: 1 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('UnitQuintalProHa')), 1)
                              ]),
                              _: 1
                            }))
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.cropArea.show_straw)
                ? (_openBlock(), _createBlock(_component_ion_row, {
                    key: 0,
                    class: "ion-justify-content-left ion-align-items-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        size: "12",
                        "size-md": "4"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_ion_text, { class: "field-label" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('YieldStraw')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.cropArea.straw_yield), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('UnitTonProHa')), 1)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }),
                      (_ctx.cropArea.intermediate_crop_name)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 0,
                            size: "12",
                            "size-md": "4"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_7, [
                                _createVNode(_component_ion_text, { class: "field-label" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('IntermediateCrop')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_text, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.cropArea.intermediate_crop_name), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_text, { class: "field-label" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('YieldIntermediateCrop')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_text, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.cropArea.intermediate_crop_yield), 1)
                                  ]),
                                  _: 1
                                }),
                                (_ctx.cropArea.intermediate_yield_unit == 'T')
                                  ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('UnitTonProHa')), 1)
                                      ]),
                                      _: 1
                                    }))
                                  : (_openBlock(), _createBlock(_component_ion_text, { key: 1 }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('UnitQuintalProHa')), 1)
                                      ]),
                                      _: 1
                                    }))
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_col, {
                        size: "12",
                        "size-md": "4"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_ion_text, { class: "field-label" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('Previous')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.cropArea.previous_crop_name), 1)
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      }),
                      (_ctx.cropArea.rotation_name)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 1,
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_9, [
                                _createVNode(_component_ion_text, { class: "field-label" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('Rotation')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_text, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.cropArea.rotation_name), 1)
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.cropArea.rotation_colza_year)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 2,
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_10, [
                                _createVNode(_component_ion_text, { class: "field-label" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('ColzaLoopInRotation')), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_text, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.cropArea.rotation_colza_year), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_text, { class: "field-label" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('Years')), 1)
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
  ]))
}