
import { 
  IonTitle, 
  IonButton, 
  IonRow, 
  IonGrid, 
  IonCol, 
  IonSpinner,
  IonText
} from '@ionic/vue';
import BaseLayout from '../layouts/BaseLayout.vue';
import { defineComponent, onMounted, ref, Ref, computed } from "vue";
import axiosInstance from '@/axiosInstance';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import PieChart from '../components/PieChart.vue';
import BarChart from '../components/BarChart.vue';
import { Crop } from '@/types/Crop';
import { Campaign } from '@/types/Campaign';

export default defineComponent({
  components: {
    BaseLayout,
    IonTitle,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonSpinner,
    PieChart,
    BarChart,
    IonText
  },
  setup() {
    const dataLoaded = ref(false);
    const username = ref("");
    const campaign = ref<Campaign>({});
    const route = useRoute();
    const slug = ref(route.params.slug);
    const year = ref(route.params.year);
    const currentCrop = ref({});
    const store = useStore();
    const userType = computed(() => store.state.userType);

    const getUserInfo = async () => {
      try {
        const response = await axiosInstance.get(`api/core/user-info/`);
        return response.data.user;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getCampaignData = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/${slug.value}/campaign/${year.value}/`); 
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    onMounted(async () => {
      const userInfo = await getUserInfo();
      if (userInfo) {
        username.value = userInfo.username;
        campaign.value = await getCampaignData();
        if (campaign.value && campaign.value.crops) {
          currentCrop.value = campaign.value.crops[0];
        }
        dataLoaded.value = true;
      }
    });

    const handleSelected = (id: number) => {
      if (campaign.value && campaign.value.crops) {
        const selected = campaign.value.crops.find((crop: Crop) => {return crop.id === id});
        if (selected) {
          currentCrop.value = selected;
        }
      }
    };

    const canUpdate = (crop: any) => {
      return (
        crop.submitted == false ||
        (
          userType.value == 'employee' &&
          crop.validated != true
        )
      );
    };

    return {
      username,
      campaign,
      year,
      slug,
      dataLoaded,
      currentCrop,
      handleSelected,
      canUpdate
    };
  },
});
