
import {  
  IonTitle,
  IonButton,
  IonModal,
  IonContent,
  IonHeader,
  IonSelect,
  IonSelectOption,
  IonText,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue';
import { defineComponent, PropType, ref, Ref, toRef, onMounted, SetupContext } from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';
import { Crop } from "@/types/Crop";
import CropArea from "../components/CetaMarge/CropArea.vue";
import CropSeed from "../components/CetaMarge/CropSeed.vue";
import CropMineralFertilization from "../components/CetaMarge/CropMineralFertilization.vue";
import CropOrganicFertilization from "../components/CetaMarge/CropOrganicFertilization.vue";
import CropLimingFertilization from "../components/CetaMarge/CropLimingFertilization.vue";
import CropMecanic from "../components/CetaMarge/CropMecanic.vue";
import CropTreatment from "../components/CetaMarge/CropTreatment.vue";

export default defineComponent({
  components: {
    CropArea,
    CropSeed,
    CropMineralFertilization,
    CropOrganicFertilization,
    CropLimingFertilization,
    CropMecanic,
    CropTreatment,
    IonTitle,
    IonButton,
    IonModal,
    IonContent,
    IonHeader,
    IonSelect,
    IonSelectOption,
    IonText,
    IonSpinner,
    IonGrid,
    IonRow,
    IonCol
  },
  props: {
    crop: {
      type: Number as PropType<number>,
      required: true,
    },
    step: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, context: SetupContext) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const showModal = ref(false);
    const showMessage = ref(false);
    const crops = ref<Crop[]>([]);
    const cropToImport = ref();
    const dataLoaded = ref(false);
    const openModal = async () => {
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    onMounted(async () => {
     if (props.crop !== undefined && props.crop !== null) {
        crops.value = await getPreviousYearCrops();
        dataLoaded.value = true;
      }
    });

    const getPreviousYearCrops = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${props.crop}/previous-year/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const importData = async () => {
        
        emit('importData', cropToImport.value);
        closeModal();
    };

    const emit = (eventName: string, ...args: any[]) => {
      context.emit(eventName, ...args);
    };

    return {
      translations,
      currentLanguage,
      showModal,
      openModal,
      closeModal,
      showMessage,
      dataLoaded,
      crops,
      importData,
      cropToImport
    };
  },
});
