
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonCheckbox
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref, Ref, toRef } from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';
import { CompanyData } from '@/types/CompanyData';
import AutoFillSelect from '@/components/AutoFillSelect.vue';
import { trashBinOutline, closeOutline } from 'ionicons/icons';

export default defineComponent({
  name: "GeneralDataForm",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonCheckbox,
    AutoFillSelect
  },
  props: {
    companyId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup(props) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const dataLoaded = ref(false);
    const companyId = toRef(props, 'companyId');
    const data = ref<CompanyData>({});
    const managementCenters = ref([]);
    const newProject = ref({});
    const errorMessage = ref<string | null>(null);

    const freshProject = (company_data: CompanyData): any => ({
      id: 0,
      company: company_data.id,
      type: '',
      date: ''
    });

    const submitForm = async () => {
      try {
        data.value = await updateData(companyId, data.value);
        dataLoaded.value = true;
      } catch (error) {
        console.error('Error updating production data:', error);
      }
    };

    const validateProject = (item: any): boolean => {
       if (!item.company) {
        errorMessage.value = "Une erreur est survenue.";
        return false;
      }
      if (!item.type) {
        errorMessage.value = "Veuillez préciser un type de projet.";
        return false;
      }
      errorMessage.value = null;
      return true;
    };

    onMounted(async () => {
      data.value = await getData(companyId);
      managementCenters.value = await getManagementCenters();
      if (data.value) {
        newProject.value = freshProject(data.value);
      }
      dataLoaded.value = true;
    });

    const getData = async (companyId: Ref<number>) => {
      try {
        const response = await axiosInstance.get(`api/core/general-data/${companyId.value}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const getManagementCenters = async () => {
      try {
        const response = await axiosInstance.get(`api/core/management-centers/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const updateData = async (companyId: Ref<number>, data: any) => {
      try {
        const response = await axiosInstance.put(`api/core/general-data/${companyId.value}/update/`, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const addProject = async () => {
      try {
        if (!validateProject(newProject.value)){
          return false;
        }
        const response = await axiosInstance.post(`api/core/project-mae/add/`, newProject.value);
        if (!data.value.projects) {
          data.value.projects = [];
        }
        data.value.projects.push(response.data);
       
        newProject.value = freshProject(data.value);
        return true;
      } catch (error) {
        console.error('Error adding new project:', error);
        return false;
      }
    };

    const updateProject = async (project: any) => {
      try {
        const response = await axiosInstance.put(`api/core/project-mae/${project.id}/update/`, project);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const deleteProject = async (projectId: Ref<number>) => {
      try {
        if (data.value.projects) {
          data.value.projects = data.value.projects.filter((project: any) => project.id !== projectId);
        }
        const response = await axiosInstance.delete(`api/core/project-mae/${projectId.value}/delete/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };


    return {
      translations,
      currentLanguage,
      data,
      managementCenters,
      trashBinOutline,
      submitForm,
      newProject,
      addProject,
      updateProject,
      deleteProject
    };
  },
});
