
import { 
  IonSpinner,
  IonGrid,
  IonCol, 
  IonText
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref} from "vue";
import axiosInstance from "@/axiosInstance";
import { CropSeed } from "@/types/CropSeed";
import frTranslations from '@/locales/fr.json';

export default defineComponent({
  name: "CropSeed",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  components: {
    IonSpinner,
    IonGrid,
    IonCol,
    IonText
  },
  setup(props) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const dataLoaded = ref(false);
    const cropSeed = ref<CropSeed>({});

    onMounted(async () => {
      
     if (props.cropId !== undefined && props.cropId !== null) {
        cropSeed.value = await getCropSeed(props.cropId);
        dataLoaded.value = true;
      }
      
    });

    const getCropSeed = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/seed/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    return {
      dataLoaded,
      cropSeed,
      translations,
      currentLanguage
    };
  },
});
