import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f1dfaa56"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "crop-name"
}
const _hoisted_2 = {
  key: 0,
  class: "crop-name"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "ion-pull-right" }
const _hoisted_6 = {
  class: "ion-padding-vertical",
  slot: "content"
}
const _hoisted_7 = { class: "ion-pull-right" }
const _hoisted_8 = {
  class: "ion-padding-vertical",
  slot: "content"
}
const _hoisted_9 = {
  class: "ion-padding-vertical",
  slot: "content"
}
const _hoisted_10 = {
  class: "ion-padding-vertical",
  slot: "content"
}
const _hoisted_11 = {
  class: "ion-padding-vertical",
  slot: "content"
}
const _hoisted_12 = { class: "ion-pull-right" }
const _hoisted_13 = {
  class: "ion-padding-vertical",
  slot: "content"
}
const _hoisted_14 = {
  class: "ion-padding-vertical",
  slot: "content"
}
const _hoisted_15 = {
  class: "ion-padding-vertical",
  slot: "content"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "ion-text-center ion-margin-vertical" }
const _hoisted_19 = { key: 2 }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_crop_area_form = _resolveComponent("crop-area-form")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_crop_seed_form = _resolveComponent("crop-seed-form")!
  const _component_crop_mineral_fertilization_form = _resolveComponent("crop-mineral-fertilization-form")!
  const _component_crop_organic_fertilization_form = _resolveComponent("crop-organic-fertilization-form")!
  const _component_crop_liming_fertilization_form = _resolveComponent("crop-liming-fertilization-form")!
  const _component_crop_mecanic_form = _resolveComponent("crop-mecanic-form")!
  const _component_crop_treatment_form = _resolveComponent("crop-treatment-form")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_BaseLayout = _resolveComponent("BaseLayout")!

  return (_openBlock(), _createBlock(_component_BaseLayout, null, {
    title: _withCtx(() => [
      (_ctx.userInfo && _ctx.userInfo.user_type == 'member')
        ? (_openBlock(), _createBlock(_component_ion_title, {
            key: 0,
            class: "ion-hide-sm-down"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('CropForm')) + " ", 1),
              (_ctx.dataLoaded)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.crop.label), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : (_ctx.crop)
          ? (_openBlock(), _createBlock(_component_ion_title, {
              key: 1,
              class: "ion-hide-sm-down"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.crop.name) + " - " + _toDisplayString(_ctx.$t('CropForm')) + " ", 1),
                (_ctx.dataLoaded)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.crop.label), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
    ]),
    content: _withCtx(() => [
      (!_ctx.dataLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_ion_spinner),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('LoadingData')), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.year)
              ? (_openBlock(), _createBlock(_component_ion_grid, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, { class: "ion-justify-content-between ion-margin-bottom" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              href: `/exploitation/${_ctx.slug}/cultures/${_ctx.year}/saisie`,
                              class: "ion-float-left",
                              color: "light"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('BackToCampaign')), 1)
                              ]),
                              _: 1
                            }, 8, ["href"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_row, { class: "ion-hide-sm-up ion-margin-bottom" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createElementVNode("h1", null, _toDisplayString(_ctx.$t('CropForm')) + " " + _toDisplayString(_ctx.crop.label), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createElementVNode("form", {
                onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
                onChange: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
              }, [
                _createVNode(_component_ion_accordion_group, { value: _ctx.activeIndex }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_accordion, { value: "0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          slot: "header",
                          color: _ctx.isActive(0),
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateIndex(0)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('Surface')), 1)
                              ]),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_5, [
                              (_ctx.cropAreaValid)
                                ? (_openBlock(), _createBlock(_component_ion_badge, {
                                    key: 0,
                                    color: "primary"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.checkmarkOutline,
                                        size: "medium"
                                      }, null, 8, ["icon"])
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_ion_badge, {
                                    key: 1,
                                    color: "secondary"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.helpOutline,
                                        size: "medium"
                                      }, null, 8, ["icon"])
                                    ]),
                                    _: 1
                                  }))
                            ])
                          ]),
                          _: 1
                        }, 8, ["color"]),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_crop_area_form, {
                            cropId: _ctx.crop.id,
                            onNext: _ctx.nextStep,
                            onPrevious: _ctx.previousStep,
                            onAreaChange: _ctx.handleAreaChange
                          }, null, 8, ["cropId", "onNext", "onPrevious", "onAreaChange"])
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_accordion, { value: "1" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          slot: "header",
                          color: _ctx.isActive(1),
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateIndex(1)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('Seed')), 1)
                              ]),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_7, [
                              (_ctx.cropSeedValid)
                                ? (_openBlock(), _createBlock(_component_ion_badge, {
                                    key: 0,
                                    color: "primary"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.checkmarkOutline,
                                        size: "medium"
                                      }, null, 8, ["icon"])
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_ion_badge, {
                                    key: 1,
                                    color: "secondary"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.helpOutline,
                                        size: "medium"
                                      }, null, 8, ["icon"])
                                    ]),
                                    _: 1
                                  }))
                            ])
                          ]),
                          _: 1
                        }, 8, ["color"]),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_crop_seed_form, {
                            cropId: _ctx.crop.id,
                            area: _ctx.crop.area.area,
                            mixedCrop: _ctx.crop.mixed,
                            onNext: _ctx.nextStep,
                            onPrevious: _ctx.previousStep,
                            onTreatmentSeedChange: _ctx.handleSeedTreatmentChange
                          }, null, 8, ["cropId", "area", "mixedCrop", "onNext", "onPrevious", "onTreatmentSeedChange"])
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_accordion, { value: "2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          slot: "header",
                          color: _ctx.isActive(2),
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateIndex(2)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('MineralFertilization')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["color"]),
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_crop_mineral_fertilization_form, {
                            cropId: _ctx.crop.id,
                            area: _ctx.crop.area.area,
                            onNext: _ctx.nextStep,
                            onPrevious: _ctx.previousStep
                          }, null, 8, ["cropId", "area", "onNext", "onPrevious"])
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_accordion, { value: "3" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          slot: "header",
                          color: _ctx.isActive(3),
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateIndex(3)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('OrganicFertilization')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["color"]),
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_crop_organic_fertilization_form, {
                            cropId: _ctx.crop.id,
                            area: _ctx.crop.area.area,
                            onNext: _ctx.nextStep,
                            onPrevious: _ctx.previousStep
                          }, null, 8, ["cropId", "area", "onNext", "onPrevious"])
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_accordion, { value: "4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          slot: "header",
                          color: _ctx.isActive(4),
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateIndex(4)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('Liming')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["color"]),
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_crop_liming_fertilization_form, {
                            cropId: _ctx.crop.id,
                            onNext: _ctx.nextStep,
                            onPrevious: _ctx.previousStep
                          }, null, 8, ["cropId", "onNext", "onPrevious"])
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_accordion, { value: "5" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          slot: "header",
                          color: _ctx.isActive(5),
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateIndex(5)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('MecanicActions')), 1)
                              ]),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_12, [
                              (_ctx.cropMecaValid)
                                ? (_openBlock(), _createBlock(_component_ion_badge, {
                                    key: 0,
                                    color: "primary"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.checkmarkOutline,
                                        size: "medium"
                                      }, null, 8, ["icon"])
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_ion_badge, {
                                    key: 1,
                                    color: "secondary"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_icon, {
                                        icon: _ctx.helpOutline,
                                        size: "medium"
                                      }, null, 8, ["icon"])
                                    ]),
                                    _: 1
                                  }))
                            ])
                          ]),
                          _: 1
                        }, 8, ["color"]),
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_crop_mecanic_form, {
                            cropId: _ctx.crop.id,
                            area: _ctx.crop.area.area,
                            onNext: _ctx.nextStep,
                            onPrevious: _ctx.previousStep
                          }, null, 8, ["cropId", "area", "onNext", "onPrevious"])
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_accordion, { value: "6" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          slot: "header",
                          color: _ctx.isActive(6),
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateIndex(6)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('Treatments')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["color"]),
                        _createElementVNode("div", _hoisted_14, [
                          _createVNode(_component_crop_treatment_form, {
                            cropId: _ctx.crop.id,
                            area: _ctx.crop.area.area,
                            seed_treatment: _ctx.crop.seed.treatment_seed,
                            onNext: _ctx.nextStep,
                            onPrevious: _ctx.previousStep
                          }, null, 8, ["cropId", "area", "seed_treatment", "onNext", "onPrevious"])
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_accordion, { value: "7" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          slot: "header",
                          color: _ctx.isActive(7),
                          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateIndex(7)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('EndSubmission')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["color"]),
                        _createElementVNode("div", _hoisted_15, [
                          _createVNode(_component_ion_grid, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_col, {
                                    size: "auto",
                                    class: "content-block"
                                  }, {
                                    default: _withCtx(() => [
                                      (_ctx.crop.submitted)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                            _createVNode(_component_ion_text, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t('CropSubmittedText')), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]))
                                        : (_ctx.cropValid)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                              _createVNode(_component_ion_text, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.$t('CropCompleteText')), 1)
                                                ]),
                                                _: 1
                                              }),
                                              _createElementVNode("div", _hoisted_18, [
                                                _createVNode(_component_ion_button, { onClick: _ctx.submitCrop }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.$t('SubmitCrop')), 1)
                                                  ]),
                                                  _: 1
                                                }, 8, ["onClick"])
                                              ])
                                            ]))
                                          : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                              _createVNode(_component_ion_text, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.$t('CropIncompleteText')), 1)
                                                ]),
                                                _: 1
                                              }),
                                              _createVNode(_component_ion_text, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.$t('PleaseCompleteSteps')), 1)
                                                ]),
                                                _: 1
                                              }),
                                              _createElementVNode("ul", null, [
                                                (!_ctx.cropAreaValid)
                                                  ? (_openBlock(), _createElementBlock("li", _hoisted_20, _toDisplayString(_ctx.$t('Area')), 1))
                                                  : _createCommentVNode("", true),
                                                (!_ctx.cropSeedValid)
                                                  ? (_openBlock(), _createElementBlock("li", _hoisted_21, _toDisplayString(_ctx.$t('Seed')), 1))
                                                  : _createCommentVNode("", true),
                                                (!_ctx.cropMecaValid)
                                                  ? (_openBlock(), _createElementBlock("li", _hoisted_22, _toDisplayString(_ctx.$t('MecanicActions')), 1))
                                                  : _createCommentVNode("", true)
                                              ])
                                            ]))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ], 32)
            ])
          ]))
    ]),
    _: 1
  }))
}