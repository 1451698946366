import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "message ion-margin" }
const _hoisted_3 = { class: "buttons ion-margin-vertical" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ion_button, {
      expand: "block",
      color: "light",
      class: "ion-float-right",
      onClick: _ctx.openModal
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('ReportWrongData')), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_ion_modal, {
      "is-open": _ctx.showModal,
      onIonModalWillDismiss: _ctx.closeModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, { class: "ion-padding" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('WrongDataModalTitle')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, { class: "ion-padding" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, { class: "ion-margin-vertical" }, {
              default: _withCtx(() => [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('WrongDataModalDescription')), 1)
              ]),
              _: 1
            }),
            (_ctx.showMessage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('WrongDataSubmitted')), 1),
                  _createVNode(_component_ion_button, {
                    onClick: _ctx.closeModal,
                    color: "light"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Close')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : (_openBlock(), _createElementBlock("form", {
                  key: 1,
                  onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
                  class: "ion-padding"
                }, [
                  _createVNode(_component_ion_textarea, {
                    modelValue: _ctx.info,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.info) = $event)),
                    fill: "solid",
                    "aria-label": _ctx.translations[_ctx.currentLanguage].WrongDataLabel,
                    placeholder: _ctx.$t('WrongDataPlaceholder'),
                    autoGrow: true,
                    required: "true",
                    rows: "5",
                    class: "input ion-margin-vertical"
                  }, null, 8, ["modelValue", "aria-label", "placeholder"]),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_ion_button, {
                      strong: true,
                      type: "submit",
                      class: "ion-float-right"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('Confirm')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_button, {
                      onClick: _ctx.closeModal,
                      color: "light",
                      class: "ion-float-right"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('Cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ], 32))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open", "onIonModalWillDismiss"])
  ]))
}