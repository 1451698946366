
import { 
  defineComponent, 
  PropType, 
  ref,
  Ref,
  watch,
  onMounted,
  SetupContext
} from "vue";
import { 
  IonRow, 
  IonGrid, 
  IonCol
} from '@ionic/vue';
import { Crop } from "@/types/Crop";
import {
  Chart as ChartJS,
  registerables
} from "chart.js";

ChartJS.register(...registerables);

interface DataPoint {
  value: number | undefined;
  id: string | undefined;
}

interface DataSet {
  label: string;
  backgroundColor: (string | undefined)[];
  data: DataPoint[];
}

interface ChartData {
  labels: (string | undefined)[];
  datasets: DataSet[];
}

export default defineComponent({
  name: "PieChart",
  components: { 
    IonRow, 
    IonGrid, 
    IonCol
  },
  props: {
    crops: {
      type: Array as PropType<Crop[]>,
      default: () => [],
      required: true,
    },
  },
  setup(props, context: SetupContext) {
    const canvas = ref<HTMLCanvasElement | null>(null);
    const chartInstance = ref();
    const chartData: Ref<ChartData> = ref<ChartData>({
      labels: [],
      datasets: [
        {
          label: "Surface",
          backgroundColor: [],
          data: [],
        },
      ],
    });

    const predefinedColors = [
      '#00a070',
      '#f3c260',
      '#a3d8e7',
      '#535b6d',
      '#eb584e'
    ];

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false
    };


    // Generate a random color
    const randomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    watch(
      () => props.crops,
      (newCrops: Crop[]) => {
        chartData.value.labels = newCrops.map((crop: Crop) => crop.label);
        chartData.value.datasets[0].data = newCrops.map((crop: Crop) => ({
          value: crop.area ? crop.area.area : 0,
          id: crop.id
        })) as DataPoint[];
        const backgroundColors = newCrops.map((crop: Crop, index: number) => {
          if (index < 4) {
            // Use predefined colors for the first four datasets
            return predefinedColors[index];
          } else {
            // Use random color for the rest
            return randomColor();
          }
        });

        chartData.value.datasets[0].backgroundColor = backgroundColors;
      },
      { immediate: true } // This will run the watcher immediately after component initialization
    );

    onMounted(() => {
      if (!canvas.value) return;
      const ctx = canvas.value.getContext('2d');

      if (!ctx) {
        console.error("Cannot get canvas context");
        return;
      }

      chartInstance.value = new ChartJS(ctx, {
        type: 'pie',
        data: chartData.value,
        options: chartOptions,
      });
    });

    const onClick = (event: MouseEvent) => {
      if (!chartInstance.value) return;

      const chart = chartInstance.value;
      const element = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
      if (!element.length) return;

      const { datasetIndex, index } = element[0];
      const id = (chartData.value.datasets[datasetIndex].data[index] as DataPoint).id;

      context.emit('selected', id);

    }

    return {
      canvas,
      onClick
    };
  },
});
