
import { defineComponent, onMounted, PropType, ref, Ref, toRef, watch } from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';
import { Bar } from "vue-chartjs";
import { 
  IonRow, 
  IonGrid, 
  IonCol, 
  IonSpinner
} from '@ionic/vue';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default defineComponent({
  name: "BarChart",
  components: { 
    Bar,
    IonRow, 
    IonGrid, 
    IonCol, 
    IonSpinner
  },
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    },
    cropName: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props) {
    const chartData = ref({});
    const cropId = toRef(props, 'cropId');
    const cropLabel = toRef(props, 'cropName');
    const dataLoaded = ref(false);

    const fetchChartData = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId.value}/chart-data/`);
        chartData.value = response.data;
        dataLoaded.value = true;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    onMounted(fetchChartData);

    watch(cropId, fetchChartData);

    const chartOptions = {
      plugins: {
        title: {
          display: true,
          text: frTranslations.YearComparisonSimilarCrop
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: frTranslations.UnitEuroProHa
          }
        }
      }
    };

    const getChartData = async (cropId: Ref<number>) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId.value}/chart-data/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    return {
      dataLoaded,
      cropLabel,
      chartData,
      chartOptions
    };
  },
});
