import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24a92aa3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "container",
  class: "inner"
}
const _hoisted_2 = { class: "ion-padding-vertical" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "ion-margin-vertical" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('NewPasswordTitle')), 1),
            _createElementVNode("div", null, [
              (_ctx.showMessage)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_ion_text, { class: "ion-padding-vertical" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('NewPasswordSuccessfull')), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_ion_button, {
                        href: "/login",
                        color: "light"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('ToLogin')), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_ion_text, { class: "ion-padding-vertical" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('NewPasswordText')), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("form", {
                      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.resetPassword && _ctx.resetPassword(...args)), ["prevent"])),
                      class: "ion-margin-vertical"
                    }, [
                      _createVNode(_component_ion_item, { class: "ion-margin-vertical" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            type: "password",
                            label: _ctx.translations[_ctx.currentLanguage].NewPassword,
                            "label-placement": "floating",
                            modelValue: _ctx.newPassword,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newPassword) = $event))
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_button, { type: "submit" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('ResetPassword')), 1)
                        ]),
                        _: 1
                      })
                    ], 32),
                    (_ctx.errorMessages)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("ul", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorMessages, (error, index) => {
                              return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(error), 1))
                            }), 128))
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}