import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import store from "@/store";
import router from "@/router";

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.AXIOS_BASE_URL,
});

const isTokenExpiredError = (error: AxiosError): boolean => {
    return error.response !== undefined && error.response.status === 401;
};

const isForbiddenError = (error: AxiosError): boolean => {
    return error.response !== undefined && error.response.status === 403;
};

const isError = (error: AxiosError): boolean => {
    return error.response !== undefined && error.response.status === 500;
};

// Request interceptor: Add the access token header
axiosInstance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Response interceptor: Handle 401 responses by attempting to refresh the token
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    async (error: AxiosError) => {
        if (!error.config) {
            return Promise.reject(error);
        }

        const { status } = error.response || {};

        if (status && [403, 500].includes(status)) {
            // Commit the error status to the Vuex store
            store.commit('setError', status);
            return Promise.reject(error);
        }
    
        const originalRequest = error.config;
        let originalRequestRetry = false;

        // If it's the refresh token request, reject immediately
        if (originalRequest.url?.endsWith('/token/refresh/')) {
            // Clear any tokens and redirect to login, as both tokens are now invalid
            store.dispatch('logout');
            return Promise.reject(error);
        }

        // If the error is not a 401 or if the request has already been retried, reject
        if (!isTokenExpiredError(error) || originalRequestRetry) {
            return Promise.reject(error);
        }

        // Mark this request as being retried
        originalRequestRetry = true;

        try {
            // Attempt to refresh the token
            await store.dispatch('refreshToken');

            // Get the new access token from storage
            const accessToken = localStorage.getItem("access_token");

            if (!accessToken) {
                throw new Error("Access token not found after refresh");
            }

            // Set the Authorization header with the new access token
            originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

            // Retry the original request with the new token
            return axiosInstance(originalRequest);

        } catch (refreshError) {
            // If the refresh fails, handle accordingly (e.g., logout)
            store.dispatch('logout');
            return Promise.reject(refreshError);
        }
    }
);

export default axiosInstance;
