
import { 
  IonSpinner,
  IonText
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref } from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';
import { CropOrganicFertilization } from "@/types/CropOrganicFertilization";

export default defineComponent({
  name: "CropOrganicFertilization",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    }
  },
  components: {
    IonText,
    IonSpinner
  },
  setup(props) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const cropOrganicFertilization = ref<CropOrganicFertilization>({});
    const dataLoaded = ref(false);

    const getCropOrganicFertilization = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/organic-fertilization/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    onMounted(async () => {
      if (props.cropId !== undefined && props.cropId !== null) {
        cropOrganicFertilization.value = await getCropOrganicFertilization(props.cropId);
        dataLoaded.value = true;
      }
    });

    return {
      translations,
      currentLanguage,
      dataLoaded,
      cropOrganicFertilization
    };
  },
});
