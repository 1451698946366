
import { 
  IonInput,
  IonCheckbox,
  IonButton,
  IonSpinner,
  IonText,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref, Ref, toRef, SetupContext, watch, reactive } from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';
import { 
  trashOutline, 
  addCircleOutline,
  arrowForwardOutline,
} from 'ionicons/icons';
import { CropMeca } from "@/types/CropMeca";
import { ActionItem } from "@/types/ActionItem";
import { OtherActionItem } from "@/types/OtherActionItem";
import { Material } from "@/types/Material";
import { Delegation } from "@/types/Delegation";
import { MecaCategory } from "@/types/MecaCategory";
import ImportDataModal from "@/modals/ImportDataModal.vue";
import AutoFillSelect from '@/components/AutoFillSelect.vue';

type DebounceFunction = (...args: any[]) => void;

export default defineComponent({
  name: "CropMecanicForm",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    },
    area: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  components: {
    ImportDataModal,
    IonInput,
    IonCheckbox,
    IonButton,
    IonSpinner,
    IonText,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    AutoFillSelect
  },
  setup(props, context: SetupContext) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const dataLoaded = ref(false);
    const errorMessage = ref('');
    const errorContainers: { [key: number]: HTMLElement | null } = reactive({});
    const errorMessageOther = ref('');
    const selectedCategory = ref(0);
    const area = toRef(props, 'area');
    const materials = ref<Material[]>([]);
    const delegations = ref<Delegation[]>([]);
    const costs = ref([]);
    const categories = ref([]);
    const cropMeca = ref<CropMeca>({});
    const freshActionItem = (categoryId: number): ActionItem => ({
      id: 0,
      crop_meca: cropMeca.value.id,
      category: categoryId,
      material: 0,
      number_passes: 0,
      area: 100,
      delegation: false,
      real_flow: 0,
      cuma_flow: 0,
      cost: 0,
      cost_pro_ha: 0,
      material_name: ''
    });
    const newActionItems = ref<ActionItem[]>([]);
    const freshOtherActionItem = (): OtherActionItem => ({
      id: 0,
      crop_meca: cropMeca.value.id,
      category: '',
      number_passes: 0,
      area: 100,
      real_flow: 0,
      cost: 0
    });
    const newOtherActionItem = ref<OtherActionItem>({});
    const materialRefs = ref<Record<number | string, typeof AutoFillSelect>>({});

    function debounce(fn: DebounceFunction, delay: number) {
      let timeoutId: number | null = null;
      return (...args: any[]) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = window.setTimeout(() => fn(...args), delay);
      };
    }
    
    const getMaterials = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/materials/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getDelegations = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/delegations/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getCosts = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/costs/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getCategories = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/meca-categories/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getCropMeca = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/meca/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const scrollToError = () => {
      const errorContainer = errorContainers[selectedCategory.value];
      if (errorContainer) {
        errorContainer.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest"  });
      } else {
        console.error('Error container not found for category', selectedCategory.value);
      }
    };

    const handleSelected = (option: any, item: any, field: string, update = false) => {
      item[field] = option ? option.id : null;
      if (update) {
        updateMaterialActionItemData([], item);
      }
      else {
        updateCumaFlow(item);
        updateCost(item);
      }
    }

    onMounted(async () => {
      if (props.cropId !== undefined && props.cropId !== null) {
        materials.value = await getMaterials();
        categories.value = await getCategories();
        delegations.value = await getDelegations();
        costs.value = await getCosts();
        cropMeca.value = await getCropMeca(props.cropId);
        newOtherActionItem.value = freshOtherActionItem();
        categories.value.forEach((category: MecaCategory) => {
          if (category.id) {
            newActionItems.value.push(freshActionItem(category.id));
          }
        });
        dataLoaded.value = true;
      }
    });

    const updateItems = (area: number) => {
      updateTotalMeca();
    }

    watch(area, updateItems);

    const getActions = (categoryId: number) => {
      if (cropMeca.value.action_items) {
        return cropMeca.value.action_items.filter((item: ActionItem) => {return item.category === categoryId});
      }
      return [];
    };

    const getNewItemByCategory = (categoryId: number) => {
      if (newActionItems.value) {
        return newActionItems.value.find((item: ActionItem) => {return item.category === categoryId});
      }
      return false;
    };

    const validateActionItem = (item: ActionItem): boolean => {
      if (!item.material) {
        if (item.category) {
          errorMessage.value = frTranslations.PleaseSelectMaterial;
          selectedCategory.value = item.category;
          scrollToError();
        }
        return false;
      }
      if (!item.number_passes) {
        if (item.category) {
          errorMessage.value = frTranslations.PleaseIndicateNumberOfPasses;
          selectedCategory.value = item.category;
          scrollToError();
        }
        return false;
      }
      errorMessage.value = "";
      selectedCategory.value = 0;
      return true;
    };

    const addActionItem = async (item: ActionItem) => {
      try {
        if (!validateActionItem(item)){
          return false;
        }
        const response = await axiosInstance.post(`api/ceta-marge/action-item/add/`, item);
        if (!cropMeca.value.action_items) {
          cropMeca.value.action_items = [];
        }
        cropMeca.value.action_items.push(response.data);
        updateCumaFlow(response.data);
        updateCost(response.data);
        updateTotalMeca();
        // Reset newActionItem to its default values
        newActionItems.value = newActionItems.value.filter((newItem: ActionItem) => newItem.category !== item.category);
        
        if (item.category) {
          if (materialRefs.value[item.category]) {
            materialRefs.value[item.category].resetComponent();
          }
          newActionItems.value.push(freshActionItem(item.category));
        }
        return true;
      } catch (error) {
        console.error('Error adding new action item:', error);
        return false;
      }
    };

    const updateDelegation = (item: ActionItem) => {
      updateCumaFlow(item);
      updateCost(item);
    };

    const updateMaterialActionItemData = (e: any, currentItem: ActionItem) => {
      updateCumaFlow(currentItem);
      updateCost(currentItem);
      updateActionItemData(e, currentItem);
    };

    const updateActionItemData = (e: any, currentItem: ActionItem) => {
      if (!validateActionItem(currentItem)){
        return;
      }
      updateActionItem(currentItem);
      updateTotalMeca();
    };

    const updateActionItem = async (data: ActionItem) => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/action-item/${data.id}/update/`, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const deleteActionItem = async (itemId: number) => {
      if (itemId) {
        try {
          await axiosInstance.delete(`api/ceta-marge/action-item/${itemId}/delete/`);
          if (cropMeca.value.action_items) {
            cropMeca.value.action_items = cropMeca.value.action_items.filter((item: ActionItem) => item.id !== itemId);
          }
          updateTotalMeca();
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    };

    const updateCumaFlow = (data: ActionItem) => {
      if (data.material) {
        const material = materials.value.find((material: Material) => material.id === data.material);
        if (material) {
          data.cuma_flow = material.cuma_flow;
          if (data.delegation) {
            data.real_flow = material.cuma_flow;
          }
        }
      }
    };

    const updateCost = (data: ActionItem) => {
      data.cost = 0;
      data.cost_pro_ha = 0;
      data.time = 0;
      let working_hour_rate = 0;
      if (data.material) {
        if (data.delegation) {
          const delegation = delegations.value.find((delegation: Delegation) => delegation.material === data.material);
          if (delegation) {
            if (delegation.delegation_cost) {
              data.cost = delegation.delegation_cost;
            }
            if (delegation.delegation_cost_workforce) {
              working_hour_rate = delegation.delegation_cost_workforce;
            }
          }
        }
        else {
          const material = materials.value.find((material: Material) => material.id === data.material);
          if (material && material.cost) {
            data.cost = material.cost;
          }
          if (cropMeca.value.working_hour_rate) {
            working_hour_rate = cropMeca.value.working_hour_rate;
          }
        }
      }
      if (data.cost && data.number_passes && data.area && area.value !== undefined) {
        const raw_cost_pro_ha = data.cost * data.number_passes * (data.area / 100);
        data.cost_pro_ha = parseFloat(raw_cost_pro_ha.toFixed(2));
      }
      if (data.real_flow && data.number_passes && data.area && area.value !== undefined) {
        const time = (data.real_flow * data.number_passes) * (data.area / 100) * area.value / 60;
        data.time = parseFloat(time.toFixed(2));
        data.working_hour_cost_pro_ha = Math.round((data.time * working_hour_rate / area.value) * 100) / 100;
      }
    };

    const copyCumaFlow = (data: ActionItem, update: boolean) => {
      data.real_flow = data.cuma_flow;
      if (update) {
        updateActionItemData([], data);
      }
    };

    const updateTotalMeca = () => {
      let material_cost_pro_ha = 0;
      let total_time = 0;
      let total_time_non_delegated = 0;
      let working_hour_cost_pro_ha = 0;
      let working_hour_cost_non_delegated_pro_ha = 0;
      if (cropMeca.value.action_items) {
        cropMeca.value.action_items.forEach((item: ActionItem) => {
          if (
            item.cost_pro_ha
          ) {
            material_cost_pro_ha += item.cost_pro_ha;
          }
          if (
            item.working_hour_cost_pro_ha
          ) {
            working_hour_cost_pro_ha += item.working_hour_cost_pro_ha;
            if (!item.delegation) {
              working_hour_cost_non_delegated_pro_ha += item.working_hour_cost_pro_ha;
            }
          }
          if (item.time) {
            total_time += item.time;
            if (!item.delegation) {
              total_time_non_delegated += item.time;
            }
          }
        });
      }
      if (cropMeca.value.other_action_items) {
        cropMeca.value.other_action_items.forEach((item: OtherActionItem) => {
          if (
            item.cost && 
            item.number_passes &&
            item.area && 
            area.value !== undefined
          ) {
            material_cost_pro_ha += item.cost;
            if (item.real_flow) {
              const time = (item.real_flow * item.number_passes) * (item.area / 100) * area.value / 60;
              if (cropMeca.value.working_hour_rate !== undefined) {
                const item_working_hour_cost_pro_ha = Math.round((time * cropMeca.value.working_hour_rate / area.value) * 100) / 100;
                working_hour_cost_non_delegated_pro_ha += item_working_hour_cost_pro_ha;
                working_hour_cost_pro_ha += item_working_hour_cost_pro_ha;
              }
              total_time += time
              total_time_non_delegated += time;
            }
          }
        });
      }
      cropMeca.value.material_cost_pro_ha = Math.round(material_cost_pro_ha * 100) / 100;
      cropMeca.value.working_hour_cost_pro_ha = Math.round(working_hour_cost_pro_ha * 100) / 100;
      cropMeca.value.working_hour_cost_non_delegated_pro_ha = Math.round(working_hour_cost_non_delegated_pro_ha * 100) / 100;
      cropMeca.value.total_time = Math.round(total_time * 100) / 100;
      cropMeca.value.time_pro_ha = Math.round((total_time / area.value) * 100) / 100;
      cropMeca.value.time_non_delegated_pro_ha = Math.round((total_time_non_delegated / area.value) * 100) / 100;
      cropMeca.value.cost_pro_ha = Math.round((material_cost_pro_ha + working_hour_cost_pro_ha) * 100 ) / 100;
      if (area.value) {
        const total_material_cost = Math.round((cropMeca.value.material_cost_pro_ha * area.value) * 100) / 100;
        const total_working_hour_cost = Math.round((cropMeca.value.working_hour_cost_pro_ha * area.value) * 100) / 100;
        const total_cost = total_material_cost + total_working_hour_cost;
        cropMeca.value.material_cost = parseFloat(total_material_cost.toFixed(2));
        cropMeca.value.working_hour_cost = parseFloat(total_working_hour_cost.toFixed(2));
        cropMeca.value.total_cost = parseFloat(total_cost.toFixed(2));
      }
      updateCropMeca();
    }

    const validateOtherActionItem = (item: OtherActionItem): boolean => {
      if (!item.category) {
        errorMessageOther.value = frTranslations.PleaseSelectMaterial;
        return false;
      }
      if (!item.number_passes) {
        item.number_passes = 1;
      }
      errorMessageOther.value = "";
      return true;
    };

    const addOtherActionItem = async (item: OtherActionItem) => {
      try {
        if (!validateOtherActionItem(item)){
          return false;
        }
        const response = await axiosInstance.post(`api/ceta-marge/other-action-item/add/`, item);
        if (!cropMeca.value.other_action_items) {
          cropMeca.value.other_action_items = [];
        }
        cropMeca.value.other_action_items.push(response.data);
        updateTotalMeca();
        // Reset newOtherActionItem to its default values
        newOtherActionItem.value = freshOtherActionItem();
        return true;
      } catch (error) {
        console.error('Error adding new other action item:', error);
        return false;
      }
    };
    
    const updateOtherActionItemData = (e: any, currentItem: OtherActionItem) => {
      if (!validateOtherActionItem(currentItem)){
        return;
      }
      updateOtherActionItem(currentItem);
      updateTotalMeca();
    };

    const updateOtherActionItem = async (data: OtherActionItem) => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/other-action-item/${data.id}/update/`, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const deleteOtherActionItem = async (itemId: number) => {
      if (itemId) {
        try {
          await axiosInstance.delete(`api/ceta-marge/other-action-item/${itemId}/delete/`);
          if (cropMeca.value.other_action_items) {
            cropMeca.value.other_action_items = cropMeca.value.other_action_items.filter((item: OtherActionItem) => item.id !== itemId);
          }
          updateTotalMeca();
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    };

    const updateCropMeca = async () => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/crop/${props.cropId}/meca/update/`, cropMeca.value);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const handleImportData = async (id: number) => {
      if (id) {
        cropMeca.value = await duplicate(props.cropId, id);
      }
    };

    const duplicate = async (cropId: number, duplicateId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/meca/duplicate/${duplicateId}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const nextStep = async (e: any) => {
      let valid = true;
      for (const category of categories.value as MecaCategory[]) {
        if (category.id) {
          const newItem = getNewItemByCategory(category.id);
          if (newItem && newItem.material) {
            valid = await addActionItem(newItem);
            if (!valid) {
              break;  // Exit the loop if valid becomes false
            }
          }
        }
      }
      if (newOtherActionItem.value && newOtherActionItem.value.category) {
        valid = await addOtherActionItem(newOtherActionItem.value);
      }
      if (valid) {
        emit('next');
      }
    };

    const handleDebouncedupdate = debounce(updateMaterialActionItemData, 300);

    const previousStep = (e: any) => {
      emit('previous');
    };

    const emit = (eventName: string, ...args: any[]) => {
      context.emit(eventName, ...args);
    };

    return {
      dataLoaded,
      translations,
      currentLanguage,
      nextStep,
      previousStep,
      errorMessage,
      errorContainers,
      errorMessageOther,
      trashOutline, 
      addCircleOutline,
      arrowForwardOutline,
      cropMeca,
      materials,
      categories,
      selectedCategory,
      getActions,
      getNewItemByCategory,
      addActionItem,
      updateMaterialActionItemData,
      handleDebouncedupdate,
      deleteActionItem,
      updateCumaFlow,
      copyCumaFlow,
      newOtherActionItem,
      addOtherActionItem,
      updateOtherActionItemData,
      deleteOtherActionItem,
      updateCropMeca,
      handleImportData,
      handleSelected,
      materialRefs,
      updateCost,
      updateDelegation
    };
  },
});
