
import { 
  IonGrid,
  IonCol, 
  IonInput,
  IonCheckbox,
  IonText,
  IonSpinner
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref, Ref, toRef, SetupContext } from "vue";
import axiosInstance from "@/axiosInstance";
import { CropArea } from "@/types/CropArea";
import { IntermediateCrop } from "@/types/IntermediateCrop";
import { Rotation } from "@/types/Rotation";
import frTranslations from '@/locales/fr.json';
import ImportDataModal from "@/modals/ImportDataModal.vue";
import AutoFillSelect from '@/components/AutoFillSelect.vue';

export default defineComponent({
  name: "CropAreaForm",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  components: {
    IonGrid,
    IonCol,
    IonInput,
    IonCheckbox,
    IonText,
    IonSpinner,
    ImportDataModal,
    AutoFillSelect
  },
  setup(props, context: SetupContext) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const dataLoaded = ref(false);
    const cropArea = ref<CropArea>({});
    const intermediateCrops = ref([]);
    const previousCrops = ref([]);
    const rotations = ref([]);
    const intermediateCrop = ref<IntermediateCrop>({});
    const selectedRotation = ref<Rotation>({});
    const errorMessage = ref<string | null>(null);

    const getIntermediateCrops = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/intermediate-crops/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getPreviousCrops = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/harvests/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };
  
    const getRotations = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/rotations/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    onMounted(async () => {
      if (props.cropId !== undefined && props.cropId !== null) {
        intermediateCrops.value = await getIntermediateCrops();
        previousCrops.value = await getPreviousCrops();
        rotations.value = await getRotations();
        cropArea.value = await getCropArea(props.cropId);
        const foundRotation = rotations.value.find((rotation: Rotation) => rotation.id === cropArea.value.rotation);
        if (foundRotation) {
          selectedRotation.value = foundRotation;
        }
        getIntermediateCrop();
        dataLoaded.value = true;
      }
    });

    const updateCropAreaData = (e: any, cropArea: CropArea) => {
      if (!cropArea.crop_yield){
        cropArea.crop_yield = 0;
      }
      if (!cropArea.crop_yield_target){
        cropArea.crop_yield_target = 0;
      }
      if (!cropArea.area){
        cropArea.area = 0;
      }
      if (!cropArea.straw_yield){
        cropArea.straw_yield = 0;
      }

      getIntermediateCrop();
      emit('areaChange', cropArea.area)
      updateCropArea(cropArea);
    }

    const getIntermediateCrop = () => {
      if (intermediateCrops.value && cropArea.value.intermediate_crop) {
        const intermediate_crop = intermediateCrops.value.find((crop: IntermediateCrop) => crop.id === cropArea.value.intermediate_crop);
        if (intermediate_crop) {
          intermediateCrop.value = intermediate_crop;
        }
      }
    }

    const getCropArea = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/area/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const updateCropArea = async (data: CropArea) => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/crop/${props.cropId}/area/update/`, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const handleImportData = async (duplicateAreaId: number) => {
      if (duplicateAreaId) {
        const crop_area_id = cropArea.value.id;
        cropArea.value = await getCropArea(duplicateAreaId);
        cropArea.value.id = crop_area_id;
      }
    }

    const handleSelected = (option: any, item: any, field: string, update = false) => {
      item[field] = option ? ((field == "previous_crop") ? option.id_crop : option.id) : null;
      if (field == "rotation") {
        selectedRotation.value = option;
      }
      if (update) {
        updateCropAreaData([], item);
      }
    };

    const nextStep = (e: any, cropArea: CropArea) => {
      updateCropArea(cropArea);
      emit('next');
    };

    const previousStep = (e: any, cropArea: CropArea) => {
      emit('previous');
    };

    const emit = (eventName: string, ...args: any[]) => {
      context.emit(eventName, ...args);
    };

    return {
      dataLoaded,
      cropArea,
      updateCropAreaData,
      intermediateCrop,
      translations,
      currentLanguage,
      intermediateCrops,
      previousCrops,
      rotations,
      nextStep,
      previousStep,
      errorMessage,
      handleImportData,
      handleSelected,
      selectedRotation
    };
  },
});
