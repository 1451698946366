
import { 
  IonButton,
  IonSpinner,
  IonGrid,
  IonCol, 
  IonInput,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonText,
  IonModal
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref, Ref, toRef, SetupContext } from "vue";
import axiosInstance from "@/axiosInstance";
import { CropSeed } from "@/types/CropSeed";
import { MixedCrop } from "@/types/MixedCrop";
import frTranslations from '@/locales/fr.json';
import { 
  trashOutline, 
  addCircleOutline,
  calculatorOutline
} from 'ionicons/icons';
import ImportDataModal from "@/modals/ImportDataModal.vue";
import AutoFillSelect from '@/components/AutoFillSelect.vue';

export default defineComponent({
  name: "CropSeedForm",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    },
    area: {
      type: Number as PropType<number>,
      required: true,
    },
    mixedCrop: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  components: {
    ImportDataModal,
    IonButton,
    IonSpinner,
    IonGrid,
    IonCol,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonCheckbox,
    IonText,
    IonModal,
    AutoFillSelect
  },
  setup(props, context: SetupContext) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const dataLoaded = ref(false);
    const area = toRef(props, 'area');
    const cropSeed = ref<CropSeed>({});
    const sewingTypes = ref([]);
    const sewingDates = ref([]);
    const showModalFarmSeedTotalCost = ref(false);
    const harvests = ref([]);
    const freshMixedCrop = (): MixedCrop => ({
      id: 0,
      crop_seed: cropSeed.value.id,
      harvest: 0,
      ratio: 0,
    });
    const newMixedCrop = ref<MixedCrop>(freshMixedCrop());
    const errorMessage = ref<string | null>(null);
    const errorMessageMixedCrop = ref<string | null>(null);
    const selectMixedCrop = ref<InstanceType<typeof AutoFillSelect> | null>(null)
   
    const getSewingTypes = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/sewing-types/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getSewingDates = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/sewing-dates/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    onMounted(async () => {
      
     if (props.cropId !== undefined && props.cropId !== null) {
        sewingTypes.value = await getSewingTypes();
        sewingDates.value = await getSewingDates();
        cropSeed.value = await getCropSeed(props.cropId);
        updateCropSeedPrice();
        newMixedCrop.value.crop_seed = cropSeed.value.id;
        harvests.value = await getHarvests();
        dataLoaded.value = true;
      }
      
    });

    const validateCropSeed = (): boolean => {
      if (
        cropSeed.value.area_farm_seed && 
        area.value &&
        cropSeed.value.area_farm_seed > area.value
      ) {
        errorMessage.value = frTranslations.FarmSeedAreaGreaterThanArea
        return false;
      }
      if (
        cropSeed.value.area_certified_seed && 
        area.value &&
        cropSeed.value.area_certified_seed > area.value
      ) {
        errorMessage.value = frTranslations.CertifiedSeedAreaGreaterThanArea
        return false;
      }
      if (
        cropSeed.value.area_farm_seed &&
        cropSeed.value.area_certified_seed && 
        area.value &&
        (cropSeed.value.area_certified_seed + cropSeed.value.area_farm_seed) > area.value
      ) {
        errorMessage.value = frTranslations.SumAreaSeedAreaGreaterThanArea
        return false;
      }
      errorMessage.value = null;
      return true;
    };

    const updateCropSeedData = () => {
      if (!validateCropSeed()){
        return;
      }
      updateCropSeedPrice();
      updateCropSeed(cropSeed.value);
    }

    const updateTreatmentSeed = () => {
      emit('treatmentSeedChange', cropSeed.value.treatment_seed);
      updateFarmSeedCost();
    }

    const updateFarmSeedCost = () => {
      let farm_seed_cost = (cropSeed.value.price_farm_seed ?? 0) 
        + (cropSeed.value.sorting_cost_farm_seed ?? 0);
      if (cropSeed.value.treatment_seed) {
        farm_seed_cost += (cropSeed.value.treatment_cost_farm_seed ?? 0);
      }
      else {
        cropSeed.value.treatment_cost_farm_seed = 0;
      }
      cropSeed.value.cost_farm_seed = farm_seed_cost;
    }

    const updateTotalFarmSeedCost = () => {
      if (
        cropSeed.value.sewing_density_kg && 
        cropSeed.value.cost_farm_seed
        ) {
        const raw_price = (cropSeed.value.cost_farm_seed / 1000) * cropSeed.value.sewing_density_kg;
        cropSeed.value.total_cost_farm_seed = Math.round(raw_price * 100) / 100;
        updateCropSeedPrice();
      }
    }

    const handleSelected = (option: any, item: any, field: string, update = false) => {
      item[field] = option ? option.id_crop : null;
      if (update) {
        updateMixedCropData([], item);
      }
    }

    const updateCropSeedPrice = () => {
      cropSeed.value.seed_total_cost = calculateSeedTotalCost();
    }

    const calculateSeedTotalCost = () => {
      let totalCost = 0;
      let areaFarmSeed = 0;
      let areaCertifiedSeed = 0;
      if (area.value) {
        if (cropSeed.value.area_farm_seed) {
          areaFarmSeed = Number(cropSeed.value.area_farm_seed);
        }
        if (cropSeed.value.area_certified_seed) {
          areaCertifiedSeed = Number(cropSeed.value.area_certified_seed);
        }
        areaCertifiedSeed = Number(cropSeed.value.area_certified_seed);
        totalCost = (
          ((cropSeed.value.total_cost_farm_seed ?? 0) * areaFarmSeed)
          + ((cropSeed.value.cost_certified_seed ?? 0) * areaCertifiedSeed)
        ) / area.value;
      }
      return Math.round(totalCost * 100) / 100;
    };

    const applyFarmSeedCost = () => {
      updateTotalFarmSeedCost();
      showModalFarmSeedTotalCost.value = false;
      if (!validateCropSeed()){
        return;
      }
      updateCropSeed(cropSeed.value);
    };

    const getHarvests = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/harvests/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getCropSeed = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/seed/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const validateMixedCrop = (mixedCrop: MixedCrop): boolean => {
      if (!mixedCrop.harvest) {
        errorMessageMixedCrop.value = "Veuillez sélectionner une culture.";
        return false;
      }
      if (!mixedCrop.ratio) {
        errorMessageMixedCrop.value = "Veuillez préciser le pourcentage du semé.";
        return false;
      }
      errorMessageMixedCrop.value = null;
      return true;
    };

    const addMixedCrop = async () => {
      try {
        if (!validateMixedCrop(newMixedCrop.value)){
          return false;
        }
        const response = await axiosInstance.post(`api/ceta-marge/mixed-crop/add/`, newMixedCrop.value);
        if (!cropSeed.value.mixed_crops) {
          cropSeed.value.mixed_crops = [];
        }
        cropSeed.value.mixed_crops.push(response.data);
        // Reset newMixedCrop to its default values
        newMixedCrop.value = freshMixedCrop();
        selectMixedCrop.value?.resetComponent();
        return true;
      } catch (error) {
        console.error('Error adding new mixed crop:', error);
        return false;
      }
    }

    const updateMixedCropData = (e: any, mixedCrop: MixedCrop) => {
      if (!validateMixedCrop(mixedCrop)){
        return;
      }
      updateMixedCrop(mixedCrop);
    }

    const updateMixedCrop = async (data: MixedCrop) => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/mixed-crop/${data.id}/update/`, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const deleteMixedCrop = async (mixedCropId: number) => {
      if (mixedCropId) {
        try {
          await axiosInstance.delete(`api/ceta-marge/mixed-crop/${mixedCropId}/delete/`);
          if (cropSeed.value.mixed_crops) {
            cropSeed.value.mixed_crops= cropSeed.value.mixed_crops.filter((mixedCrop: MixedCrop) => mixedCrop.id !== mixedCropId);
          }
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    };

    const updateCropSeed = async (data: CropSeed) => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/crop/${props.cropId}/seed/update/`, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const handleImportData = async (duplicateSeedId: number) => {
      if (duplicateSeedId) {
        const crop_seed_id = cropSeed.value.id;
        cropSeed.value = await getCropSeed(duplicateSeedId);
        cropSeed.value.id = crop_seed_id;  
      }
    }

    const nextStep = async (e: any, cropSeed: CropSeed) => {
      const valid = ref(false);
      if (newMixedCrop.value?.harvest) {
        valid.value = await addMixedCrop();
      }
      else {
        valid.value = true;
      }
      if (valid.value === true) {
        updateCropSeed(cropSeed);
        emit('next');
      }
    };

    const previousStep = (e: any, cropSeed: CropSeed) => {
      emit('previous');
    };

    const emit = (eventName: string, ...args: any[]) => {
      context.emit(eventName, ...args);
    };

    return {
      dataLoaded,
      cropSeed,
      updateCropSeedData,
      updateFarmSeedCost,
      updateTreatmentSeed,
      translations,
      currentLanguage,
      sewingTypes,
      sewingDates,
      harvests,
      nextStep,
      previousStep,
      showModalFarmSeedTotalCost,
      applyFarmSeedCost,
      newMixedCrop,
      updateMixedCropData,
      deleteMixedCrop,
      addMixedCrop,
      errorMessage,
      errorMessageMixedCrop,
      trashOutline, 
      addCircleOutline,
      calculatorOutline,
      updateCropSeedPrice,
      handleImportData,
      handleSelected,
      selectMixedCrop
    };
  },
});
