import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94613d0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "ion-margin" }
const _hoisted_4 = { class: "ion-text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_import_data_modal = _resolveComponent("import-data-modal")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_auto_fill_select = _resolveComponent("auto-fill-select")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dataLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_spinner),
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('LoadingData')), 1)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_import_data_modal, {
            crop: _ctx.cropId,
            step: "area",
            onImportData: _ctx.handleImportData
          }, null, 8, ["crop", "onImportData"]),
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-justify-content-left ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "9",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        type: "number",
                        fill: "solid",
                        "label-placement": "floating",
                        class: "user-input",
                        label: _ctx.translations[_ctx.currentLanguage].Area,
                        min: "0",
                        modelValue: _ctx.cropArea.area,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cropArea.area) = $event)),
                        modelModifiers: { number: true },
                        onIonBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateCropAreaData($event, _ctx.cropArea)))
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "3",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('UnitHa')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "9",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        type: "number",
                        fill: "solid",
                        min: "0",
                        "label-placement": "floating",
                        label: _ctx.translations[_ctx.currentLanguage].Yield,
                        modelValue: _ctx.cropArea.crop_yield,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.cropArea.crop_yield) = $event)),
                        modelModifiers: { number: true },
                        onIonBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateCropAreaData($event, _ctx.cropArea)))
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "3",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.cropArea.yield_unit == 'T')
                        ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('UnitTonProHa')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_text, { key: 1 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('UnitQuintalProHa')), 1)
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "9",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        type: "number",
                        min: "0",
                        fill: "solid",
                        "label-placement": "floating",
                        label: _ctx.translations[_ctx.currentLanguage].YieldTarget,
                        modelValue: _ctx.cropArea.crop_yield_target,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.cropArea.crop_yield_target) = $event)),
                        modelModifiers: { number: true },
                        onIonBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateCropAreaData($event, _ctx.cropArea)))
                      }, null, 8, ["label", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "3",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.cropArea.yield_unit == 'T')
                        ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('UnitTonProHa')), 1)
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_text, { key: 1 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('UnitQuintalProHa')), 1)
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.cropArea.show_straw)
                ? (_openBlock(), _createBlock(_component_ion_row, {
                    key: 0,
                    class: "ion-justify-content-left ion-align-items-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        size: "12",
                        "size-md": "auto"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_checkbox, {
                            modelValue: _ctx.cropArea.straw,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.cropArea.straw) = $event)),
                            onIonChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateCropAreaData($event, _ctx.cropArea))),
                            labelPlacement: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('Straw')), 1)
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      (_ctx.cropArea.straw)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 0,
                            size: "9",
                            "size-md": "auto"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_input, {
                                fill: "solid",
                                type: "number",
                                min: "0",
                                "label-placement": "floating",
                                label: _ctx.translations[_ctx.currentLanguage].YieldStraw,
                                modelValue: _ctx.cropArea.straw_yield,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.cropArea.straw_yield) = $event)),
                                modelModifiers: { number: true },
                                onIonBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateCropAreaData($event, _ctx.cropArea)))
                              }, null, 8, ["label", "modelValue"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.cropArea.straw)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 1,
                            size: "3",
                            "size-md": "auto"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('UnitTonProHa')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-justify-content-left ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "5"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('IntermediateCrop')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_auto_fill_select, {
                        options: _ctx.intermediateCrops,
                        searchField: "name",
                        selected: _ctx.cropArea.intermediate_crop_name,
                        label: _ctx.translations[_ctx.currentLanguage].SelectIntermediateCrop,
                        placeholder: _ctx.translations[_ctx.currentLanguage].Search,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = option => _ctx.handleSelected(option, _ctx.cropArea, 'intermediate_crop', true))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" >")
                        ]),
                        _: 1
                      }, 8, ["options", "selected", "label", "placeholder"])
                    ]),
                    _: 1
                  }),
                  (_ctx.cropArea.intermediate_crop > 0)
                    ? (_openBlock(), _createBlock(_component_ion_col, {
                        key: 0,
                        size: "9",
                        "size-md": "5"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            fill: "solid",
                            "label-placement": "floating",
                            label: _ctx.translations[_ctx.currentLanguage].YieldIntermediateCrop,
                            modelValue: _ctx.cropArea.intermediate_crop_yield,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.cropArea.intermediate_crop_yield) = $event)),
                            onIonBlur: _cache[12] || (_cache[12] = ($event: any) => (_ctx.updateCropAreaData($event, _ctx.cropArea)))
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.intermediateCrop.id > 0)
                    ? (_openBlock(), _createBlock(_component_ion_col, {
                        key: 1,
                        size: "3",
                        "size-md": "2"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.intermediateCrop.display_quintals_ton == 'T')
                            ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('UnitTonProHa')), 1)
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_ion_text, { key: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('UnitQuintalProHa')), 1)
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('Previous')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_auto_fill_select, {
                        options: _ctx.previousCrops,
                        searchField: "crop_name",
                        selected: _ctx.cropArea.previous_crop_name,
                        label: _ctx.translations[_ctx.currentLanguage].Previous,
                        placeholder: _ctx.translations[_ctx.currentLanguage].Search,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = option => _ctx.handleSelected(option, _ctx.cropArea, 'previous_crop', true))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" >")
                        ]),
                        _: 1
                      }, 8, ["options", "selected", "label", "placeholder"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { class: "ion-justify-content-left ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('Rotation')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_auto_fill_select, {
                        options: _ctx.rotations,
                        searchField: "name",
                        selected: _ctx.cropArea.rotation_name,
                        label: _ctx.translations[_ctx.currentLanguage].Rotation,
                        placeholder: _ctx.translations[_ctx.currentLanguage].Search,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = option => _ctx.handleSelected(option, _ctx.cropArea, 'rotation', true))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" >")
                        ]),
                        _: 1
                      }, 8, ["options", "selected", "label", "placeholder"])
                    ]),
                    _: 1
                  }),
                  (_ctx.selectedRotation.has_colza)
                    ? (_openBlock(), _createBlock(_component_ion_col, {
                        key: 0,
                        size: "9",
                        "size-md": "auto"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            type: "number",
                            "label-placement": "floating",
                            fill: "solid",
                            class: "ion-text-right",
                            label: _ctx.translations[_ctx.currentLanguage].ColzaLoopInRotation,
                            modelValue: _ctx.cropArea.rotation_colza_year,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.cropArea.rotation_colza_year) = $event)),
                            modelModifiers: { number: true },
                            onIonBlur: _cache[16] || (_cache[16] = ($event: any) => (_ctx.updateCropAreaData($event, _ctx.cropArea)))
                          }, null, 8, ["label", "modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.selectedRotation.has_colza)
                    ? (_openBlock(), _createBlock(_component_ion_col, {
                        key: 1,
                        size: "3",
                        "size-md": "auto"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('Years')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.errorMessage)
              ? (_openBlock(), _createBlock(_component_ion_text, {
                  key: 0,
                  color: "danger"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ion_button, {
              color: "primary",
              onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.nextStep($event, _ctx.cropArea)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Next')), 1)
              ]),
              _: 1
            })
          ])
        ]))
  ]))
}