import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_wrong_data_modal = _resolveComponent("wrong-data-modal")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_general_data_form = _resolveComponent("general-data-form")!
  const _component_dairy_cattle_data_form = _resolveComponent("dairy-cattle-data-form")!
  const _component_dairy_goat_sheep_data_form = _resolveComponent("dairy-goat-sheep-data-form")!
  const _component_pork_data_form = _resolveComponent("pork-data-form")!
  const _component_poultry_data_form = _resolveComponent("poultry-data-form")!
  const _component_sales_culture_data_form = _resolveComponent("sales-culture-data-form")!
  const _component_methanization_data_form = _resolveComponent("methanization-data-form")!
  const _component_photovoltaic_data_form = _resolveComponent("photovoltaic-data-form")!
  const _component_BaseLayout = _resolveComponent("BaseLayout")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dataLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_page, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_spinner),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('LoadingData')), 1)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_BaseLayout, null, {
            title: _withCtx(() => [
              (_ctx.userInfo && _ctx.userInfo.user_type == 'member')
                ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Dashboard')), 1)
                    ]),
                    _: 1
                  }))
                : (_ctx.company)
                  ? (_openBlock(), _createBlock(_component_ion_title, { key: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('Company')) + " " + _toDisplayString(_ctx.company.name), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
            ]),
            content: _withCtx(() => [
              (_ctx.userInfo && _ctx.userInfo.user_type == 'member')
                ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.$t('Welcome')) + ", " + _toDisplayString(_ctx.userInfo.first_name) + " " + _toDisplayString(_ctx.userInfo.last_name) + " !", 1))
                : _createCommentVNode("", true),
              (_ctx.company)
                ? (_openBlock(), _createBlock(_component_ion_grid, { key: 1 }, {
                    default: _withCtx(() => [
                      (_ctx.userInfo.user_type == 'member')
                        ? (_openBlock(), _createBlock(_component_ion_row, {
                            key: 0,
                            class: "ion-justify-content-start ion-align-items-center"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, { size: "auto" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('MyTools')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, { size: "auto" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_button, {
                                    fill: "solid",
                                    color: "primary",
                                    href: `/exploitation/${_ctx.slug}/cultures`
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('cetaMarge')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["href"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_row, {
                            key: 1,
                            class: "ion-justify-content-start ion-align-items-center"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, { size: "auto" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_button, {
                                    fill: "solid",
                                    color: "light",
                                    href: "/"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('BackToDashboard')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, { size: "auto" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_button, {
                                    fill: "solid",
                                    color: "primary",
                                    href: `/exploitation/${_ctx.slug}/cultures`
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('cetaMarge')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["href"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })),
                      _createVNode(_component_ion_row, { class: "ion-justify-content-between" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            size: "12",
                            "size-md": "9"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("h2", null, _toDisplayString(_ctx.company.name), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, {
                            size: "12",
                            "size-md": "3"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_wrong_data_modal, {
                                "company-id": _ctx.company.id
                              }, null, 8, ["company-id"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, { class: "equal-height-row" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            size: "12",
                            "size-md": "6",
                            "size-lg": "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('Informations')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_col, {
                                            size: "12",
                                            "size-md": "auto"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('Address')), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_col, {
                                            size: "12",
                                            "size-md": "auto"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", null, _toDisplayString(_ctx.company.address) + ", " + _toDisplayString(_ctx.company.postal_code) + " - " + _toDisplayString(_ctx.company.location), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_col, {
                                            size: "12",
                                            "size-md": "auto"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('Email')), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_col, {
                                            size: "12",
                                            "size-md": "auto"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", null, _toDisplayString(_ctx.company.email), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_col, {
                                            size: "12",
                                            "size-md": "auto"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('Phone')), 1)
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_col, {
                                            size: "12",
                                            "size-md": "auto"
                                          }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("div", null, _toDisplayString(_ctx.company.phone), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, {
                            size: "12",
                            "size-md": "6",
                            "size-lg": "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('Members')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("table", null, [
                                        _createElementVNode("th", null, _toDisplayString(_ctx.$t('Name')), 1),
                                        _createElementVNode("th", null, _toDisplayString(_ctx.$t('Status')), 1),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.company.members, (member, index) => {
                                          return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                            _createElementVNode("td", null, _toDisplayString(member.user.first_name) + " " + _toDisplayString(member.user.last_name), 1),
                                            _createElementVNode("td", null, _toDisplayString(member.status), 1)
                                          ]))
                                        }), 128))
                                      ])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, {
                            size: "12",
                            "size-lg": "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('Memberships')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      (_ctx.company.memberships.length > 0)
                                        ? (_openBlock(), _createElementBlock("table", _hoisted_4, [
                                            _createElementVNode("thead", null, [
                                              _createElementVNode("th", null, _toDisplayString(_ctx.$t('Group')), 1),
                                              _createElementVNode("th", null, _toDisplayString(_ctx.$t('Member')), 1),
                                              _createElementVNode("th", null, _toDisplayString(_ctx.$t('Role')), 1)
                                            ]),
                                            _createElementVNode("tbody", null, [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.company.memberships, (membership, index) => {
                                                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                                  _createElementVNode("td", null, _toDisplayString(membership.group.name), 1),
                                                  _createElementVNode("td", null, _toDisplayString(membership.member.user.first_name) + " " + _toDisplayString(membership.member.user.last_name), 1),
                                                  _createElementVNode("td", null, _toDisplayString(membership.role), 1)
                                                ]))
                                              }), 128))
                                            ])
                                          ]))
                                        : (_openBlock(), _createBlock(_component_ion_text, { key: 1 }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.$t('NoMemberships')), 1)
                                            ]),
                                            _: 1
                                          }))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('CompanyData')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, { class: "equal-height-row" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('Certifications')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_col, { class: "ion-justify-content-between ion-align-items-center" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('BIO')), 1),
                                              (_ctx.company_data.bio_certification)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.checkmarkOutline,
                                                      size: "medium"
                                                    }, null, 8, ["icon"])
                                                  ]))
                                                : (_openBlock(), _createElementBlock("span", _hoisted_6, [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.closeOutline,
                                                      size: "medium"
                                                    }, null, 8, ["icon"])
                                                  ]))
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_col, { class: "ion-justify-content-between ion-align-items-center" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('HVE')), 1),
                                              (_ctx.company_data.heifer_breeding_delegation)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.checkmarkOutline,
                                                      size: "medium"
                                                    }, null, 8, ["icon"])
                                                  ]))
                                                : (_openBlock(), _createElementBlock("span", _hoisted_8, [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.closeOutline,
                                                      size: "medium"
                                                    }, null, 8, ["icon"])
                                                  ]))
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_col, { class: "ion-justify-content-between ion-align-items-center" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('Others')), 1),
                                              _createElementVNode("span", null, _toDisplayString(_ctx.company_data.other_certifications), 1)
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_row, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_col, { class: "ion-justify-content-between ion-align-items-center" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('ShortNetwork')), 1),
                                              (_ctx.company_data.short_network_sale)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                                    _createVNode(_component_ion_icon, {
                                                      color: "primary",
                                                      icon: _ctx.checkmarkOutline,
                                                      size: "medium"
                                                    }, null, 8, ["icon"])
                                                  ]))
                                                : (_openBlock(), _createElementBlock("span", _hoisted_10, [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.closeOutline,
                                                      size: "medium"
                                                    }, null, 8, ["icon"])
                                                  ]))
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_col, { class: "ion-justify-content-between ion-align-items-center" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TransfoFarmProducts')), 1),
                                              (_ctx.company_data.farm_products_transfo)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.checkmarkOutline,
                                                      color: "primary",
                                                      size: "medium"
                                                    }, null, 8, ["icon"])
                                                  ]))
                                                : (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.closeOutline,
                                                      size: "medium"
                                                    }, null, 8, ["icon"])
                                                  ]))
                                            ]),
                                            _: 1
                                          }),
                                          _createVNode(_component_ion_col, { class: "ion-justify-content-between ion-align-items-center" }, {
                                            default: _withCtx(() => [
                                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('MOEmployer')), 1),
                                              (_ctx.company_data.mo_employer)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.checkmarkOutline,
                                                      color: "primary",
                                                      size: "medium"
                                                    }, null, 8, ["icon"])
                                                  ]))
                                                : (_openBlock(), _createElementBlock("span", _hoisted_14, [
                                                    _createVNode(_component_ion_icon, {
                                                      icon: _ctx.closeOutline,
                                                      size: "medium"
                                                    }, null, 8, ["icon"])
                                                  ]))
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, {
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('SAU')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.company_data.sau) + " ha ", 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_grid, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, null, {
                        default: _withCtx(() => [
                          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('ProductionData')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_row, { class: "equal-height-row" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        size: "12",
                        "size-md": "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card_header, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_title, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('GeneralData')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_card_content, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_general_data_form, {
                                    "company-id": _ctx.company.id
                                  }, null, 8, ["company-id"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.hasProduction('Bovins lait') == true)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 0,
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('DairyCattleProduction')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_dairy_cattle_data_form, {
                                        "company-id": _ctx.company.id
                                      }, null, 8, ["company-id"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.hasProduction('Caprins lait') == true)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 1,
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('DairyGoatSheepProduction')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_dairy_goat_sheep_data_form, {
                                        "company-id": _ctx.company.id
                                      }, null, 8, ["company-id"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.hasProduction('Porcs') == true)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 2,
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('PorkProduction')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_pork_data_form, {
                                        "company-id": _ctx.company.id
                                      }, null, 8, ["company-id"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.hasProduction('Volailles') == true)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 3,
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('PoultryProduction')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_poultry_data_form, {
                                        "company-id": _ctx.company.id
                                      }, null, 8, ["company-id"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.hasProduction('Cultures de vente') == true)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 4,
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('SalesCultureProduction')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_sales_culture_data_form, {
                                        "company-id": _ctx.company.id
                                      }, null, 8, ["company-id"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.hasProduction('Méthanisation') == true)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 5,
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('Methanization')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_methanization_data_form, {
                                        "company-id": _ctx.company.id
                                      }, null, 8, ["company-id"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.hasProduction('Photovoltaïque') == true)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 6,
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_header, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('Photovoltaic')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_photovoltaic_data_form, {
                                        "company-id": _ctx.company.id
                                      }, null, 8, ["company-id"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
  ]))
}