
import { 
  IonGrid,
  IonCol, 
  IonText,
  IonSpinner
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref } from "vue";
import axiosInstance from "@/axiosInstance";
import { CropArea } from "@/types/CropArea";
import frTranslations from '@/locales/fr.json';

export default defineComponent({
  name: "CropArea",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  components: {
    IonGrid,
    IonCol,
    IonText,
    IonSpinner
  },
  setup(props) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const dataLoaded = ref(false);
    const cropArea = ref<CropArea>({});

    onMounted(async () => {
      if (props.cropId !== undefined && props.cropId !== null) {
        cropArea.value = await getCropArea(props.cropId);
        dataLoaded.value = true;
      }
    });

    const getCropArea = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/area/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    return {
      dataLoaded,
      cropArea,
      translations,
      currentLanguage
    };
  },
});
