import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-316243d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "ion-text-end" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "message ion-margin" }
const _hoisted_7 = { class: "ion-padding-horitzontal ion-margin-vertical" }
const _hoisted_8 = {
  key: 1,
  class: "ion-padding-horitzontal ion-margin-vertical"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { key: 3 }
const _hoisted_13 = { key: 4 }
const _hoisted_14 = { key: 5 }
const _hoisted_15 = { key: 6 }
const _hoisted_16 = {
  key: 1,
  class: "ion-padding-horitzontal ion-margin-vertical"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_crop_area = _resolveComponent("crop-area")!
  const _component_crop_seed = _resolveComponent("crop-seed")!
  const _component_crop_mineral_fertilization = _resolveComponent("crop-mineral-fertilization")!
  const _component_crop_organic_fertilization = _resolveComponent("crop-organic-fertilization")!
  const _component_crop_liming_fertilization = _resolveComponent("crop-liming-fertilization")!
  const _component_crop_mecanic = _resolveComponent("crop-mecanic")!
  const _component_crop_treatment = _resolveComponent("crop-treatment")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dataLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_spinner),
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('LoadingData')), 1)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ion_button, {
              color: "light",
              onClick: _ctx.openModal
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('ImportData')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createVNode(_component_ion_modal, {
            "is-open": _ctx.showModal,
            onIonModalWillDismiss: _ctx.closeModal
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, { class: "ion-padding ion-margin-vertical" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('ImportDataModalTitle')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_content, { class: "ion-padding ion-margin-vertical" }, {
                default: _withCtx(() => [
                  (_ctx.crops.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_ion_text, { class: "ion-padding-vertical" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('ImportDataModalDescription')), 1)
                          ]),
                          _: 1
                        }),
                        (_ctx.showMessage)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('DataImported')), 1),
                              _createVNode(_component_ion_button, {
                                onClick: _ctx.closeModal,
                                color: "light"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('Close')), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_ion_select, {
                            fill: "solid",
                            "label-placement": "floating",
                            label: _ctx.translations[_ctx.currentLanguage].Year,
                            placeholder: _ctx.translations[_ctx.currentLanguage].YearPlaceholder,
                            modelValue: _ctx.cropToImport,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cropToImport) = $event)),
                            "cancel-text": _ctx.translations[_ctx.currentLanguage].Cancel,
                            "ok-text": _ctx.translations[_ctx.currentLanguage].Choose
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.crops, (crop, index) => {
                                return (_openBlock(), _createBlock(_component_ion_select_option, {
                                  key: index,
                                  value: crop.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(crop.year) + " - " + _toDisplayString(crop.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["label", "placeholder", "modelValue", "cancel-text", "ok-text"])
                        ]),
                        (_ctx.cropToImport)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              (_ctx.step == 'area')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                    _createVNode(_component_crop_area, { cropId: _ctx.cropToImport }, null, 8, ["cropId"])
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.step == 'seed')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                    _createVNode(_component_crop_seed, { cropId: _ctx.cropToImport }, null, 8, ["cropId"])
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.step == 'mineral-fertilization')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                    _createVNode(_component_crop_mineral_fertilization, { cropId: _ctx.cropToImport }, null, 8, ["cropId"])
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.step == 'organic-fertilization')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                    _createVNode(_component_crop_organic_fertilization, { cropId: _ctx.cropToImport }, null, 8, ["cropId"])
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.step == 'liming')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    _createVNode(_component_crop_liming_fertilization, { cropId: _ctx.cropToImport }, null, 8, ["cropId"])
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.step == 'meca')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                    _createVNode(_component_crop_mecanic, { cropId: _ctx.cropToImport }, null, 8, ["cropId"])
                                  ]))
                                : _createCommentVNode("", true),
                              (_ctx.step == 'treatment')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                    _createVNode(_component_crop_treatment, { cropId: _ctx.cropToImport }, null, 8, ["cropId"])
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createVNode(_component_ion_text, { class: "ion-padding" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('NoPreviousCampaign')), 1)
                          ]),
                          _: 1
                        })
                      ])),
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, { class: "ion-margin-vertical ion-justify-content-end" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, { size: "auto" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, { onClick: _ctx.importData }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('Confirm')), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, { size: "auto" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                onClick: _ctx.closeModal,
                                color: "light"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('Cancel')), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["is-open", "onIonModalWillDismiss"])
        ]))
  ]))
}