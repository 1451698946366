import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aed8eb58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "ion-margin-vertical" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { slot: "start" }
const _hoisted_6 = { class: "ion-text-center" }
const _hoisted_7 = { class: "ion-text-center" }
const _hoisted_8 = { class: "ion-text-center" }
const _hoisted_9 = { class: "ion-text-center" }
const _hoisted_10 = { class: "ion-text-center" }
const _hoisted_11 = { class: "ion-text-center" }
const _hoisted_12 = { class: "ion-text-center" }
const _hoisted_13 = { class: "ion-text-center" }
const _hoisted_14 = { slot: "start" }
const _hoisted_15 = { class: "ion-text-center" }
const _hoisted_16 = { class: "ion-text-center" }
const _hoisted_17 = { class: "ion-text-center" }
const _hoisted_18 = { class: "ion-text-center" }
const _hoisted_19 = { class: "flex-container" }
const _hoisted_20 = { class: "ion-text-center" }
const _hoisted_21 = { class: "flex-container" }
const _hoisted_22 = { class: "ion-text-center" }
const _hoisted_23 = { class: "flex-container" }
const _hoisted_24 = { class: "ion-text-center" }
const _hoisted_25 = { class: "flex-container" }
const _hoisted_26 = { class: "ion-text-center" }
const _hoisted_27 = { class: "ion-margin-vertical" }
const _hoisted_28 = { class: "summary-card" }
const _hoisted_29 = { class: "responsive-table" }
const _hoisted_30 = { class: "data" }
const _hoisted_31 = { class: "data" }
const _hoisted_32 = { class: "data" }
const _hoisted_33 = { class: "data" }
const _hoisted_34 = { class: "data" }
const _hoisted_35 = { class: "data" }
const _hoisted_36 = { class: "data" }
const _hoisted_37 = { class: "data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dataLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_spinner),
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('LoadingData')), 1)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, index_category) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index_category,
                class: "ion-margin-vertical"
              }, [
                (_ctx.getTreatments(category.id).length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_ion_toolbar, { class: "ion-margin-vertical" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_ion_text, { class: "ion-padding-horizontal" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(category.name), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        _: 2
                      }, 1024),
                      _createElementVNode("table", null, [
                        _createElementVNode("thead", null, [
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('Product')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('ProductType')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('AreaTreated')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('TotalQuantity')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('DoseProSpreadHa')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('Cost')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('CostProHa')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('IFT')), 1)
                        ]),
                        _createElementVNode("tbody", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTreatments(category.id), (item, index) => {
                            return (_openBlock(), _createElementBlock("tr", { key: index }, [
                              _createElementVNode("td", _hoisted_6, [
                                _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.product_name), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _createElementVNode("td", _hoisted_7, [
                                _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.product_type_name), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _createElementVNode("td", _hoisted_8, [
                                _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.area_treated), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _createElementVNode("td", _hoisted_9, [
                                _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.total_quantity) + " " + _toDisplayString(item.unit.replace('/ha', '').toUpperCase()), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _createElementVNode("td", _hoisted_10, [
                                _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.dose_pro_spread_ha) + " " + _toDisplayString(item.unit.replace('/ha', '').toUpperCase()) + " " + _toDisplayString(_ctx.$t('UnitProHa')), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _createElementVNode("td", _hoisted_11, [
                                _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.cost) + " " + _toDisplayString(_ctx.$t('UnitEuro')) + " / " + _toDisplayString(item.unit.replace('/ha', '').toUpperCase()), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _createElementVNode("td", _hoisted_12, [
                                _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.cost_pro_ha) + " " + _toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _createElementVNode("td", _hoisted_13, [
                                _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.ift), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cropTreatment.treatment_passes, (pass, index_pass) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index_pass,
                class: "ion-margin-vertical"
              }, [
                _createVNode(_component_ion_toolbar, { class: "ion-margin-vertical" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_ion_text, { class: "ion-padding-horizontal" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('TreatmentPass')) + " " + _toDisplayString(index_pass + 1), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _createVNode(_component_ion_text, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(pass.plant_state_name), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("table", null, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('Product')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('ProductType')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('AreaTreated')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('TotalQuantity')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('DoseProHa')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('Cost')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('CostProHa')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('IFT')), 1)
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPassTreatments(pass.id), (item, index) => {
                      return (_openBlock(), _createElementBlock("tr", { key: index }, [
                        _createElementVNode("td", _hoisted_15, [
                          _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.product_name), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("td", _hoisted_16, [
                          _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.product_type_name), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("td", _hoisted_17, [
                          _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.area_treated), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("td", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, [
                            _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.total_quantity) + " " + _toDisplayString(item.unit.replace('/ha', '').toUpperCase()), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_20, [
                          _createElementVNode("div", _hoisted_21, [
                            _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.dose_pro_ha) + " " + _toDisplayString(item.unit.replace('/ha', '').toUpperCase()) + " " + _toDisplayString(_ctx.$t('UnitProHa')), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_22, [
                          _createElementVNode("div", _hoisted_23, [
                            _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.cost) + " " + _toDisplayString(_ctx.$t('UnitEuro')) + " / " + _toDisplayString(item.unit.replace('/ha', '').toUpperCase()), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_24, [
                          _createElementVNode("div", _hoisted_25, [
                            _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.cost_pro_ha) + " " + _toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_26, [
                          _createVNode(_component_ion_text, { class: "ion-text-center" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.ift), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      size: "auto",
                      class: "flex-container"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_28, [
                          _createElementVNode("div", _hoisted_29, [
                            _createElementVNode("table", null, [
                              _createElementVNode("tr", null, [
                                _createElementVNode("td", null, _toDisplayString(_ctx.$t('IFTHerbicide')), 1),
                                _createElementVNode("td", _hoisted_30, _toDisplayString(_ctx.cropTreatment.ift_herbicide), 1),
                                _createElementVNode("td", null, _toDisplayString(_ctx.$t('IFTExcludingHerbicide')), 1),
                                _createElementVNode("td", _hoisted_31, _toDisplayString(_ctx.cropTreatment.ift_excluding_herbicide), 1),
                                _createElementVNode("td", null, _toDisplayString(_ctx.$t('IFTGlyphosate')), 1),
                                _createElementVNode("td", _hoisted_32, _toDisplayString(_ctx.cropTreatment.ift_glyphosate), 1),
                                _createElementVNode("td", null, _toDisplayString(_ctx.$t('IFTTotal')), 1),
                                _createElementVNode("td", _hoisted_33, _toDisplayString(_ctx.cropTreatment.ift_total), 1)
                              ]),
                              _createElementVNode("tr", null, [
                                _createElementVNode("td", null, _toDisplayString(_ctx.$t('CostHerbicide')), 1),
                                _createElementVNode("td", _hoisted_34, _toDisplayString(_ctx.cropTreatment.cost_herbicide_pro_ha) + " " + _toDisplayString(_ctx.$t('UnitEuroProHa')), 1),
                                _createElementVNode("td", null, _toDisplayString(_ctx.$t('CostExcludingHerbicide')), 1),
                                _createElementVNode("td", _hoisted_35, _toDisplayString(_ctx.cropTreatment.cost_excluding_herbicide_pro_ha) + " " + _toDisplayString(_ctx.$t('UnitEuroProHa')), 1),
                                _createElementVNode("td", null, _toDisplayString(_ctx.$t('CostGlyphosate')), 1),
                                _createElementVNode("td", _hoisted_36, _toDisplayString(_ctx.cropTreatment.cost_glyphosate_pro_ha) + " " + _toDisplayString(_ctx.$t('UnitEuroProHa')), 1),
                                _createElementVNode("td", null, _toDisplayString(_ctx.$t('TotalCost')), 1),
                                _createElementVNode("td", _hoisted_37, _toDisplayString(_ctx.cropTreatment.cost_pro_ha) + " " + _toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]))
  ]))
}