import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-458b36d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "ion-margin-vertical" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { slot: "start" }
const _hoisted_6 = { class: "ion-text-center" }
const _hoisted_7 = { class: "ion-text-center" }
const _hoisted_8 = { class: "ion-text-center" }
const _hoisted_9 = { class: "ion-text-center" }
const _hoisted_10 = { class: "ion-text-center" }
const _hoisted_11 = { class: "ion-text-center" }
const _hoisted_12 = { class: "ion-text-center" }
const _hoisted_13 = {
  key: 0,
  class: "ion-margin-vertical"
}
const _hoisted_14 = { slot: "start" }
const _hoisted_15 = { class: "ion-text-center" }
const _hoisted_16 = { class: "ion-text-center" }
const _hoisted_17 = { class: "ion-text-center" }
const _hoisted_18 = { class: "ion-text-center" }
const _hoisted_19 = { class: "ion-text-center" }
const _hoisted_20 = { class: "ion-text-center" }
const _hoisted_21 = { class: "ion-margin-vertical" }
const _hoisted_22 = { class: "summary-card" }
const _hoisted_23 = { class: "flex-container" }
const _hoisted_24 = { class: "flex-container" }
const _hoisted_25 = { class: "flex-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dataLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_spinner),
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('LoadingData')), 1)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, index_category) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index_category,
                class: "ion-margin-vertical"
              }, [
                (_ctx.getActions(category.id).length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_ion_toolbar, { class: "ion-margin-vertical" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_ion_text, { class: "ion-padding-horizontal" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(category.name), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        _: 2
                      }, 1024),
                      _createElementVNode("table", null, [
                        _createElementVNode("thead", null, [
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('Material')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('NumberPasses')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('PercentArea')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('Delegation')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('MaterialTractorAndGNRCost')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('CUMAFlow')), 1),
                          _createElementVNode("th", null, _toDisplayString(_ctx.$t('RealFlow')), 1)
                        ]),
                        _createElementVNode("tbody", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getActions(category.id), (item, index) => {
                            return (_openBlock(), _createElementBlock("tr", { key: index }, [
                              _createElementVNode("td", _hoisted_6, _toDisplayString(item.material_name), 1),
                              _createElementVNode("td", _hoisted_7, _toDisplayString(item.number_passes), 1),
                              _createElementVNode("td", _hoisted_8, _toDisplayString(item.area), 1),
                              _createElementVNode("td", _hoisted_9, [
                                (_ctx.delegation)
                                  ? (_openBlock(), _createBlock(_component_ion_icon, {
                                      key: 0,
                                      icon: _ctx.checkmarkOutline
                                    }, null, 8, ["icon"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("td", _hoisted_10, [
                                _createVNode(_component_ion_text, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.cost_pro_ha) + " " + _toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _createElementVNode("td", _hoisted_11, [
                                _createVNode(_component_ion_text, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.cuma_flow) + " " + _toDisplayString(_ctx.$t('UnitMnHa')), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _createElementVNode("td", _hoisted_12, [
                                _createVNode(_component_ion_text, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.real_flow) + " " + _toDisplayString(_ctx.$t('UnitMnHa')), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ])
                            ]))
                          }), 128))
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (_ctx.cropMeca.other_action_items.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createVNode(_component_ion_toolbar, { class: "ion-margin-vertical" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_ion_text, { class: "ion-padding-horizontal" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Others')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("table", null, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("th", null, _toDisplayString(_ctx.$t('Material')), 1),
                      _createElementVNode("th", null, _toDisplayString(_ctx.$t('NumberPasses')), 1),
                      _createElementVNode("th", null, _toDisplayString(_ctx.$t('PercentArea')), 1),
                      _createElementVNode("th", null, _toDisplayString(_ctx.$t('RealFlow')) + " " + _toDisplayString(_ctx.$t('UnitMnHa')), 1),
                      _createElementVNode("th", null, _toDisplayString(_ctx.$t('Cost')) + " " + _toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                    ]),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cropMeca.other_action_items, (item, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _createElementVNode("td", _hoisted_15, [
                            _createVNode(_component_ion_text, { class: "ion-text-center" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.category), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _createElementVNode("td", _hoisted_16, [
                            _createVNode(_component_ion_text, { class: "ion-text-center" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.number_passes), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _createElementVNode("td", _hoisted_17, [
                            _createVNode(_component_ion_text, { class: "ion-text-center" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.area), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _createElementVNode("td", _hoisted_18, [
                            _createVNode(_component_ion_text, { class: "ion-text-center" }, {
                              default: _withCtx(() => [
                                (item.delegation)
                                  ? (_openBlock(), _createBlock(_component_ion_icon, {
                                      key: 0,
                                      icon: _ctx.checkmarkOutline
                                    }, null, 8, ["icon"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _createElementVNode("td", _hoisted_19, [
                            _createVNode(_component_ion_text, { class: "ion-text-center" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.real_flow) + " " + _toDisplayString(_ctx.$t('UnitMnHa')), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _createElementVNode("td", _hoisted_20, [
                            _createVNode(_component_ion_text, { class: "ion-text-center" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.cost) + " " + _toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]))
                      }), 128))
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      size: "auto",
                      class: "flex-container"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_22, [
                          _createVNode(_component_ion_grid, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_col, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('TotalMecaMaterialCost')), 1),
                                      _createElementVNode("div", _hoisted_23, [
                                        _createVNode(_component_ion_text, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.cropMeca.material_cost_pro_ha), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                                          ]),
                                          _: 1
                                        })
                                      ])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_col, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('TotalMecaWorkingHourCost')), 1),
                                      _createElementVNode("div", _hoisted_24, [
                                        _createVNode(_component_ion_text, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.cropMeca.working_hour_cost_pro_ha), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                                          ]),
                                          _: 1
                                        })
                                      ])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_col, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('TotalMecaCostExcludingStructurCost')), 1),
                                      _createElementVNode("div", _hoisted_25, [
                                        _createVNode(_component_ion_text, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.cropMeca.cost_pro_ha), 1)
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                                          ]),
                                          _: 1
                                        })
                                      ])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]))
  ]))
}