
import {  
  IonTitle,
  IonButton
} from '@ionic/vue';
import BaseLayout from '../../layouts/BaseLayout.vue';
import { defineComponent } from "vue";

export default defineComponent({
  name: 'AccessRestricted',
  components: {
    BaseLayout,
    IonTitle,
    IonButton
  },
  setup() {

    return {
    };
  },
});
