
import { 
  IonSpinner,
  IonText,
  IonToolbar
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref } from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';
import { 
  checkmarkOutline
} from 'ionicons/icons';
import { CropMeca } from "@/types/CropMeca";
import { ActionItem } from "@/types/ActionItem";

export default defineComponent({
  name: "CropMecanic",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    }
  },
  components: {
    IonSpinner,
    IonText,
    IonToolbar,
  },
  setup(props) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const dataLoaded = ref(false);
    const categories = ref([]);
    const cropMeca = ref<CropMeca>({});

    const getCategories = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/meca-categories/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getCropMeca = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/meca/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    onMounted(async () => {
      if (props.cropId !== undefined && props.cropId !== null) {
        categories.value = await getCategories();
        cropMeca.value = await getCropMeca(props.cropId);
        dataLoaded.value = true;
      }
    });

    const getActions = (categoryId: number) => {
      if (cropMeca.value.action_items) {
        return cropMeca.value.action_items.filter((item: ActionItem) => {return item.category === categoryId});
      }
      return [];
    };

    return {
      dataLoaded,
      translations,
      currentLanguage,
      checkmarkOutline,
      cropMeca,
      categories,
      getActions
    };
  },
});
