
import { 
  IonButton,
  IonInput,
  IonText
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref, toRef, Ref, SetupContext, watch } from "vue";
import axiosInstance from "@/axiosInstance";
import { AxiosError } from "axios";
import frTranslations from '@/locales/fr.json';
import { 
  trashOutline, 
  addCircleOutline,
  arrowForwardOutline
} from 'ionicons/icons';
import { CropOrganicFertilization } from "@/types/CropOrganicFertilization";
import { OrganicFertiItem } from "@/types/OrganicFertiItem";
import { Fertilizer } from "@/types/Fertilizer";
import ImportDataModal from "@/modals/ImportDataModal.vue";
import AutoFillSelect from '@/components/AutoFillSelect.vue';

type DebounceFunction = (...args: any[]) => void;

export default defineComponent({
  name: "CropOrganicFertilizationForm",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    },
    area: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  components: {
    ImportDataModal,
    IonButton,
    IonText,
    IonInput,
    AutoFillSelect
  },
  setup(props, context: SetupContext) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const area = toRef(props, 'area');
    const errorMessage = ref<string | null>(null);
    const productTypes = ref<Fertilizer[]>([]);
    const periods = ref<{ [key: number]: any }>({});
    const newItemPeriods = ref([]);
    const cropOrganicFertilization = ref<CropOrganicFertilization>({});
    const dataLoaded = ref(false);
    const selectProduct = ref<InstanceType<typeof AutoFillSelect> | null>(null)
    const selectPeriod = ref<InstanceType<typeof AutoFillSelect> | null>(null)
    const freshOrganicFertiItem = (): OrganicFertiItem => ({
      id: 0,
      crop_organic_ferti: cropOrganicFertilization.value.id,
      product: 0,
      period: 0,
      nitrogen_content: 0,
      area_spread_percent: 100,
      total_quantity: 0,
      cost: 0,
      quantity_pro_ha: 0,
      quantity_pro_spread_ha: 0,
      nitrogen_efficacity_coeff: 0,
      units_n: 0,
      units_p: 0,
      units_k: 0,
      product_name: '',
      period_name: ''
    });
    const newCropOrganicFertilizationItem = ref<OrganicFertiItem>(freshOrganicFertiItem());

    function debounce(fn: DebounceFunction, delay: number) {
      let timeoutId: number | null = null;
      return (...args: any[]) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = window.setTimeout(() => fn(...args), delay);
      };
    }
    
    const getProductTypes = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/organic-fertilizer-types/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getPeriods = async (productId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${props.cropId}/periods/${productId}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getPeriodsForProduct = (productId: number) => {
      if (productId) {
        return periods.value[productId];
      }
      return [];
    };

    const handleSelected = (option: any, item: any, field: string, update = false) => {
      item[field] = option ? option.id : null;
      if (update) {
        updateOrganicFertiItemData([], item);
      }
      else {
        updateNewFertiItemData();
      }
    }

    const updateNewFertiItemData = async () => {
      if (newCropOrganicFertilizationItem.value.product) {
        const product = productTypes.value.find((product: Fertilizer) => product.id == newCropOrganicFertilizationItem.value.product);
        if (product && product.formulation_n) {
          newCropOrganicFertilizationItem.value.nitrogen_content = product.formulation_n;
        }
      }
      const reference = await getReference(newCropOrganicFertilizationItem.value);
      if (
        reference &&
        reference.nitrogen_efficacity_coeff
      ) {
        newCropOrganicFertilizationItem.value.nitrogen_efficacity_coeff = reference.nitrogen_efficacity_coeff;
      }
    };

    const getReference = async (item: OrganicFertiItem) => {
      if (
        item.product &&
        item.period
      ) {
        try {
          const response = await axiosInstance.post(`api/ceta-marge/crop/${props.cropId}/nitrogen-coeff/`, item);
           errorMessage.value = null;
          return response.data;
        } catch (error: any) {
          console.error(error);
          if (error.response.status === 404) {
            errorMessage.value = frTranslations.NoCoeffFound;
          }
          else {
            errorMessage.value = frTranslations.ErrorPageText;
          }
        }
      }
      return false;
    };

    const getCropOrganicFertilization = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/organic-fertilization/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    onMounted(async () => {
      if (props.cropId !== undefined && props.cropId !== null) {
        productTypes.value = await getProductTypes();
        for (const productType of productTypes.value) {
          if (productType.id) {
            periods.value[productType.id] = await getPeriods(productType.id);
          }
        }
        cropOrganicFertilization.value = await getCropOrganicFertilization(props.cropId);
        newCropOrganicFertilizationItem.value.crop_organic_ferti = cropOrganicFertilization.value.id;
        dataLoaded.value = true;
      }
    });

    const updateItems = (area: number) => {
      if (cropOrganicFertilization.value.organic_fertilization_items) {
        cropOrganicFertilization.value.organic_fertilization_items.forEach((item: OrganicFertiItem) => {
          estimateTotalQuantity(item);
        });
      }
    }

    watch(area, updateItems);

    const validateOrganicFertiItem = (item: OrganicFertiItem): boolean => {
      if (!item.product) {
        errorMessage.value = "Veuillez sélectionner un produit.";
        return false;
      }
      if (!item.period) {
        errorMessage.value = "Veuillez sélectionner une période.";
        return false;
      }
      
      errorMessage.value = null;
      return true;
    };

    const addOrganicFertiItem = async () => {
      try {
        if (!validateOrganicFertiItem(newCropOrganicFertilizationItem.value)){
          return false;
        }
        const response = await axiosInstance.post(`api/ceta-marge/organic-fertilization-item/add/`, newCropOrganicFertilizationItem.value);
        if (!cropOrganicFertilization.value.organic_fertilization_items) {
          cropOrganicFertilization.value.organic_fertilization_items = [];
        }
        cropOrganicFertilization.value.organic_fertilization_items.push(response.data);
        updateTotalFertilizerQuantity();
        // Reset newCropOrganicFertilizationItem to its default values
        newCropOrganicFertilizationItem.value = freshOrganicFertiItem();
        selectProduct.value?.resetComponent();
        selectPeriod.value?.resetComponent();
        return true;
      } catch (error) {
        console.error('Error adding new fertilisation item:', error);
        return false;
      }
    };

    const estimateTotalQuantity = (currentItem: OrganicFertiItem) => {
      if (
        currentItem.area_spread_percent && 
        area.value && 
        currentItem.quantity_pro_spread_ha
      ) {
        const raw_quantity = currentItem.quantity_pro_spread_ha * (currentItem.area_spread_percent / 100) * area.value;
        currentItem.total_quantity = parseFloat(raw_quantity.toFixed(2));
        const raw_quantity_pro_ha = raw_quantity / area.value;
        currentItem.quantity_pro_ha = parseFloat(raw_quantity_pro_ha.toFixed(2));
        updateItem(currentItem);
      }
    };

    const updateItem = async (item: OrganicFertiItem) => {
      const reference = await getReference(item);
      if (
        reference &&
        reference.nitrogen_efficacity_coeff
      ) {
        item.nitrogen_efficacity_coeff = reference.nitrogen_efficacity_coeff;
        updateFertilizerQuantity(item);
        if (item.id) {
          updateOrganicFertiItem(item);
        }
      }      
    };

    const updateFertilizerQuantity = (currentItem: OrganicFertiItem) => {
      if (
        currentItem.quantity_pro_spread_ha &&
        currentItem.product &&
        currentItem.area_spread_percent
      ) {

        currentItem.quantity_pro_ha = currentItem.quantity_pro_spread_ha * currentItem.area_spread_percent / 100;
        const product: Fertilizer | undefined = productTypes.value.find((item: Fertilizer) => item.id === currentItem.product);
        if (product && product.formulation_n && currentItem.nitrogen_efficacity_coeff) {
          const raw_n = product.formulation_n * currentItem.quantity_pro_ha * currentItem.nitrogen_efficacity_coeff;
          currentItem.units_n = parseFloat(raw_n.toFixed(2));
        }
        if (product && product.formulation_p) {
          const raw_p = product.formulation_p * currentItem.quantity_pro_ha;
          currentItem.units_p = parseFloat(raw_p.toFixed(2));
        }
        if (product && product.formulation_k) {
          const raw_k = product.formulation_k * currentItem.quantity_pro_ha;
          currentItem.units_k = parseFloat(raw_k.toFixed(2));
        }
        updateTotalFertilizerQuantity();
      }
    };

    const updateTotalFertilizerQuantity = () => {
      let units_n = 0;
      let units_p = 0;
      let units_k = 0;
      let cost = 0;
      if (cropOrganicFertilization.value.organic_fertilization_items) {
        cropOrganicFertilization.value.organic_fertilization_items.forEach((item: OrganicFertiItem) => {
          if (item.units_n) {
            units_n += item.units_n;
          }
          if (item.units_p) {
            units_p += item.units_p;
          }
          if (item.units_k) {
            units_k += item.units_k;
          }
          if (item.cost && item.total_quantity && area.value && area.value > 0) {
            cost += item.cost * item.total_quantity / area.value;
          }
        });
        cropOrganicFertilization.value.units_n = parseFloat(units_n.toFixed(2));
        cropOrganicFertilization.value.units_p = parseFloat(units_p.toFixed(2));
        cropOrganicFertilization.value.units_k = parseFloat(units_k.toFixed(2));
        cropOrganicFertilization.value.cost = Math.round(cost * 100) / 100;
      }
      updateCropOrganicFerti();
    };

     const updateCropOrganicFerti = async () => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/crop/${props.cropId}/organic-fertilization/update/`, cropOrganicFertilization.value);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const updateOrganicFertiItemData = (e: any, currentItem: OrganicFertiItem) => {
      if (!validateOrganicFertiItem(currentItem)){
        return;
      }
      updateItem(currentItem);
    };

    const updateOrganicFertiItem = async (data: OrganicFertiItem) => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/organic-fertilization-item/${data.id}/update/`, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const deleteOrganicFertiItem = async (itemId: number) => {
      if (itemId) {
        try {
          await axiosInstance.delete(`api/ceta-marge/organic-fertilization-item/${itemId}/delete/`);
          if (cropOrganicFertilization.value.organic_fertilization_items) {
            cropOrganicFertilization.value.organic_fertilization_items = cropOrganicFertilization.value.organic_fertilization_items.filter((item: OrganicFertiItem) => item.id !== itemId);
          }
          updateTotalFertilizerQuantity();
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    };

    const handleImportData = async (id: number) => {
      if (id) {
        cropOrganicFertilization.value = await duplicate(props.cropId, id);
      }
    };

    const duplicate = async (cropId: number, duplicateId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/organic-fertilization/duplicate/${duplicateId}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const handleDebouncedupdateOrganicFertiItemData = debounce(updateOrganicFertiItemData, 300);

    const nextStep = async (e: any) => {
      const valid = ref(false);
      if (newCropOrganicFertilizationItem.value?.product) {
        valid.value = await addOrganicFertiItem();
      }
      else {
        valid.value = true;
      }
      if (valid.value === true) {
        emit('next');
      }
    };

    const previousStep = (e: any) => {
      emit('previous');
    };

    const emit = (eventName: string, ...args: any[]) => {
      context.emit(eventName, ...args);
    };

    return {
      translations,
      currentLanguage,
      dataLoaded,
      trashOutline, 
      addCircleOutline,
      arrowForwardOutline,
      productTypes,
      newItemPeriods,
      cropOrganicFertilization,
      newCropOrganicFertilizationItem,
      addOrganicFertiItem,
      deleteOrganicFertiItem,
      errorMessage,
      nextStep,
      previousStep,
      handleDebouncedupdateOrganicFertiItemData,
      estimateTotalQuantity,
      updateNewFertiItemData,
      updateItem,
      handleImportData,
      handleSelected,
      selectProduct,
      selectPeriod,
      getPeriodsForProduct
    };
  },
});
