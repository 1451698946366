
import { defineComponent, ref } from 'vue';
import { IonPage, IonContent, IonInput, IonButton, IonTitle } from '@ionic/vue';
import axiosInstance from '@/axiosInstance';
import frTranslations from '@/locales/fr.json';

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonInput,
    IonButton
  },
  setup() {
    const email = ref('');
    const showMessage = ref(false);
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };

    const requestReset = async () => {
      try {
        const response = await axiosInstance.post('api/core/password_reset/', {
           email: email.value
        });
      } catch (error) {
        console.error('There was an error sending the reset email', error);
      }
      finally {
        showMessage.value = true;
      }
    };

    return {
      email,
      showMessage,
      requestReset,
      translations,
      currentLanguage
    };
  },
});
