import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import store from '@/store';
import UserLogin from '../views/UserLogin.vue';
import EmployeeDashboard from '../views/EmployeeDashboard.vue';
import UserDashboard from '../views/UserDashboard.vue';
import ResetEmail from '../views/ResetEmail.vue';
import ResetPassword from '../views/ResetPassword.vue';
import CetaMarge from '../views/CetaMarge.vue';
import CetaMargeCrop from '../views/CetaMargeCrop.vue';
import CetaMargeCampaign from '../views/CetaMargeCampaign.vue';
import CampaignForm from '@/forms/CetaMarge/CampaignForm.vue';
import CropForm from '@/forms/CetaMarge/CropForm.vue';
import NotFound from "@/components/errors/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "dashboard",
    component: EmployeeDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/exploitation/:slug",
    name: "company-detail",
    component: UserDashboard,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/exploitation/:slug/cultures",
    name: "ceta-marge",
    component: CetaMarge,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/exploitation/:slug/cultures/:year",
    name: "ceta-marge-campaign",
    component: CetaMargeCampaign,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/exploitation/:slug/cultures/:year/saisie",
    name: "ceta-marge-campaign-form",
    component: CampaignForm,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/exploitation/:slug/cultures/:year/saisie/:cropId",
    name: "ceta-marge-crop-form",
    component: CropForm,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/exploitation/:slug/cultures/:year/consultation/:cropId",
    name: "ceta-marge-crop",
    component: CetaMargeCrop,
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/login",
    name: "login",
    component: UserLogin,
    meta: {
      guestOnly: true,
    },
  },
  {
    path: "/mot-de-passe-oublie",
    name: "forgotten-password",
    component: ResetEmail,
    meta: {
      guestOnly: true,
    },
  },
  {
    path: "/nouveau-mot-de-passe",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      guestOnly: true,
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: NotFound,
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['isAuthenticated'];
  const usertype = store.getters['getUserType'];
  const companySlug = store.getters['getCompanySlug'];
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'login' });
  } else if (to.meta.guestOnly && isAuthenticated) {
    if (usertype == "member") {
      next({ name: 'company-detail', params: {slug: companySlug}});
    }
    else {
      next({ name: 'dashboard' });
    }
  } else if (to.name == 'dashboard' && usertype == "member" && companySlug) {
    next({ name: 'company-detail', params: {slug: companySlug}});
  }
  else {
    next();
  }
});

export default router;