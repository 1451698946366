import { createStore } from 'vuex';
import axiosInstance from '@/axiosInstance';
import router from "@/router";

const store = createStore({
  state: {
    accessToken: localStorage.getItem('access_token') || '',
    refreshToken: localStorage.getItem('refresh_token') || '',
    userType: localStorage.getItem('user_type') || '',
    companySlug: localStorage.getItem('company_slug') || '',
    currentError: null,
  },
  getters: {
    isAuthenticated: (state) => !!state.accessToken,
    getUserType: (state) => state.userType,
    getCompanySlug: (state) => state.companySlug,
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
    },
    clearTokens(state) {
      state.accessToken = '';
      state.refreshToken = '';
    },
    setUserType(state, userType) {
      state.userType = userType;
    },
    setCompanySlug(state, companySlug) {
      state.companySlug = companySlug;
    },
    setError(state, status) {
      state.currentError = status;
    },
    clearError(state) {
      state.currentError = null;
    }
  },
  actions: {
    async login({ state, dispatch, commit }, { username, password }) {
      try {
        const response = await axiosInstance.post(`api/core/token/`, {
          username,
          password,
        });

        const accessToken = response.data.access;
        const refreshToken = response.data.refresh;

        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('refresh_token', refreshToken);

        commit('setAccessToken', accessToken);
        commit('setRefreshToken', refreshToken);

        await dispatch('fetchUserInfo');
        
        if (state.userType === 'member' && state.companySlug) {
          router.push({ name: 'company-detail', params: { slug: state.companySlug } });
        } else if (state.userType === 'employee') {
          router.push({ name: 'dashboard' });
        } else {
          router.push({ name: 'dashboard' });
        }

      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async refreshToken({ commit, state, dispatch }) {
      try {
        const response = await axiosInstance.post(`api/core/token/refresh/`, {
          refresh: state.refreshToken,
        });
    
        const accessToken = response.data.access;
    
        localStorage.setItem('access_token', accessToken);
    
        commit('setAccessToken', accessToken);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async fetchUserInfo({ commit }) {
      try {
        const response = await axiosInstance.get('api/core/user-info/');
        localStorage.setItem('user_type', response.data.user.user_type);
        localStorage.setItem('company_slug', response.data.user.company_slug);
        commit('setUserType', response.data.user.user_type);
        commit('setCompanySlug', response.data.user.company_slug);
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    },
    logout({ commit }) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('user_type');
      localStorage.removeItem('company_slug');
      commit('clearTokens');
      router.push({ name: 'login' });
    },
  },
});

export default store;
