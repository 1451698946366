
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonCheckbox
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref, Ref, toRef } from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';

export default defineComponent({
  name: "dataForm",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonCheckbox
  },
  props: {
    companyId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup(props) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const dataLoaded = ref(false);
    const companyId = toRef(props, 'companyId');
    const data = ref({});

    const submitForm = async () => {
      try {
        data.value = await updateData(companyId, data.value);
        dataLoaded.value = true;
      } catch (error) {
        console.error('Error updating production data:', error);
      }
    };

    onMounted(async () => {
      data.value = await getData(companyId);
      dataLoaded.value = true;
    });

    const getData = async (companyId: Ref<number>) => {
      try {
        const response = await axiosInstance.get(`api/core/dairy-cattle-data/${companyId.value}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const updateData = async (companyId: Ref<number>, data: any) => {
      try {
        const response = await axiosInstance.put(`api/core/dairy-cattle-data/${companyId.value}/update/`, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };


    return {
      translations,
      currentLanguage,
      data,
      submitForm,
    };
  },
});
