
import { IonPage, IonContent, IonInput, IonButton, IonText } from '@ionic/vue';
import { defineComponent, ref, computed } from "vue";
import { useStore } from 'vuex';
import frTranslations from '@/locales/fr.json';


export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonInput,
    IonButton,
    IonText
  },
  setup() {
    const currentLanguage = ref('fr');
    const username = ref("");
    const password = ref("");
    const errorString = ref("");
    const store = useStore();

    const login = async () => {
      try {
        await store.dispatch("login", { username: username.value, password: password.value });
      } catch (error) {
        console.error("Login failed:", error);
        if (error) {
          errorString.value = frTranslations.WrongCredentials;
        }
      }
    };

    const translations = {
      fr: frTranslations,
    };

    const logoUrl = computed(() => require('@/assets/logo-ceta-35.png'));

    return {
      username,
      password,
      errorString,
      login,
      logoUrl,
      translations,
      currentLanguage,
    };
  },
});
