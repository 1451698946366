import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4032964c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_auto_fill_select = _resolveComponent("auto-fill-select")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("form", {
          onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
          onChange: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
        }, [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('BreedingType')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_auto_fill_select, {
                        options: _ctx.breedingTypes,
                        searchField: "name",
                        selected: _ctx.data.breeding_type_name,
                        label: _ctx.translations[_ctx.currentLanguage].BreedingType,
                        placeholder: _ctx.translations[_ctx.currentLanguage].Search,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = option => _ctx.handleSelected(option, _ctx.data, 'breeding_type', true))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" >")
                        ]),
                        _: 1
                      }, 8, ["options", "selected", "label", "placeholder"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { class: "ion-margin-vertical ion-align-items-center" }, {
                default: _withCtx(() => [
                  (_ctx.selectedBreedingType.name == 'Naisseur' || _ctx.selectedBreedingType.name == 'Naisseur engraisseur')
                    ? (_openBlock(), _createBlock(_component_ion_col, {
                        key: 0,
                        size: "12",
                        "size-md": "auto"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            fill: "solid",
                            modelValue: _ctx.data.sow_number,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.sow_number) = $event)),
                            label: _ctx.translations[_ctx.currentLanguage].SowNumber,
                            "label-placement": "floating",
                            class: "input",
                            onIonBlur: _ctx.submitForm
                          }, null, 8, ["modelValue", "label", "onIonBlur"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.selectedBreedingType.name == 'Naisseur' || _ctx.selectedBreedingType.name == 'Naisseur engraisseur')
                    ? (_openBlock(), _createBlock(_component_ion_col, {
                        key: 1,
                        size: "12",
                        "size-md": "auto"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            fill: "solid",
                            modelValue: _ctx.data.percent_fattening,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.percent_fattening) = $event)),
                            label: _ctx.translations[_ctx.currentLanguage].PercentFattening,
                            "label-placement": "floating",
                            class: "input",
                            onIonBlur: _ctx.submitForm
                          }, null, 8, ["modelValue", "label", "onIonBlur"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.selectedBreedingType.name == 'Post' || _ctx.selectedBreedingType.name == 'Post sevreur' || _ctx.selectedBreedingType.name == 'Post sevreur engraisseur')
                    ? (_openBlock(), _createBlock(_component_ion_col, {
                        key: 2,
                        size: "12",
                        "size-md": "auto"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_input, {
                            fill: "solid",
                            modelValue: _ctx.data.place_number,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.place_number) = $event)),
                            label: _ctx.translations[_ctx.currentLanguage].PlaceNumber,
                            "label-placement": "floating",
                            class: "input",
                            onIonBlur: _ctx.submitForm
                          }, null, 8, ["modelValue", "label", "onIonBlur"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { class: "ion-margin-vertical ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('ValuationLabel')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_auto_fill_select, {
                        options: _ctx.valuationLabels,
                        searchField: "name",
                        selected: _ctx.data.valuation_label_name,
                        label: _ctx.translations[_ctx.currentLanguage].ValuationLabel,
                        placeholder: _ctx.translations[_ctx.currentLanguage].Search,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = option => _ctx.handleSelected(option, _ctx.data, 'valuation_label', true))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" >")
                        ]),
                        _: 1
                      }, 8, ["options", "selected", "label", "placeholder"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { class: "ion-margin-vertical ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        fill: "solid",
                        modelValue: _ctx.data.coop,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data.coop) = $event)),
                        label: _ctx.translations[_ctx.currentLanguage].Coop,
                        "label-placement": "floating",
                        class: "input",
                        onIonBlur: _ctx.submitForm
                      }, null, 8, ["modelValue", "label", "onIonBlur"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select, {
                        label: _ctx.translations[_ctx.currentLanguage].FAF,
                        placeholder: _ctx.translations[_ctx.currentLanguage].Select,
                        modelValue: _ctx.data.faf,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.data.faf) = $event)),
                        onIonChange: _ctx.submitForm,
                        "cancel-text": _ctx.translations[_ctx.currentLanguage].Cancel,
                        "ok-text": _ctx.translations[_ctx.currentLanguage].Choose
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select_option, { value: "integral" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('Integral')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "partial" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('Partial')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["label", "placeholder", "modelValue", "onIonChange", "cancel-text", "ok-text"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 32)
      ]))
    : _createCommentVNode("", true)
}