
import {  
  IonTitle,
  IonButton
} from '@ionic/vue';
import BaseLayout from '../../layouts/BaseLayout.vue';
import { defineComponent } from "vue";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'NotFound',
  components: {
    BaseLayout,
    IonTitle,
    IonButton
  },
  setup() {
    const router = useRouter();

    const goBack = () => {
      if (window.history.length <= 2) {
        router.push('/');
      } else {
        router.go(-1);
      }
    };
    

    return {
      goBack
    };
  },
});
