import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c064112a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex-container" }
const _hoisted_3 = { class: "flex-container" }
const _hoisted_4 = { class: "flex-container" }
const _hoisted_5 = { class: "flex-container" }
const _hoisted_6 = { class: "flex-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("form", {
          onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
          onChange: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)))
        }, [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-justify-content-between ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        fill: "solid",
                        modelValue: _ctx.data.milk_delivery,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.milk_delivery) = $event)),
                        label: _ctx.translations[_ctx.currentLanguage].MilkDelivered,
                        "label-placement": "floating",
                        class: "input",
                        onIonBlur: _ctx.submitForm
                      }, null, 8, ["modelValue", "label", "onIonBlur"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        fill: "solid",
                        modelValue: _ctx.data.dairy,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.dairy) = $event)),
                        label: _ctx.translations[_ctx.currentLanguage].Dairy,
                        "label-placement": "floating",
                        class: "input",
                        onIonBlur: _ctx.submitForm
                      }, null, 8, ["modelValue", "label", "onIonBlur"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        type: "number",
                        fill: "solid",
                        modelValue: _ctx.data.milk_cow_number,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.milk_cow_number) = $event)),
                        modelModifiers: { number: true },
                        label: _ctx.translations[_ctx.currentLanguage].MilkCowNumber,
                        "label-placement": "floating",
                        class: "input",
                        onIonBlur: _ctx.submitForm
                      }, null, 8, ["modelValue", "label", "onIonBlur"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { class: "ion-margin-vertical" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode(_component_ion_checkbox, {
                          modelValue: _ctx.data.milking_robot,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.milking_robot) = $event)),
                          onIonChange: _ctx.submitForm
                        }, null, 8, ["modelValue", "onIonChange"]),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('MilkingRobot')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_ion_checkbox, {
                          modelValue: _ctx.data.heifer_breeding_delegation,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data.heifer_breeding_delegation) = $event)),
                          onIonChange: _ctx.submitForm
                        }, null, 8, ["modelValue", "onIonChange"]),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('HeiferBreedingDelegation')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_ion_checkbox, {
                          modelValue: _ctx.data.green_feeding,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data.green_feeding) = $event)),
                          onIonChange: _ctx.submitForm
                        }, null, 8, ["modelValue", "onIonChange"]),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('GreenFeeding')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_ion_checkbox, {
                          modelValue: _ctx.data.food_delegation,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.data.food_delegation) = $event)),
                          onIonChange: _ctx.submitForm
                        }, null, 8, ["modelValue", "onIonChange"]),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('FoodDelegation')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "auto"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_ion_checkbox, {
                          modelValue: _ctx.data.food_robot,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.data.food_robot) = $event)),
                          onIonChange: _ctx.submitForm
                        }, null, 8, ["modelValue", "onIonChange"]),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('FoodRobot')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 32)
      ]))
    : _createCommentVNode("", true)
}