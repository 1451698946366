
import {
  IonSpinner,
  IonText
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref } from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';
import { CropLiming } from "@/types/CropLiming";

export default defineComponent({
  name: "CropLimingFertilization",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  components: {
    IonSpinner,
    IonText
  },
  setup(props) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const dataLoaded = ref(false);
    const cropLiming = ref<CropLiming>({});

    onMounted(async () => {
      
     if (props.cropId !== undefined && props.cropId !== null) {
        cropLiming.value = await getCropLiming(props.cropId);
        dataLoaded.value = true;
      }
      
    });

    const getCropLiming = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/liming/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    return {
      cropLiming,
      dataLoaded,
      translations,
      currentLanguage
    };
  },
});
