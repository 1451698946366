
import {  
  IonPage,
  IonTitle,
  IonButton,
  IonRow,
  IonGrid,
  IonCol,
  IonSpinner,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle
} from '@ionic/vue';
import BaseLayout from '../layouts/BaseLayout.vue';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';
import { defineComponent, onMounted, ref } from "vue";
import axiosInstance from "@/axiosInstance";
import DairyCattleDataForm from '@/forms/DairyCattleDataForm.vue';
import DairyGoatSheepDataForm from '@/forms/DairyGoatSheepDataForm.vue';
import PorkDataForm from '@/forms/PorkDataForm.vue';
import PoultryDataForm from '@/forms/PoultryDataForm.vue';
import SalesCultureDataForm from '@/forms/SalesCultureDataForm.vue';
import MethanizationDataForm from '@/forms/MethanizationDataForm.vue';
import PhotovoltaicDataForm from '@/forms/PhotovoltaicDataForm.vue';
import GeneralDataForm from '@/forms/GeneralDataForm.vue';
import WrongDataModal from '@/modals/WrongDataModal.vue';
import { useRoute } from 'vue-router';
import { CompanyData } from '@/types/CompanyData';

export default defineComponent({
  components: {
    BaseLayout,
    IonPage,
    IonTitle,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonSpinner,
    IonIcon,
    GeneralDataForm,
    DairyCattleDataForm,
    DairyGoatSheepDataForm,
    PorkDataForm,
    PoultryDataForm,
    SalesCultureDataForm,
    MethanizationDataForm,
    PhotovoltaicDataForm,
    WrongDataModal
  },
  setup() {
    const dataLoaded = ref(false);
    const company = ref(null);
    const company_data = ref<CompanyData>({});
    const route = useRoute();
    const slug = route.params.slug as string;
    const userInfo = ref([]);

    const getUserInfo = async () => {
      try {
        const response = await axiosInstance.get(`api/core/user-info/`);
        return response.data.user;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getCompany = async () => {
      try {
        const response = await axiosInstance.get(`api/core/companies/${slug}/`); 
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getCompanyData = async () => {
      try {
        const response = await axiosInstance.get(`api/core/company/${slug}/company-data/`); 
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const hasProduction = (production: any) => {
      let hasProduction = false;
      if (company_data.value && company_data.value.productions) {
        company_data.value.productions.forEach(prod => {
          if (prod == production) {
            hasProduction = true;
          }
        });
      }
      return hasProduction;
    };

    onMounted(async () => {
      userInfo.value = await getUserInfo();
      company.value = await getCompany();
      company_data.value = await getCompanyData();
      dataLoaded.value = true;
    });

    return {
      userInfo,
      company,
      company_data,
      dataLoaded,
      checkmarkOutline,
      closeOutline,
      hasProduction,
      slug
    };
  },
});
