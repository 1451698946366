
import { 
  IonTitle,
  IonButton, 
  IonRow, 
  IonGrid, 
  IonCol, 
  IonSpinner, 
  IonSelect, 
  IonSelectOption,
  IonCheckbox,
  IonInput,
  IonAlert,
  IonText
} from '@ionic/vue';
import { 
  checkmarkOutline, 
  closeOutline, 
  trashOutline, 
  addCircleOutline, 
  createOutline,
  eyeOutline,
  chevronBackOutline
} from 'ionicons/icons';
import BaseLayout from '../../layouts/BaseLayout.vue';
import { defineComponent, onMounted, ref, Ref, computed } from "vue";
import axiosInstance from '@/axiosInstance';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Campaign } from '@/types/Campaign';
import { Crop } from "@/types/Crop";
import { CropArea } from "@/types/CropArea";
import frTranslations from '@/locales/fr.json';
import AutoFillSelect from '@/components/AutoFillSelect.vue';

export default defineComponent({
  components: {
    BaseLayout,
    IonTitle,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonSpinner,
    IonSelect,
    IonSelectOption,
    IonCheckbox,
    IonInput,
    IonAlert,
    IonText,
    AutoFillSelect
  },
  setup() {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const freshCrop = (): Crop => ({
      id: 0,
      campaign: 0,
      label: '',
      area: {},
      cropType: '',
      crop_type: 0,
      mixed: false,
      progress: 0,
      progressStatus: 'Non démarrée',
      precision: '',
      harvest: 0,
      crop_name: ''
    });
    const dataLoaded = ref(false);
    const showDeleteCropConfirmation = ref(false);
    const userId = ref<number>(0);
    const campaign = ref<Campaign>({});
    const newCrop = ref<Crop>(freshCrop());
    const harvests = ref([]);
    const cropTypes = ref([]);
    const route = useRoute();
    const year = ref(route.params.year);
    const slug = ref(route.params.slug);
    const selectedCropId = ref<number>(0);
    const errorMessage = ref<string | null>(null);
    const selectCrop = ref<InstanceType<typeof AutoFillSelect> | null>(null);
    const store = useStore();
    const userType = computed(() => store.state.userType);

    const canValidate = (crop: any) => {
      return (
        userType.value == 'employee' && 
        crop.submitted == true &&
        crop.validated != true
      );
    };

    const canUnvalidate = (crop: any) => {
      return (
        userType.value == 'employee' && 
        crop.validated == true
      );
    };

    const canUnsubmit = (crop: any) => {
      return (
        crop.submitted == true &&
        crop.validated != true
      );
    };

    const canSee = (crop: any) => {
      return (
        (
          userType.value == 'member' &&
          crop.submitted === true
        ) ||
        (
          userType.value == 'employee' &&
          crop.validated === true
        )
      );
    };

    const canUpdate = (crop: any) => {
      return (
        crop.submitted == false ||
        (
          userType.value == 'employee' &&
          crop.validated != true
        )
      );
    };

    const canAdd = () => {
      return (
        newCrop.value &&
        (
          (
            userType.value == 'member' &&
            campaign.value.submitted !== true
          ) ||
          (
            userType.value == 'employee' &&
            campaign.value.validated !== true
          )
        )
      );
    };

    const canDelete = (crop: any) => {
      return (
        crop.submitted !== true ||
        (
          userType.value == 'employee' &&
          crop.validated !== true
        )
      );
    };

    const validate = async (crop: any) => {
      try {
        crop.validated = true;
        crop.progressStatus = "Validée";
        await updateCrop(crop.id, crop);
      } catch (error) {
        console.error('Error updating crop data:', error);
      }
    };

    const unvalidate = async (crop: any) => {
      try {
        crop.validated = false;
        crop.progressStatus = "Soumise";
        await updateCrop(crop.id, crop);
      } catch (error) {
        console.error('Error updating crop data:', error);
      }
    };

    const unsubmit = async (crop: any) => {
      try {
        crop.submitted = false;
        crop.progressStatus = "En cours";
        crop.progress = crop.progress - 10;
        await updateCrop(crop.id, crop);
      } catch (error) {
        console.error('Error updating crop data:', error);
      }
    };

    const getUserInfo = async () => {
      try {
        const response = await axiosInstance.get(`api/core/user-info/`);
        return response.data.user;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getCampaignData = async (userId: Ref<number>, year: Ref<string | string[]>) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/${slug.value}/campaign/${year.value}/`); 
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const getHarvests = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/harvests/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const handleSelected = (option: any, item: any, field: string, update = false) => {
      item[field] = option ? option.id_crop : null;
      if (update) {
        updateCropData([], item);
      }
    }

    const getCropTypes = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop-status-types/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const duplicateCrop = (data: Crop) => {
      newCrop.value.campaign = data.campaign;
      newCrop.value.harvest = data.harvest;
      newCrop.value.cropType = data.cropType;
      newCrop.value.crop_type = data.crop_type;
      newCrop.value.mixed = data.mixed;
      newCrop.value.precision = data.precision;
    };

    const updateProductionPrice = (e: any, crop: Crop) => {
      let costs = 0;
      let costs_pro_t = 0;
      if (
        crop.area && 
        crop.area.crop_yield_target &&
        crop.sale_price &&
        crop.area.yield_unit
      ) {
        const yield_value = (crop.area.yield_unit == 'T') ? crop.area.crop_yield_target : crop.area.crop_yield_target / 10;
      
        if (crop.operational_cost) {
          costs += crop.operational_cost;
        }
        if (crop.meca && crop.meca.cost_pro_ha) {
          costs += crop.meca.cost_pro_ha;
        }
        if (crop.meca && crop.meca.structure_cost && crop.area && crop.area.area) {
          costs += Math.round((crop.meca.structure_cost / crop.area.area) * 100) / 100;
        }
        if (yield_value) {
          costs_pro_t = Math.round((costs / yield_value) * 100) / 100;
        }
      }
      if (crop.sale_price) {
        crop.production_cost = Math.round((crop.sale_price - costs_pro_t) * 100) / 100;
      }
      updateCropData(e, crop);
      if (crop.area && crop.area.id && crop.id) {
        updateCropArea(crop.id, crop.area);
      }
    };

    const updateCrop = async (cropId: number, data: Crop) => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/crop/${cropId}/update-summary/`, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const updateCropArea = async (cropId: number, data: CropArea) => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/crop/${cropId}/area/update/`, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const setOpen = (isOpen: boolean, cropRefId: number) => {
      showDeleteCropConfirmation.value = isOpen;
      selectedCropId.value = cropRefId;
    }

    const alertButtons = [
      {
        text: 'Annuler',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          showDeleteCropConfirmation.value = false;
          selectedCropId.value = 0;
        }
      },
      {
        text: 'Oui, supprimer',
        handler: () => {
          deleteCrop();
        }
      }
    ];

    const validateCrop = (crop: Crop): boolean => {
      if (!crop.harvest) {
        errorMessage.value = "Veuillez sélectionner une culture.";
        return false;
      }
      if (!crop.crop_type) {
        errorMessage.value = "Veuillez sélectionner un type.";
        return false;
      }
      errorMessage.value = null;
      return true;
    };

    const addCrop = async () => {
      try {
        if (!validateCrop(newCrop.value)){
          return;
        }
        let yield_target = 0;
        if (newCrop.value.area && newCrop.value.area.crop_yield_target) {
          yield_target = newCrop.value.area.crop_yield_target;
        }
        const response = await axiosInstance.post(`api/ceta-marge/crop/add/`, newCrop.value);
        if (!campaign.value.crops) {
          campaign.value.crops = [];
        }
        const crop = response.data;
        campaign.value.crops.push(crop);
        if (crop.area) {
          crop.area.crop_yield_target = yield_target;
          updateCropArea(crop.id, crop.area);
        }
        // Reset newCrop to its default values
        newCrop.value = freshCrop();
        newCrop.value.campaign = campaign.value.id;
        newCrop.value.bio = campaign.value.bio;
        selectCrop.value?.resetComponent();
      } catch (error) {
        console.error('Error adding new crop:', error);
      }
    }

    const deleteCrop = async () => {
      if (selectedCropId.value) {
        try {
          const response = await axiosInstance.delete(`api/ceta-marge/crop/${selectedCropId.value}/delete/`);
          if (campaign.value.crops) {
            campaign.value.crops = campaign.value.crops.filter((crop: Crop) => crop.id !== selectedCropId.value);
          }
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    };

    const isSelected = (refId: number, cropRefId: number) => {
      return refId == cropRefId;
    }

    const updateCropData = (e: any, currentCrop: Crop) => {
      if (!validateCrop(currentCrop)){
        return;
      }
      if (currentCrop.id) {
        updateCrop(currentCrop.id, currentCrop);
      }
    }

    onMounted(async () => {
      const userInfo = await getUserInfo();
      harvests.value = await getHarvests();
      cropTypes.value = await getCropTypes();
      if (userInfo) {
        userId.value = userInfo.id;
        campaign.value = await getCampaignData(userId, year);
        newCrop.value.campaign = campaign.value.id;
        newCrop.value.bio = campaign.value.bio;
        dataLoaded.value = true;
      }
    });

    return {
      translations,
      currentLanguage,
      campaign,
      year,
      slug,
      harvests,
      cropTypes,
      dataLoaded,
      checkmarkOutline,
      closeOutline,
      createOutline,
      eyeOutline,
      addCircleOutline,
      trashOutline,
      chevronBackOutline,
      isSelected,
      updateCropData,
      setOpen,
      showDeleteCropConfirmation,
      alertButtons,
      newCrop,
      addCrop,
      duplicateCrop,
      errorMessage,
      handleSelected,
      selectCrop,
      canValidate,
      canUpdate,
      canAdd,
      canDelete,
      canSee,
      validate,
      canUnsubmit,
      unsubmit,
      canUnvalidate,
      unvalidate,
      updateProductionPrice
    };
  },
});
