
import { 
  IonSearchbar,
  IonList,
  IonItem,
  IonButton,
  IonModal,
  IonContent,
  IonText,
  IonIcon
} from '@ionic/vue';
import { 
  chevronDownOutline,
  closeOutline
} from 'ionicons/icons';
import { defineComponent, ref, watch, SetupContext } from 'vue';

interface Option {
  value: string;
  [key: string]: string;
}

export default defineComponent({
  components: {
    IonSearchbar,
    IonList,
    IonItem ,
    IonButton,
    IonModal,
    IonContent,
    IonText,
    IonIcon
  },
  props: {
    options: {
      type: Array as () => Option[],
      required: true
    },
    selected: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search'
    },
    label: {
      type: String,
      default: ''
    },
    searchField: {
      type: String,
      defaut: 'name',
      required: true
    },
    clear: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  setup(props,  context: SetupContext) {
    const internalSearchText = ref('');
    const filteredList = ref<Option[]>([]);
    const showModal = ref(false);
    const value = ref('');

    watch(
      () => props.selected,
      () => { value.value = props.selected },
      { immediate: true }
    );

    const openModal = () => {
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };
    
    const filterItems = () => {
      if (internalSearchText.value === '') {
        filteredList.value = [];
      } else {
        filteredList.value = props.options.filter(item => 
          item[props.searchField].toLowerCase().includes(internalSearchText.value.toLowerCase())
        );
      }
    };

    const selectItem = (item: Option) => {
      context.emit('update:modelValue', item);
      value.value = item[props.searchField]
      internalSearchText.value = '';
      filteredList.value = [];
      closeModal();
    };

    const getValueByField = (item: Option): string => {
      return item[props.searchField];
    };

    const resetComponent = () => {
      internalSearchText.value = '';
      filteredList.value = [];
      value.value = '';
    }

    const clearValue = () => {
      resetComponent();
      context.emit('update:modelValue', null);
      closeModal();
    }

    return { 
      internalSearchText,
      filteredList,
      chevronDownOutline,
      closeOutline,
      filterItems,
      selectItem,
      getValueByField,
      showModal,
      openModal,
      closeModal,
      value,
      resetComponent,
      clearValue
    };
  }
});
