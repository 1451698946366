import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d54a0762"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "field-container" }
const _hoisted_4 = { class: "field-container" }
const _hoisted_5 = { class: "field-container" }
const _hoisted_6 = { class: "field-container" }
const _hoisted_7 = { class: "field-container" }
const _hoisted_8 = { class: "field-container" }
const _hoisted_9 = { class: "field-container" }
const _hoisted_10 = { class: "field-container" }
const _hoisted_11 = { class: "field-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dataLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_spinner),
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('LoadingData')), 1)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-justify-content-left ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "6"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_ion_text, { class: "field-label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('SewingDensity')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cropSeed.sewing_density_kg), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, { class: "unit" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('UnitKgProHa')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "6"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_ion_text, { class: "field-label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('SewingDensity')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cropSeed.sewing_density_meters), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, { class: "unit" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('UnitGrainProQuadratMeter')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "6"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_ion_text, { class: "field-label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('SewingType')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cropSeed.sewing_type_name), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "6"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_ion_text, { class: "field-label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('SewingDate')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cropSeed.sewing_date_name), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('FarmSeeds')), 1),
              _createVNode(_component_ion_row, { class: "ion-justify-content-left ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "4"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_ion_text, { class: "field-label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Area')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cropSeed.area_farm_seed), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, { class: "unit" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('UnitHa')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "4"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_ion_text, { class: "field-label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Cost')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cropSeed.total_cost_farm_seed), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, { class: "unit" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('CertifiedSeeds')), 1),
              _createVNode(_component_ion_row, { class: "ion-justify-content-left ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "4"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_ion_text, { class: "field-label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Area')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cropSeed.area_certified_seed), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, { class: "unit" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('UnitHa')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "4"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_ion_text, { class: "field-label" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Cost')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cropSeed.cost_certified_seed), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, { class: "unit" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('TotalCost')), 1),
              _createVNode(_component_ion_row, { class: "ion-justify-content-left ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "4"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_ion_text, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.cropSeed.seed_total_cost), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_text, { class: "unit" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('UnitEuro')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.cropSeed.mixed_crops.length > 0)
                ? (_openBlock(), _createBlock(_component_ion_row, {
                    key: 0,
                    class: "ion-justify-content-left ion-align-items-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, { size: "auto" }, {
                        default: _withCtx(() => [
                          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('MixedCrop')), 1),
                          _createElementVNode("table", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cropSeed.mixed_crops, (mixed_crop, index) => {
                              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                _createElementVNode("td", null, _toDisplayString(mixed_crop.name), 1),
                                _createElementVNode("td", null, _toDisplayString(mixed_crop.ratio), 1),
                                _createElementVNode("td", null, [
                                  _createVNode(_component_ion_text, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('PercentSewed')), 1)
                                    ]),
                                    _: 1
                                  })
                                ])
                              ]))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
  ]))
}