
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent, computed } from "vue";
import store from "@/store";
import AccessRestricted from "@/components/errors/AccessRestricted.vue";
import NotFound from "@/components/errors/NotFound.vue";
import ErrorPage from "@/components/errors/ErrorPage.vue";

export default defineComponent({
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup() {
    // Computed property to determine the error component to render
    const errorComponent = computed(() => {
      if (store.state.currentError) {
        switch (store.state.currentError) {
          case 403:
            return AccessRestricted;
          case 404:
            return NotFound;
          case 500:
            return ErrorPage;
          default:
            return null;
        }
      }
      return null;
    });

    // Computed property to get the current error state from the store
    const currentError = computed(() => store.state.currentError);

    return { errorComponent, currentError };
  },
});
