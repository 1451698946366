
import { 
  IonSpinner,
  IonText,
  IonToolbar
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref} from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';
import { CropTreatment } from "@/types/CropTreatment";
import { TreatmentItem } from "@/types/TreatmentItem";
import { TreatmentCategory } from "@/types/TreatmentCategory";

export default defineComponent({
  name: "CropTreatment",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    }
  },
  components: {
    IonSpinner,
    IonText,
    IonToolbar
  },
  setup(props) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const dataLoaded = ref(false);
    const categories = ref<TreatmentCategory[]>([]);
    const cropTreatment = ref<CropTreatment>({});

    const getCategories = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/treatment-categories/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getCropTreatment = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/treatment/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    onMounted(async () => {
      if (props.cropId !== undefined && props.cropId !== null) {
        categories.value = await getCategories();
        cropTreatment.value = await getCropTreatment(props.cropId);
        dataLoaded.value = true;
      }
    });

    const getTreatments = (categoryId: number) => {
      if (cropTreatment.value.treatment_items) {
        return cropTreatment.value.treatment_items.filter((item: TreatmentItem) => {return item.category === categoryId});
      }
      return [];
    };

    const getPassTreatments = (passId: number) => {
      if (cropTreatment.value.treatment_items) {
        return cropTreatment.value.treatment_items.filter((item: TreatmentItem) => {return item.treatment_pass === passId});
      }
      return [];
    };

    return {
      dataLoaded,
      translations,
      currentLanguage,
      categories,
      cropTreatment,
      getTreatments,
      getPassTreatments
    };
  },
});
