
import { 
  IonButton,
  IonInput,
  IonText,
  IonSpinner
} from '@ionic/vue';
import { 
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRef,
  SetupContext,
  watch
} from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';
import { 
  trashOutline, 
  addCircleOutline,
  arrowForwardOutline
} from 'ionicons/icons';
import { CropMineralFertilization } from "@/types/CropMineralFertilization";
import { MineralFertiItem } from "@/types/MineralFertiItem";
import { Fertilizer } from "@/types/Fertilizer";
import ImportDataModal from "@/modals/ImportDataModal.vue";
import AutoFillSelect from '@/components/AutoFillSelect.vue';

type DebounceFunction = (...args: any[]) => void;

export default defineComponent({
  name: "CropMineralFertilizationForm",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    },
    area: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  components: {
    ImportDataModal,
    IonButton,
    IonText,
    IonInput,
    IonSpinner,
    AutoFillSelect
  },
  setup(props, context: SetupContext) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const errorMessage = ref<string | null>(null);
    const productTypes = ref<Fertilizer[]>([]);
    const plantStates = ref([]);
    const area = toRef(props, 'area');
    const cropMineralFertilization = ref<CropMineralFertilization>({});
    const dataLoaded = ref(false);
    const selectProduct = ref<InstanceType<typeof AutoFillSelect> | null>(null)
    const selectPlantState = ref<InstanceType<typeof AutoFillSelect> | null>(null)
    const freshMineralFertiItem = (): MineralFertiItem => ({
      id: 0,
      crop_mineral_ferti: cropMineralFertilization.value.id,
      product: 0,
      plant_state: 0,
      area_spread_percent: 100,
      total_quantity: 0,
      quantity_pro_ha: 0,
      quantity_pro_spread_ha: 0,
      cost: 0,
      units_n: 0,
      units_p: 0,
      units_k: 0,
      product_name: '',
      plant_state_name: ''
    });
    const newCropMineralFertilizationItem = ref<MineralFertiItem>(freshMineralFertiItem());

    function debounce(fn: DebounceFunction, delay: number) {
      let timeoutId: number | null = null;
      return (...args: any[]) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = window.setTimeout(() => fn(...args), delay);
      };
    }
    
    const getProductTypes = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/mineral-fertilizer-types/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getPlantStates = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/plant-states/${props.cropId}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getCropMineralFertilization = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/mineral-fertilization/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    onMounted(async () => {
      if (props.cropId !== undefined && props.cropId !== null) {
        productTypes.value = await getProductTypes();
        plantStates.value = await getPlantStates();
        cropMineralFertilization.value = await getCropMineralFertilization(props.cropId);
        newCropMineralFertilizationItem.value.crop_mineral_ferti = cropMineralFertilization.value.id;
        dataLoaded.value = true;
      }
    });

    const handleSelected = (option: any, item: any, field: string, update = false) => {
      item[field] = option ? option.id : null;
      if (update) {
        updateMineralFertiItemData([], item);
      }
    }

    const updateItems = (area: number) => {
      if (cropMineralFertilization.value.mineral_fertilization_items) {
        cropMineralFertilization.value.mineral_fertilization_items.forEach((item: MineralFertiItem) => {
          estimateTotalQuantity(item);
        });
      }
    }

    watch(area, updateItems);

    const validateMineralFertiItem = (item: MineralFertiItem): boolean => {
      if (!item.product) {
        errorMessage.value = "Veuillez sélectionner un produit.";
        return false;
      }
      if (!item.plant_state) {
        errorMessage.value = "Veuillez sélectionner un stade de plante.";
        return false;
      }
      errorMessage.value = null;
      return true;
    };

    const addMineralFertiItem = async () => {
      try {
        if (!validateMineralFertiItem(newCropMineralFertilizationItem.value)){
          return false;
        }
        const response = await axiosInstance.post(`api/ceta-marge/mineral-fertilization-item/add/`, newCropMineralFertilizationItem.value);
        if (!cropMineralFertilization.value.mineral_fertilization_items) {
          cropMineralFertilization.value.mineral_fertilization_items = [];
        }
        cropMineralFertilization.value.mineral_fertilization_items.push(response.data);
        updateTotalFertilizerQuantity();
        // Reset newCropMineralFertilizationItem to its default values
        newCropMineralFertilizationItem.value = freshMineralFertiItem();
        selectProduct.value?.resetComponent();
        selectPlantState.value?.resetComponent();
        return true;
      } catch (error) {
        console.error('Error adding new fertilisation item:', error);
        return false;
      }
    };

    const updateMineralFertiItemData = (e: any, currentItem: MineralFertiItem) => {
      if (!validateMineralFertiItem(currentItem)){
        return;
      }
      updateFertilizerQuantity(currentItem);
      updateMineralFertiItem(currentItem);
    };

    const estimateTotalQuantity = (currentItem: MineralFertiItem) => {
      if (
        currentItem.area_spread_percent && 
        area.value && 
        currentItem.quantity_pro_spread_ha
      ) {
        const raw_quantity = currentItem.quantity_pro_spread_ha * ((currentItem.area_spread_percent / 100) * area.value);
        currentItem.total_quantity = parseFloat(raw_quantity.toFixed(2));
        const raw_quantity_pro_ha = raw_quantity / area.value;
        currentItem.quantity_pro_ha = parseFloat(raw_quantity_pro_ha.toFixed(2));
        updateItem(currentItem);
      }
    };

    const updateItem = (item: MineralFertiItem) => {
      updateFertilizerQuantity(item);
      if (item.id) {
        updateMineralFertiItem(item);
      }
    };

    const updateFertilizerQuantity = (currentItem: MineralFertiItem) => {
      if (
        currentItem.quantity_pro_ha &&
        currentItem.product
      ) {
        const product: Fertilizer | undefined = productTypes.value.find((item: Fertilizer) => item.id === currentItem.product);
        if (product && typeof product.formulation_n !== "undefined") {
          const raw_n = product.formulation_n * currentItem.quantity_pro_ha / 100;
          currentItem.units_n = parseFloat(raw_n.toFixed(2));
        }
        if (product && typeof product.formulation_p !== "undefined") {
          const raw_p = product.formulation_p * currentItem.quantity_pro_ha / 100;
          currentItem.units_p = parseFloat(raw_p.toFixed(2));
        }
        if (product && typeof product.formulation_k !== "undefined") {
          const raw_k = product.formulation_k * currentItem.quantity_pro_ha / 100;
          currentItem.units_k = parseFloat(raw_k.toFixed(2));
        }
        updateTotalFertilizerQuantity();
      }
    };

    const updateTotalFertilizerQuantity = () => {
      const units_n = ref<number>(0);
      const units_p = ref<number>(0);
      const units_k = ref<number>(0);
      const cost = ref<number>(0);
      if (cropMineralFertilization.value.mineral_fertilization_items) {
        cropMineralFertilization.value.mineral_fertilization_items.forEach((item: MineralFertiItem) => {
          if (item.units_n) {
            units_n.value += item.units_n;
          }
          if (item.units_p) {
            units_p.value += item.units_p;
          }
          if (item.units_k) {
            units_k.value += item.units_k;
          }
          if (item.cost && item.total_quantity && area.value && area.value > 0) {
            cost.value += item.cost / 1000 * item.total_quantity / area.value;
          }
        });
        cropMineralFertilization.value.units_n = units_n.value;
        cropMineralFertilization.value.units_p = units_p.value;
        cropMineralFertilization.value.units_k = units_k.value;
        cropMineralFertilization.value.cost = Math.round(cost.value * 100) / 100;
      }
      updateCropMineralFerti();
    };

    const updateCropMineralFerti = async () => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/crop/${props.cropId}/mineral-fertilization/update/`, cropMineralFertilization.value);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const updateMineralFertiItem = async (data: MineralFertiItem) => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/mineral-fertilization-item/${data.id}/update/`, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const deleteMineralFertiItem = async (itemId: number) => {
      if (itemId) {
        try {
          await axiosInstance.delete(`api/ceta-marge/mineral-fertilization-item/${itemId}/delete/`);
          if (cropMineralFertilization.value.mineral_fertilization_items) {
            cropMineralFertilization.value.mineral_fertilization_items = cropMineralFertilization.value.mineral_fertilization_items.filter((item: MineralFertiItem) => item.id !== itemId);
          }
          updateTotalFertilizerQuantity();
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    };

    const handleImportData = async (id: number) => {
      if (id) {
        cropMineralFertilization.value = await duplicateCropMineralFertilization(props.cropId, id);
      }
    };

    const duplicateCropMineralFertilization = async (cropId: number, duplicateId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/mineral-fertilization/duplicate/${duplicateId}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const handleDebouncedupdateMineralFertiItemData = debounce(updateMineralFertiItemData, 300);

    const nextStep = async (e: any) => {
      const valid = ref(false);
      if (newCropMineralFertilizationItem.value?.product) {
        valid.value = await addMineralFertiItem();
      }
      else {
        valid.value = true;
      }
      if (valid.value === true) {
        updateCropMineralFerti();
        emit('next');
      }
    };

    const previousStep = (e: any) => {
      emit('previous');
    };

    const emit = (eventName: string, ...args: any[]) => {
      context.emit(eventName, ...args);
    };

    return {
      translations,
      currentLanguage,
      dataLoaded,
      trashOutline, 
      addCircleOutline,
      arrowForwardOutline,
      productTypes,
      plantStates,
      cropMineralFertilization,
      newCropMineralFertilizationItem,
      addMineralFertiItem,
      deleteMineralFertiItem,
      errorMessage,
      nextStep,
      previousStep,
      handleDebouncedupdateMineralFertiItemData,
      estimateTotalQuantity,
      updateItem,
      handleImportData,
      handleSelected,
      selectProduct,
      selectPlantState
    };
  },
});

