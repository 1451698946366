
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref, Ref, toRef } from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';
import AutoFillSelect from '@/components/AutoFillSelect.vue';
import { PoultryProductionData } from '@/types/PoultryProductionData';

export default defineComponent({
  name: "PoultryDataForm",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    AutoFillSelect
  },
  props: {
    companyId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup(props) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const dataLoaded = ref(false);
    const companyId = toRef(props, 'companyId');
    const data = ref<PoultryProductionData>({});
    const breedingTypes = ref([]);
    const selectedBreedingType = ref<any>({});

    const submitForm = async () => {
      try {
        data.value = await updateData(companyId, data.value);
        dataLoaded.value = true;
      } catch (error) {
        console.error('Error updating production data:', error);
      }
    };

    const getBreedingTypes = async () => {
      try {
        const response = await axiosInstance.get(`api/core/poultry-breeding-types/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    onMounted(async () => {
      data.value = await getData(companyId);
      breedingTypes.value = await getBreedingTypes();
      if (data.value && data.value.breeding_type){
        selectedBreedingType.value = breedingTypes.value.find((item: any) => item.id == data.value.breeding_type);
      }
      dataLoaded.value = true;
    });

    const getData = async (companyId: Ref<number>) => {
      try {
        const response = await axiosInstance.get(`api/core/poultry-data/${companyId.value}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const updateData = async (companyId: Ref<number>, data: any) => {
      try {
        const response = await axiosInstance.put(`api/core/poultry-data/${companyId.value}/update/`, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const handleSelected = (option: any, item: any, field: string, update = false) => {
      item[field] = option ? option.id : null;
      if (field == 'breeding_type') {
        selectedBreedingType.value = option;
      }
      if (update) {
        updateData(companyId, item);
      }
    }

    return {
      translations,
      currentLanguage,
      data,
      breedingTypes,
      selectedBreedingType,
      submitForm,
      handleSelected,
    };
  },
});
