
import { 
  IonInput,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonSpinner,
  IonText,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonAlert
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref, Ref, toRef, SetupContext, watch, reactive } from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';
import { 
  trashOutline, 
  addCircleOutline,
  arrowForwardOutline
} from 'ionicons/icons';
import { CropTreatment } from "@/types/CropTreatment";
import { TreatmentItem } from "@/types/TreatmentItem";
import { TreatmentApplication } from "@/types/TreatmentApplication";
import { TreatmentCategory } from "@/types/TreatmentCategory";
import { TreatmentPass } from "@/types/TreatmentPass";
import ImportDataModal from "@/modals/ImportDataModal.vue";
import AutoFillSelect from '@/components/AutoFillSelect.vue';

type DebounceFunction = (...args: any[]) => void;

export default defineComponent({
  name: "CropTreatmentForm",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    },
    area: {
      type: Number as PropType<number>,
      required: true,
    },
    seed_treatment: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  components: {
    ImportDataModal,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonSpinner,
    IonText,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonAlert,
    AutoFillSelect
  },
  setup(props, context: SetupContext) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const dataLoaded = ref(false);
    const errorMessage = ref('');
    const errorContainers: { [key: number]: HTMLElement | null } = reactive({});
    const selectedCategory = ref(0);
    const selectedPass = ref(0);
    const area = toRef(props, 'area');
    const lastPassNumber = ref(0);
    const seed_treatment = toRef(props, 'seed_treatment');
    const products = ref<TreatmentApplication[]>([]);
    const productTypes = ref<TreatmentCategory[]>([]);
    const plantStates = ref([]);
    const categories = ref<TreatmentCategory[]>([]);
    const cropTreatment = ref<CropTreatment>({});
    const freshTreatmentPass = (): TreatmentPass => ({
      id: 0,
      crop_treatment: cropTreatment.value.id,
      pass_number: lastPassNumber.value
    });
    const newTreatmentPass = ref<TreatmentPass>({});
    const freshTreatmentItem = (categoryId: number | null, passId: number | null): TreatmentItem => ({
      id: 0,
      crop_treatment: cropTreatment.value.id,
      category: categoryId,
      treatment_pass: passId,
      product: 0,
      product_type_name: '',
      area_treated: 100,
      total_quantity: 0,
      dose_pro_ha: 0,
      dose_pro_spread_ha: 0,
      cost: 0,
      cost_pro_ha: 0,
      ift: 0,
      unit: '',
      product_name: '',
      glyphosate: false
    });
    const newTreatmentItems = ref<TreatmentItem[]>([]);
    const showDeletePassConfirmation = ref(false);
    const selectedPassId = ref<number>(0);
    const alertButtons = [
      {
        text: frTranslations.Cancel,
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          showDeletePassConfirmation.value = false;
          selectedPassId.value = 0;
        }
      },
      {
        text: frTranslations.YesDelete,
        handler: () => {
          deleteTreatmentPass();
        }
      }
    ];
    const productRefs = ref<Record<number | string, typeof AutoFillSelect>>({});

    function debounce(fn: DebounceFunction, delay: number) {
      let timeoutId: number | null = null;
      return (...args: any[]) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = window.setTimeout(() => fn(...args), delay);
      };
    }
    
    const getProducts = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/treatment-products/${props.cropId}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getCategories = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/treatment-categories/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getCropTreatment = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/treatment/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const scrollToError = () => {
      const errorContainer = errorContainers[selectedCategory.value];
      if (errorContainer) {
        errorContainer.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest"  });
      } else {
        console.error('Error container not found for category', selectedCategory.value);
      }
    };

    onMounted(async () => {
      if (props.cropId !== undefined && props.cropId !== null) {
        products.value = await getProducts();
        categories.value = await getCategories();
        plantStates.value = await getPlantStates();
        cropTreatment.value = await getCropTreatment(props.cropId);
        initiateNewTreatments();
        dataLoaded.value = true;
      }
    });

    const initiateNewTreatments = () => {
      categories.value.forEach((category: TreatmentCategory) => {
        if (category.id) {
          newTreatmentItems.value.push(freshTreatmentItem(category.id, null));
        }
      });
      if (cropTreatment.value.treatment_passes) {
        cropTreatment.value.treatment_passes.forEach((pass: TreatmentPass) => {
          if (pass.id) {
            newTreatmentItems.value.push(freshTreatmentItem(null, pass.id));
          }
          if (pass.pass_number) {
            lastPassNumber.value =  pass.pass_number;
          }
        });
      }
      lastPassNumber.value += 1;
      newTreatmentPass.value = freshTreatmentPass();
    }

    const handleSelected = (option: any, item: any, field: string, update = false) => {
      item[field] = option ? option.id : null;
      if (update) {
        updateTreatmentItemData([], item);
      }
      else {
        updateProductData(item);
      }
    }

    const updateItems = (area: number) => {
      if (cropTreatment.value.treatment_items) {
        cropTreatment.value.treatment_items.forEach((item: TreatmentItem) => {
          calculateDoseProHa(item);
        });
      }
    }

    watch(area, updateItems);

    const getTreatments = (categoryId: number) => {
      if (cropTreatment.value.treatment_items) {
        return cropTreatment.value.treatment_items.filter((item: TreatmentItem) => {return item.category === categoryId});
      }
      return [];
    };

    const getPlantStates = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/plant-states/${props.cropId}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const getProductsByCategory = (categoryName: string) => {
      if (products.value && categoryName) {
        return products.value.filter((product: TreatmentApplication) => {return (product.product && product.product.treatment_type_name) ? product.product.treatment_type_name === categoryName : false});
      }
      return [];
    };

    const getPassTreatments = (passId: number) => {
      if (cropTreatment.value.treatment_items) {
        return cropTreatment.value.treatment_items.filter((item: TreatmentItem) => {return item.treatment_pass === passId});
      }
      return [];
    };

    const getNewItemByCategory = (categoryId: number) => {
      if (newTreatmentItems.value) {
        return newTreatmentItems.value.find((item: TreatmentItem) => {return item.category === categoryId});
      }
      return false;
    };

    const getNewItemByPass = (passId: number) => {
      if (newTreatmentItems.value) {
        return newTreatmentItems.value.find((item: TreatmentItem) => {return item.treatment_pass === passId});
      }
      return false;
    };

    const validateTreatmentItem = (item: TreatmentItem): boolean => {
      if (!item.product) {
        errorMessage.value = frTranslations.ProductMissing;
        if (item.category) {
          selectedCategory.value = item.category;
          selectedPass.value = 0;
          scrollToError();
        }
        if (item.treatment_pass) {
          selectedPass.value = item.treatment_pass;
          selectedCategory.value = 0;
          scrollToError();
        }
        return false;
      }
      
      errorMessage.value = "";
      selectedCategory.value = 0;
      selectedPass.value = 0;
      return true;
    };

    const addTreatmentItem = async (item: TreatmentItem) => {
      try {
        if (!validateTreatmentItem(item)){
          return false;
        }
        updateProductData(item);
        const response = await axiosInstance.post(`api/ceta-marge/treatment-item/add/`, item);
        if (!cropTreatment.value.treatment_items) {
          cropTreatment.value.treatment_items = [];
        }
        cropTreatment.value.treatment_items.push(response.data);
        // Reset newTreatmentItem to its default values
        if (item.category) {
          newTreatmentItems.value = newTreatmentItems.value.filter((newItem: TreatmentItem) => newItem.category !== item.category);
          newTreatmentItems.value.push(freshTreatmentItem(item.category, null));
          if (productRefs.value[item.category]) {
            productRefs.value[item.category].resetComponent();
          }
        }
        if (item.treatment_pass) {
          newTreatmentItems.value = newTreatmentItems.value.filter((newItem: TreatmentItem) => newItem.treatment_pass !== item.treatment_pass);
          newTreatmentItems.value.push(freshTreatmentItem(null, item.treatment_pass));
          if (productRefs.value[item.treatment_pass]) {
            productRefs.value[item.treatment_pass].resetComponent();
          }
        }
        updateTotalTreatment();
        return true;
      } catch (error) {
        console.error('Error adding new treatment item:', error);
        return false;
      }
    };

    const updateTreatmentItemData = (e: any, currentItem: TreatmentItem) => {
      if (!validateTreatmentItem(currentItem)){
        return;
      }
      updateProductData(currentItem);
      if (currentItem.id && currentItem.id > 0) {
        updateTreatmentItem(currentItem);
      }
      updateTotalTreatment();
    };

    const updateProductData = (item: TreatmentItem) => {
      const product = products.value.find((product: TreatmentApplication) => product.id === item.product);
      if (product && product.product) {
        item.unit = product.product.unit;
        if (product.product.product_type) {
          item.product_type_name = product.product.product_type.name;
        }
        if (product.registered_dose && item.dose_pro_ha) {
          if (item.product_type_name && !item.product_type_name.toLowerCase().includes('adjuvant')) {
            item.ift = parseFloat((item.dose_pro_ha / product.registered_dose).toFixed(3));
          }
        }
      }
      if (item.cost && item.dose_pro_ha) {
        item.cost_pro_ha = Math.round((item.cost * item.dose_pro_ha) * 100) / 100;
      }
    }

    const updateTreatmentItem = async (data: TreatmentItem) => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/treatment-item/${data.id}/update/`, data);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const deleteTreatmentItem = async (itemId: number) => {
      if (itemId) {
        try {
          await axiosInstance.delete(`api/ceta-marge/treatment-item/${itemId}/delete/`);
          if (cropTreatment.value.treatment_items) {
            cropTreatment.value.treatment_items = cropTreatment.value.treatment_items.filter((item: TreatmentItem) => item.id !== itemId);
          }
          updateTotalTreatment();
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    };

    const addNewPass = async () => {
      try {
        const response = await axiosInstance.post(`api/ceta-marge/treatment-pass/add/`, newTreatmentPass.value);
        if (!cropTreatment.value.treatment_passes) {
          cropTreatment.value.treatment_passes = [];
        }
        cropTreatment.value.treatment_passes.push(response.data);
        newTreatmentItems.value.push(freshTreatmentItem(null, response.data.id));
        lastPassNumber.value += 1
        newTreatmentPass.value = freshTreatmentPass();
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const updateTreatmentPass = async (pass: TreatmentPass) => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/treatment-pass/${pass.id}/update/`, pass);
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const deleteTreatmentPass = async () => {
      if (selectedPassId.value) {
        try {
          await axiosInstance.delete(`api/ceta-marge/treatment-pass/${selectedPassId.value}/delete/`);
          if (cropTreatment.value.treatment_passes) {
            cropTreatment.value.treatment_passes = cropTreatment.value.treatment_passes.filter((item: TreatmentPass) => item.id !== selectedPassId.value);
          }
          if (cropTreatment.value.treatment_items) {
            cropTreatment.value.treatment_items = cropTreatment.value.treatment_items.filter((item: TreatmentItem) => item.treatment_pass !== selectedPassId.value);
          }
          updateTotalTreatment();
        } catch (error) {
          console.error(error);
          throw error;
        }
      }
    };

    const setOpen = (isOpen: boolean, passId: number) => {
      showDeletePassConfirmation.value = isOpen;
      selectedPassId.value = passId;
    }

    const calculateDoseProHa = (item: TreatmentItem) => {
      const error = ref<boolean>(false);
      if (!area.value) {
        error.value = true;
        errorMessage.value = frTranslations.TotalAreaMissing;
      }
      if (!item.area_treated) {
        error.value = true;
        errorMessage.value = frTranslations.AreaPercentMissing;
      }
      if (!item.dose_pro_spread_ha) {
        error.value = true;
        errorMessage.value = frTranslations.QuantityProHaMissing;
      }
      if (error.value) {
        if (item.category) {
          selectedCategory.value = item.category;
          selectedPass.value = 0;
        }
        if (item.treatment_pass) {
          selectedPass.value = item.treatment_pass;
          selectedCategory.value = 0;
        }
      }
      else {
        if (area.value && item.dose_pro_spread_ha && item.area_treated) {
          const raw_quantity = item.dose_pro_spread_ha * (area.value * (item.area_treated / 100));
          item.total_quantity = parseFloat(raw_quantity.toFixed(2))
          item.dose_pro_ha = item.total_quantity / area.value; 
          updateTreatmentItemData([], item);
        }
        errorMessage.value = '';
        selectedPass.value = 0;
        selectedCategory.value = 0;
      }
    }

    const updateTotalTreatment = () => {
      let cost = 0;
      let cost_except_herbicide = 0;
      let cost_herbicide = 0;
      let cost_glyphosate = 0;
      let total_ift = 0;
      let total_ift_except_herbicide = 0;
      let total_ift_herbicide = 0;
      let total_ift_glyphosate = 0;
      if (cropTreatment.value.treatment_items) {
        cropTreatment.value.treatment_items.forEach((item: TreatmentItem) => {
          if (item.cost_pro_ha) {
            cost += item.cost_pro_ha;
            if (item.product_type_name == "Herbicides") {
              cost_herbicide += item.cost_pro_ha;
            }
            else {
              cost_except_herbicide += item.cost_pro_ha;
            }
            if (item.glyphosate) {
              cost_glyphosate += item.cost_pro_ha;
            }
          }
          if (item.ift) {
            total_ift += item.ift;
            if (item.product_type_name == "Herbicides") {
              total_ift_herbicide += item.ift;
            }
            else {
              total_ift_except_herbicide += item.ift;
            }
            if (item.glyphosate) {
              total_ift_glyphosate += item.ift;
            }
          }
        });
      }
      // 

      if (seed_treatment.value) {
        total_ift += 1;
      }

      cropTreatment.value.cost_pro_ha = parseFloat(cost.toFixed(2));
      cropTreatment.value.total_cost = Math.round(cost * area.value * 100) / 100;
      cropTreatment.value.cost_excluding_herbicide_pro_ha = parseFloat(cost_except_herbicide.toFixed(2));
      cropTreatment.value.cost_excluding_herbicide = Math.round(cost_except_herbicide * area.value * 100) / 100;
      cropTreatment.value.cost_herbicide_pro_ha = parseFloat(cost_herbicide.toFixed(2));
      cropTreatment.value.cost_herbicide = Math.round((cost_herbicide * area.value) * 100) / 100;
      cropTreatment.value.cost_glyphosate_pro_ha = parseFloat(cost_glyphosate.toFixed(2));
      cropTreatment.value.cost_glyphosate = Math.round(cost_glyphosate *area.value * 100) / 100;
      cropTreatment.value.ift_excluding_herbicide = parseFloat(total_ift_except_herbicide.toFixed(3));
      cropTreatment.value.ift_herbicide = parseFloat(total_ift_herbicide.toFixed(3));
      cropTreatment.value.ift_glyphosate = parseFloat(total_ift_glyphosate.toFixed(3));
      cropTreatment.value.ift_total = parseFloat(total_ift.toFixed(3));
      
      updateCropTreatment();
    }

    watch(seed_treatment, updateTotalTreatment);

    const updateCropTreatment = async () => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/crop/${props.cropId}/treatment/update/`, cropTreatment.value);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const handleImportData = async (id: number) => {
      if (id) {
        cropTreatment.value = await duplicate(props.cropId, id);
        initiateNewTreatments();
      }
    };

    const duplicate = async (cropId: number, duplicateId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/treatment/duplicate/${duplicateId}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const nextStep = async (e: any) => {
      let valid = true;
      for (const category of categories.value as TreatmentCategory[]) {
        if (category.id) {
          const newItem = getNewItemByCategory(category.id);
          if (newItem && newItem.product) {
            valid = await addTreatmentItem(newItem);
            if (!valid) {
              break;  // Exit the loop if valid becomes false
            }
          }
        }
      }
      for (const pass of cropTreatment.value.treatment_passes as TreatmentPass[]) {
        if (pass.id) {
          const newItem = getNewItemByPass(pass.id);
          if (newItem && newItem.product) {
            valid = await addTreatmentItem(newItem);
            if (!valid) {
              break;
            }
          }
        }
      }
      if (valid) {
        emit('next');
      }
    };
    
    const handleDebouncedupdate = debounce(updateTreatmentItemData, 300);

    const previousStep = (e: any) => {
      emit('previous');
    };

    const emit = (eventName: string, ...args: any[]) => {
      context.emit(eventName, ...args);
    };

    return {
      dataLoaded,
      translations,
      currentLanguage,
      nextStep,
      previousStep,
      errorMessage,
      errorContainers,
      trashOutline,
      categories,
      productTypes,
      getProductsByCategory,
      products,
      plantStates,
      cropTreatment,
      addCircleOutline,
      arrowForwardOutline,
      getTreatments,
      getPassTreatments,
      getNewItemByCategory,
      getNewItemByPass,
      addTreatmentItem,
      handleDebouncedupdate,
      deleteTreatmentItem,
      addNewPass,
      updateTreatmentPass,
      deleteTreatmentPass,
      selectedCategory,
      selectedPass,
      alertButtons,
      setOpen,
      showDeletePassConfirmation,
      calculateDoseProHa,
      updateProductData,
      handleImportData,
      handleSelected,
      productRefs
    };
  },
});
