
import { 
  IonMenu,
  IonMenuToggle,
  IonMenuButton,
  IonHeader, 
  IonToolbar,
  IonButtons,
  IonThumbnail,
  IonIcon,
  IonContent,
  IonFooter,
  IonPage
} from '@ionic/vue';
import { helpCircle, logOutOutline } from 'ionicons/icons';
import { defineComponent, onMounted, computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import frTranslations from '@/locales/fr.json';

export default defineComponent({
  components: {
    IonMenu,
    IonMenuToggle,
    IonMenuButton,
    IonHeader,
    IonToolbar, 
    IonButtons,
    IonIcon,
    IonThumbnail,
    IonContent,
    IonFooter,
    IonPage
  },
  setup() {
    const currentLanguage = ref('fr');

    const translations = {
      fr: frTranslations,
    };

    const router = useRouter();
    const store = useStore();
    const user_type = ref('');
    const company_slug = ref('');

    const logoUrl = computed(() => require('@/assets/logo-ceta-35.png'));

    const logout = async () => {
      await store.dispatch("logout");
      router.push({ name: "login" }).then(() => window.location.reload());
    };

    onMounted(async () => {
      if (!store.getters['isAuthenticated']) {
        router.push({ name: "login" });
      }
      user_type.value = store.getters['getUserType'];
      company_slug.value = store.getters['getCompanySlug'];
    });

    return {
      helpCircle,
      logOutOutline,
      logoUrl,
      logout,
      translations,
      currentLanguage,
      user_type,
      company_slug
    };
  },
});
