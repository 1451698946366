import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_BaseLayout = _resolveComponent("BaseLayout")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BaseLayout, null, {
      title: _withCtx(() => [
        _createVNode(_component_ion_title, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('ErrorPage')), 1)
          ]),
          _: 1
        })
      ]),
      content: _withCtx(() => [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('ErrorPageTitle')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('ErrorPageText')), 1),
        _createVNode(_component_ion_button, {
          color: "light",
          href: "/"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('Back')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}