import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "ion-margin-vertical" }
const _hoisted_4 = { class: "field-container" }
const _hoisted_5 = { class: "field-container" }
const _hoisted_6 = { class: "field-container" }
const _hoisted_7 = { class: "field-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.dataLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_spinner),
          _createVNode(_component_ion_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('LoadingData')), 1)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, { class: "ion-justify-content-left ion-align-items-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      size: "12",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_ion_text, { class: "field-label" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('LimingFrequency')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.cropLiming.frequency), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('Years')), 1)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      size: "12",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_ion_text, { class: "field-label" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('Product')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.cropLiming.product_name), 1)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      size: "12",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_ion_text, { class: "field-label" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('Quantity')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.cropLiming.quantity), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('UnitTproHa')), 1)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "ion-justify-content-left ion-align-items-center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      size: "12",
                      "size-md": "4"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_ion_text, { class: "field-label" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('Cost')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.cropLiming.cost), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('UnitEuroProT')), 1)
                            ]),
                            _: 1
                          })
                        ])
                      ]),
                      _: 1
                    }),
                    (_ctx.cropLiming.spreading_included)
                      ? (_openBlock(), _createBlock(_component_ion_col, {
                          key: 0,
                          size: "auto"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('SpreadingIncluded')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.cropLiming.spreading_over_current)
                      ? (_openBlock(), _createBlock(_component_ion_col, {
                          key: 1,
                          size: "auto"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('SpreadingOverCrop')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]))
  ]))
}