
import {  
  IonLabel,
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonButton
} from '@ionic/vue';

import { defineComponent, onMounted, ref, Ref } from "vue";
import { useRoute } from 'vue-router';
import axiosInstance from "@/axiosInstance";
import BaseLayout from '@/layouts/BaseLayout.vue';
import { Crop } from "@/types/Crop";
import CropArea from "../components/CetaMarge/CropArea.vue";
import CropSeed from "../components/CetaMarge/CropSeed.vue";
import CropMineralFertilization from "../components/CetaMarge/CropMineralFertilization.vue";
import CropOrganicFertilization from "../components/CetaMarge/CropOrganicFertilization.vue";
import CropLimingFertilization from "../components/CetaMarge/CropLimingFertilization.vue";
import CropMecanic from "../components/CetaMarge/CropMecanic.vue";
import CropTreatment from "../components/CetaMarge/CropTreatment.vue";

export default defineComponent({
  name: "CetaMargeCrop",
  components: {
    BaseLayout,
    CropArea,
    CropSeed,
    CropMineralFertilization,
    CropOrganicFertilization,
    CropLimingFertilization,
    CropMecanic,
    CropTreatment,
    IonLabel,
    IonAccordion,
    IonAccordionGroup,
    IonItem,
    IonSpinner,
    IonGrid,
    IonRow,
    IonCol,
    IonButton
  },
  setup() {

    const dataLoaded = ref(false);
    const activeIndex = ref(0);
    const numberOfSteps = ref(8);
    const route = useRoute();
    const year = ref(route.params.year);
    const slug = ref(route.params.slug);
    const cropId = ref<string | string[]>(route.params.cropId);
    const crop = ref<Crop>();

    onMounted(async () => {
      crop.value = await getCrop(cropId);
      dataLoaded.value = true;
    });

    const getCrop = async (cropId: Ref<string | string[]>) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId.value}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const nextStep = async () => {
      crop.value = await getCrop(cropId);
      activeIndex.value = Math.min(activeIndex.value + 1, numberOfSteps.value - 1);
    };

    const previousStep = () => {
      activeIndex.value = Math.max(activeIndex.value - 1, 0);
    };

    const updateIndex = (index: number) => {
      activeIndex.value = index;
    };

    const isActive = (index: number) => {
      if (activeIndex.value == index) {
        return "primary";
      }
      return "light";
    };

    return {
      dataLoaded,
      slug,
      crop,
      year,
      activeIndex,
      nextStep,
      previousStep,
      isActive,
      updateIndex
    };
  },
});
