
import { 
  IonSpinner,
  IonText
} from '@ionic/vue';
import { 
  defineComponent,
  onMounted,
  PropType,
  ref
} from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';
import { CropMineralFertilization } from "@/types/CropMineralFertilization";

export default defineComponent({
  name: "CropMineralFertilization",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    }
  },
  components: {
    IonText,
    IonSpinner
  },
  setup(props) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const cropMineralFertilization = ref<CropMineralFertilization>({});
    const dataLoaded = ref(false);

    const getCropMineralFertilization = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/mineral-fertilization/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    onMounted(async () => {
      if (props.cropId !== undefined && props.cropId !== null) {
        cropMineralFertilization.value = await getCropMineralFertilization(props.cropId);
        dataLoaded.value = true;
      }
    });

    return {
      translations,
      currentLanguage,
      dataLoaded,
      cropMineralFertilization
    };
  },
});
