
import {  
  IonContent,
  IonHeader,
  IonTitle,
  IonButton,
  IonModal,
  IonItem,
  IonTextarea
} from '@ionic/vue';
import { defineComponent, PropType, ref, Ref, toRef } from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';

export default defineComponent({
  components: {
    IonContent,
    IonTitle,
    IonHeader,
    IonButton,
    IonModal,
    IonItem,
    IonTextarea
  },
  props: {
    companyId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup(props) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const companyId = toRef(props, 'companyId');
    const showModal = ref(false);
    const info = ref("");
    const showMessage = ref(false);
    
    const openModal = async () => {
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
      info.value = '';
    };

    const submitForm = async () => {
      try {
        await sendFormData(companyId, info);
        showMessage.value = true;
      } catch (error) {
        console.error('Error sending info', error);
      }
    };

    const sendFormData = async (companyId: Ref<number>, info: Ref<string>) => {
      try {
        const formData = new FormData();
        formData.append('data', info.value); 
        const response = await axiosInstance.post(`api/core/wrong-data/${companyId.value}/`, formData);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    return {
      translations,
      currentLanguage,
      showModal,
      openModal,
      closeModal,
      info,
      submitForm,
      showMessage
    };
  },
});
