import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6311f1b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "container",
  class: "login"
}
const _hoisted_2 = { class: "ion-text-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ion-margin-top ion-text-center" }
const _hoisted_5 = { class: "ion-margin-vertical ion-text-center" }
const _hoisted_6 = { class: "ion-margin-top ion-text-center" }
const _hoisted_7 = { href: "/mot-de-passe-oublie" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("form", {
              onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  src: _ctx.logoUrl,
                  alt: "translations[currentLanguage].Ceta35Logo"
                }, null, 8, _hoisted_3)
              ]),
              _createVNode(_component_ion_input, {
                class: "standard ion-margin-bottom ion-padding-horizontal",
                label: _ctx.translations[_ctx.currentLanguage].Username,
                "label-placement": "floating",
                modelValue: _ctx.username,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                type: "text",
                required: ""
              }, null, 8, ["label", "modelValue"]),
              _createVNode(_component_ion_input, {
                class: "standard ion-margin-bottom ion-padding-horizontal",
                label: _ctx.translations[_ctx.currentLanguage].Password,
                "label-placement": "floating",
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                type: "password",
                onKeyup: _withKeys(_ctx.login, ["enter"]),
                required: ""
              }, null, 8, ["label", "modelValue", "onKeyup"]),
              (_ctx.error)
                ? (_openBlock(), _createBlock(_component_ion_text, {
                    key: 0,
                    class: "ion-margin-vertical",
                    color: "danger"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.error), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_ion_button, { type: "submit" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('Login')), 1)
                  ]),
                  _: 1
                })
              ])
            ], 32),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.errorString)
                ? (_openBlock(), _createBlock(_component_ion_text, {
                    key: 0,
                    color: "danger"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.errorString), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t('ForgottenPassword')), 1)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}