
import {
  IonButton,
  IonSpinner,
  IonText,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonCheckbox
} from '@ionic/vue';
import { defineComponent, onMounted, PropType, ref, Ref, toRef, SetupContext } from "vue";
import axiosInstance from "@/axiosInstance";
import frTranslations from '@/locales/fr.json';
import { CropLiming } from "@/types/CropLiming";
import ImportDataModal from "@/modals/ImportDataModal.vue";

export default defineComponent({
  name: "CropLimingFertilizationForm",
  props: {
    cropId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  components: {
    ImportDataModal,
    IonButton,
    IonSpinner,
    IonText,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonCheckbox
  },
  setup(props, context: SetupContext) {
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };
    const dataLoaded = ref(false);
    const errorMessage = ref<string | null>(null);
    const limingProducts = ref([]);
    const cropLiming = ref<CropLiming>({});

    onMounted(async () => {
      
     if (props.cropId !== undefined && props.cropId !== null) {
        limingProducts.value = await getLimingProducts();
        cropLiming.value = await getCropLiming(props.cropId);
        dataLoaded.value = true;
      }
      
    });

    const updateCropLimingPrice = () => {
      cropLiming.value.yearly_cost = calculateLimingYearlyCost();
      updateCropLimingData();
    }

    const calculateLimingYearlyCost = () => {
      if (cropLiming.value.frequency) {
        const costLiming = Number(cropLiming.value.cost);
        const quantityLiming = Number(cropLiming.value.quantity);
        let totalCost = (
          costLiming * quantityLiming
        ) / cropLiming.value.frequency;
        if (!cropLiming.value.spreading_included) {
          totalCost = totalCost + 10;
        }
        return Math.round(totalCost * 100) / 100;
      } else {
        return 0;
      }
    };

    const validateCropLiming = (): boolean => {
      return true;
    };

    const updateCropLimingData = () => {
      if (!validateCropLiming()){
        return;
      }
      updateCropLiming();
    }

    const getCropLiming = async (cropId: number) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId}/liming/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const getLimingProducts = async () => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/liming-products/`);
        return response.data;
      } catch (error) {
        console.error(error);
        return "";
      }
    };

    const updateCropLiming = async () => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/crop/${props.cropId}/liming/update/`, cropLiming.value);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const handleImportData = async (id: number) => {
      if (id) {
        const current_id = cropLiming.value.id;
        cropLiming.value = await getCropLiming(id);
        cropLiming.value.id = current_id;  
      }
    };

    const nextStep = (e: any) => {
      
      emit('next');
    };

    const previousStep = (e: any) => {
      emit('previous');
    };

    const emit = (eventName: string, ...args: any[]) => {
      context.emit(eventName, ...args);
    };

    return {
      cropLiming,
      dataLoaded,
      translations,
      currentLanguage,
      nextStep,
      previousStep,
      errorMessage,
      limingProducts,
      updateCropLimingData,
      updateCropLimingPrice,
      handleImportData
    };
  },
});
