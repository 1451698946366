import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac9d6e3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { href: "/" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_menu, { "content-id": "main-content" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('Menu')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, { class: "ion-padding" }, {
          default: _withCtx(() => [
            (_ctx.user_type == 'member')
              ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createElementVNode("a", {
                          href: `/exploitation/${_ctx.company_slug}/`
                        }, _toDisplayString(_ctx.$t('Dashboard')), 9, _hoisted_1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createElementVNode("a", {
                          href: `/exploitation/${_ctx.company_slug}/cultures/`
                        }, _toDisplayString(_ctx.$t('cetaMarge')), 9, _hoisted_2)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.user_type == 'employee')
              ? (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.$t('Dashboard')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_ion_footer, { class: "ion-padding" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_menu_toggle, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { expand: "block" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('CloseMenu')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_page, { id: "main-content" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_buttons, { slot: "start" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_thumbnail, null, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          src: _ctx.logoUrl,
                          alt: "translations[currentLanguage].Ceta35Logo"
                        }, null, 8, _hoisted_4)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_menu_button)
                  ]),
                  _: 1
                }),
                _renderSlot(_ctx.$slots, "title", {}, undefined, true),
                _createVNode(_component_ion_buttons, {
                  slot: "end",
                  class: "ion-hide-sm-down"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      fill: "solid",
                      color: "light",
                      onClick: _ctx.logout
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('Logout')) + " ", 1),
                        _createVNode(_component_ion_icon, {
                          slot: "end",
                          icon: _ctx.logOutOutline
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_buttons, {
                  slot: "end",
                  class: "ion-hide-sm-up"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      fill: "solid",
                      color: "light",
                      onClick: _ctx.logout
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "icon-only",
                          icon: _ctx.logOutOutline
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 3
            })
          ]),
          _: 3
        }),
        _createVNode(_component_ion_content, {
          fullscreen: true,
          class: "ion-padding"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
          ]),
          _: 3
        }),
        _createVNode(_component_ion_footer)
      ]),
      _: 3
    })
  ], 64))
}