
import {  
  IonLabel,
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonIcon,
  IonBadge,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonButton
} from '@ionic/vue';
import { 
  helpOutline, 
  checkmarkOutline,
  alertOutline,
  warningOutline
} from 'ionicons/icons';
import { defineComponent, onMounted, PropType, ref, Ref, toRef } from "vue";
import { useRoute } from 'vue-router';
import { useRouter } from "vue-router";
import axiosInstance from "@/axiosInstance";
import BaseLayout from '../../layouts/BaseLayout.vue';
import { Crop } from "@/types/Crop";
import CropAreaForm from "./CropAreaForm.vue";
import CropSeedForm from "./CropSeedForm.vue";
import CropMineralFertilizationForm from "./CropMineralFertilizationForm.vue";
import CropOrganicFertilizationForm from "./CropOrganicFertilizationForm.vue";
import CropLimingFertilizationForm from "./CropLimingFertilizationForm.vue";
import CropMecanicForm from "./CropMecanicForm.vue";
import CropTreatmentForm from "./CropTreatmentForm.vue";
import { ActionItem } from "@/types/ActionItem";

export default defineComponent({
  name: "CropForm",
  components: {
    BaseLayout,
    CropAreaForm,
    CropSeedForm,
    CropMineralFertilizationForm,
    CropOrganicFertilizationForm,
    CropLimingFertilizationForm,
    CropMecanicForm,
    CropTreatmentForm,
    IonLabel,
    IonAccordion,
    IonAccordionGroup,
    IonItem,
    IonIcon,
    IonBadge,
    IonSpinner,
    IonGrid,
    IonRow,
    IonCol,
    IonButton
  },
  setup() {

    const dataLoaded = ref(false);
    const activeIndex = ref(0);
    const numberOfSteps = ref(8);
    const route = useRoute();
    const router = useRouter();
    const year = ref(route.params.year);
    const slug = ref(route.params.slug);
    const cropId = ref<string | string[]>(route.params.cropId);
    const crop = ref<Crop>();
    const cropAreaValid = ref(false);
    const cropSeedValid = ref(false);
    const cropMecaValid = ref(false);
    const cropValid = ref(false);

    const submitCrop = async () => {
      try {
        if (crop.value) {
          crop.value.submitted = true;
          crop.value = await updateCrop(cropId, crop.value);
        }
        router.push({ name: "ceta-marge-campaign-form" });
      } catch (error) {
        console.error('Error updating production data:', error);
      }
    };

    onMounted(async () => {
      crop.value = await getCrop(cropId);
      estimateStatus();
      dataLoaded.value = true;
    });

    const getCrop = async (cropId: Ref<string | string[]>) => {
      try {
        const response = await axiosInstance.get(`api/ceta-marge/crop/${cropId.value}/`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const updateCrop = async (cropId: Ref<string | string[]>, crop: Crop) => {
      try {
        const response = await axiosInstance.put(`api/ceta-marge/crop/${cropId.value}/update/`, crop);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const estimateStatus = () => {
      cropAreaValid.value = estimateAreaStatus();
      cropSeedValid.value = estimateSeedStatus();
      cropMecaValid.value = estimateMecaStatus();
      if (cropAreaValid.value && cropSeedValid.value && cropMecaValid.value) {
        cropValid.value = true;
      }
      else {
        cropValid.value = false;
      }

    };

    const estimateAreaStatus = () => {
      if (crop.value) {
        const area = crop.value.area;
        if (
          area && 
          area.area && area.area > 0 && 
          (
            (area.crop_yield && area.crop_yield > 0) ||
            (area.crop_yield_target && area.crop_yield_target > 0)
          ) && 
          area.previous_crop && area.previous_crop > 0) {
          return true;
        }
      }
      return false;
    };

    const estimateSeedStatus = () => {
      if (crop.value) {
        const seed = crop.value.seed;
        if (seed && seed.seed_total_cost && seed.seed_total_cost > 0) {
          return true;
        }
      }
      return false;
    };

    const estimateMecaStatus = () => {
      if (crop.value) {
        const meca = crop.value.meca;
        if (meca && meca.action_items) {
          const sewing_actions = meca.action_items.filter((item: ActionItem) => {
            if (item.category == 2) {
              return true;
            }
          });
          if (sewing_actions.length > 0) {
            return true;
          }
        }
      }
      return false;
    };

    const nextStep = async () => {
      crop.value = await getCrop(cropId);
      estimateStatus();
      activeIndex.value = Math.min(activeIndex.value + 1, numberOfSteps.value - 1);
    };

    const handleAreaChange = (area: number) => {
      if (crop.value && crop.value.area) {
        crop.value.area.area = area;
      }
    };

    const handleSeedTreatmentChange = (seed_treatment: boolean) => {
      if (crop.value && crop.value.seed) {
        crop.value.seed.treatment_seed = seed_treatment;
      }
    };

    const previousStep = () => {
      activeIndex.value = Math.max(activeIndex.value - 1, 0);
    };

    const updateIndex = (index: number) => {
      activeIndex.value = index;
    };

    const isActive = (index: number) => {
      if (activeIndex.value == index) {
        return "primary";
      }
      return "light";
    };

    return {
      helpOutline, 
      checkmarkOutline,
      warningOutline,
      alertOutline,
      dataLoaded,
      slug,
      crop,
      year,
      activeIndex,
      nextStep,
      previousStep,
      isActive,
      updateIndex,
      cropAreaValid,
      cropSeedValid,
      cropMecaValid,
      cropValid,
      submitCrop,
      handleAreaChange,
      handleSeedTreatmentChange
    };
  },
});
