
import { defineComponent, ref, onMounted } from "vue";
import { IonPage, IonContent, IonInput, IonButton } from "@ionic/vue";
import { useRoute } from 'vue-router';
import axiosInstance from '@/axiosInstance';
import frTranslations from '@/locales/fr.json';

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonInput,
    IonButton,
  },
  setup() {
    const route = useRoute();
    const newPassword = ref("");
    const token = ref(""); // Get this token from the URL or user input
    const showMessage = ref(false);
    const errorMessages = ref<string[]>([]);
    const isLoading = ref<boolean>(false);
    const currentLanguage = ref('fr');
    const translations = {
      fr: frTranslations,
    };


    onMounted(() => {
      token.value = route.query.token as string;
    });

    const resetPassword = async () => {
      errorMessages.value = [];
      isLoading.value = true;
      try {
        const response = await axiosInstance.post(`api/core/password_reset/confirm/`, {
          password: newPassword.value, 
          token: token.value
        });

       console.log(response);

        if (response.status) {
           showMessage.value = true;
        }

      } catch (error: any) {
        console.error("There was an error resetting the password", error);
        if (error.response?.data?.password && Array.isArray(error.response.data.password)) {
          errorMessages.value = error.response.data.password;
        } else {
          errorMessages.value = ['Une erreur inconnue est survenue. Veuillez réessayer'];
        }
      } finally {
        // Set loading to false
        isLoading.value = false;
      }
    };

    return {
      newPassword,
      errorMessages,
      isLoading,
      showMessage,
      resetPassword,
      translations,
      currentLanguage
    };
  },
});
