import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38fc443b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-container" }
const _hoisted_2 = { class: "ion-padding-vertical" }
const _hoisted_3 = { class: "ion-padding-vertical" }
const _hoisted_4 = { class: "ion-padding-vertical" }
const _hoisted_5 = { class: "ion-padding-vertical" }
const _hoisted_6 = {
  key: 0,
  class: "ion-padding-vertical"
}
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.value)
      ? (_openBlock(), _createBlock(_component_ion_button, {
          key: 0,
          onClick: _ctx.openModal,
          color: "light",
          class: "auto-fill-select-button"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.value), 1),
            _createVNode(_component_ion_icon, {
              slot: "end",
              icon: _ctx.chevronDownOutline
            }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["onClick"]))
      : (_openBlock(), _createBlock(_component_ion_button, {
          key: 1,
          onClick: _ctx.openModal,
          color: "light",
          class: "auto-fill-select-button"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('SelectOption')), 1),
            _createVNode(_component_ion_icon, {
              slot: "end",
              icon: _ctx.chevronDownOutline
            }, null, 8, ["icon"])
          ]),
          _: 1
        }, 8, ["onClick"])),
    _createVNode(_component_ion_modal, {
      "is-open": _ctx.showModal,
      onIonModalWillDismiss: _ctx.closeModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_content, { class: "ion-padding-horizontal" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_button, {
                color: "light",
                onClick: _ctx.closeModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_ctx.value && _ctx.clear)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    class: "ion-pull-right",
                    color: "secondary",
                    onClick: _ctx.clearValue
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        icon: _ctx.closeOutline
                      }, null, 8, ["icon"]),
                      _createTextVNode(_toDisplayString(_ctx.$t('Delete')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ion_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('SelectOptionModalText')), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ion_searchbar, {
                "aria-label": _ctx.label,
                modelValue: _ctx.internalSearchText,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalSearchText) = $event)),
                onIonInput: _ctx.filterItems,
                placeholder: _ctx.placeholder
              }, null, 8, ["aria-label", "modelValue", "onIonInput", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_ion_text, { strong: "true" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.label), 1)
                ]),
                _: 1
              }),
              (_ctx.options.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_ion_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('NoOptionsFound')), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_ctx.filteredList.length)
                      ? (_openBlock(), _createBlock(_component_ion_list, {
                          key: 0,
                          class: "list-options"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredList, (item) => {
                              return (_openBlock(), _createBlock(_component_ion_item, {
                                key: item.value,
                                onClick: ($event: any) => (_ctx.selectItem(item))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getValueByField(item)), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            }), 128))
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_ion_list, {
                          key: 1,
                          class: "list-options"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
                              return (_openBlock(), _createBlock(_component_ion_item, {
                                key: item.value,
                                onClick: ($event: any) => (_ctx.selectItem(item))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getValueByField(item)), 1)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            }), 128))
                          ]),
                          _: 1
                        }))
                  ]))
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open", "onIonModalWillDismiss"])
  ]))
}