import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29d5621e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "ion-margin-vertical" }
const _hoisted_4 = { class: "responsive-table" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_pie_chart = _resolveComponent("pie-chart")!
  const _component_bar_chart = _resolveComponent("bar-chart")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_BaseLayout = _resolveComponent("BaseLayout")!

  return (_openBlock(), _createBlock(_component_BaseLayout, null, {
    title: _withCtx(() => [
      (_ctx.dataLoaded)
        ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('DashboardCampaign')) + " " + _toDisplayString(_ctx.campaign.label), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    content: _withCtx(() => [
      (!_ctx.dataLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ion_spinner),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('LoadingData')), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          href: `/exploitation/${_ctx.slug}/cultures/`,
                          color: "light"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('BackToCampaignsDashboard')), 1)
                          ]),
                          _: 1
                        }, 8, ["href"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, {
                      size: "12",
                      "size-lg": "4"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pie_chart, {
                          crops: _ctx.campaign.crops,
                          onSelected: _ctx.handleSelected
                        }, null, 8, ["crops", "onSelected"]),
                        _createVNode(_component_bar_chart, {
                          cropId: _ctx.currentCrop.id,
                          cropName: _ctx.currentCrop.label
                        }, null, 8, ["cropId", "cropName"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      size: "12",
                      "size-lg": "8"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('CostRepartitionTable')), 1),
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("table", null, [
                              _createElementVNode("thead", null, [
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('Crop')), 1),
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('OperationalCost')), 1),
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('MecanicCost')), 1),
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('YieldTargetAbbr')), 1),
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('SalePrice')), 1),
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('ProductionCost')), 1),
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('IFTH')), 1),
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('IFTG')), 1),
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('IFTWH')), 1),
                                _createElementVNode("th", null, _toDisplayString(_ctx.$t('IFTT')), 1)
                              ]),
                              _createElementVNode("tbody", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.campaign.crops, (crop, index) => {
                                  return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                    _createElementVNode("td", null, [
                                      (_ctx.canUpdate(crop))
                                        ? (_openBlock(), _createBlock(_component_router_link, {
                                            key: 0,
                                            to: `/exploitation/${_ctx.slug}/cultures/${_ctx.year}/saisie/${crop.id}/`
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(crop.label), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["to"]))
                                        : (_openBlock(), _createBlock(_component_router_link, {
                                            key: 1,
                                            to: `/exploitation/${_ctx.slug}/cultures/${_ctx.year}/consultation/${crop.id}/`
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(crop.label), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["to"]))
                                    ]),
                                    _createElementVNode("td", null, [
                                      _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(crop.operational_cost) + " " + _toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _createElementVNode("td", null, [
                                      _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(crop.meca.cost_pro_ha) + " " + _toDisplayString(_ctx.$t('UnitEuroProHa')), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _createElementVNode("td", null, [
                                      _createVNode(_component_ion_text, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(crop.area.crop_yield_target) + " ", 1),
                                          (crop.area.yield_unit == 'T')
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('UnitTonProHa')), 1))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('UnitQuintalProHa')), 1))
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _createElementVNode("td", null, [
                                      _createVNode(_component_ion_text, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(crop.sale_price) + " " + _toDisplayString(_ctx.$t('UnitEuroProT')), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _createElementVNode("td", null, [
                                      _createVNode(_component_ion_text, { class: "ion-text-nowrap" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(crop.production_cost) + " " + _toDisplayString(_ctx.$t('UnitEuroProT')), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _createElementVNode("td", null, _toDisplayString(crop.treatments.ift_herbicide), 1),
                                    _createElementVNode("td", null, _toDisplayString(crop.treatments.ift_glyphosate), 1),
                                    _createElementVNode("td", null, _toDisplayString(crop.treatments.ift_excluding_herbicide), 1),
                                    _createElementVNode("td", null, _toDisplayString(crop.treatments.ift_total), 1)
                                  ]))
                                }), 128))
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
    ]),
    _: 1
  }))
}